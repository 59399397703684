import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import './Backpack.css';

import { IBackpack, IEquipmentItem, IItem } from '../../interfaces/gameInterfaces';

import apiProvider from '../../api';
import { StatsContext } from '../../context/StatsContext';
import { CharContext } from '../../context/CharContext';
import { defaultItem } from '../../interfaces/defaultValues';

import ActionButton from '../elements/ActionButton/ActionButton';
import ItemTable from '../elements/ItemTable/ItemTable';
import Card from '../../ui/Card/Card';
import ItemList from '../elements/ItemList/ItemList';
import Loading from '../../ui/Loading/Loading';

interface IProps {
    showModal: Function;
}

const Backpack = (props: IProps) => {
    const { t } = useTranslation();

    const [showModal, setShowModal] = React.useState<boolean>(true);
    const [refreshState, setRefreshState] = React.useState<number>(1);
    const [currentItem, setCurrentItem] = React.useState<IItem | IEquipmentItem>(defaultItem);
    const [backpack, setBackpack] = React.useState<IBackpack>([]);

    // Context
    const [char] = React.useContext(CharContext);
    const [, setStats] = React.useContext(StatsContext);

    // Logout function
    const logout = async () => {
        await clickClose();
    };

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    useEffect(() => {
        async function fetchData() {
            setBackpack([]);
            setCurrentItem(defaultItem);

            const [responseBackpack, responseStats] = await Promise.all([
                apiProvider.backpackDownloadBackpack(char.jwt, logout),
                apiProvider.statsGetKaracterStats(char.jwt, logout),
            ]);

            if (!responseBackpack || responseBackpack.error) return;
            if (!responseStats || responseStats.error) return;

            setBackpack(responseBackpack);
            setStats(responseStats);
        }

        fetchData();
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshState]);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------

    const clickCurrentItem = async (data: IItem | IEquipmentItem) => {
        setCurrentItem(data);
    };

    const clickClose = async () => {
        setShowModal(false);
        props.showModal(false);
    };

    const clickDrop = async () => {
        if (currentItem) {
            const response = await apiProvider.backpackDropItem(char.jwt, currentItem.id, logout);

            if (!response || response.error) return;

            // todo toast
        }

        setRefreshState(refreshState + 1);
    };

    const dynamicModalClass = () => (showModal ? { display: 'block' } : { display: 'none' });

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return showModal ? (
        <div id="modal fade" tabIndex={-1} role="dialog" className={`modal ${showModal ? 'show' : 'hide'}`} style={dynamicModalClass()}>
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <Card>
                                    <ItemList items={backpack} onClick={clickCurrentItem} onDoubleClick={() => {}} />
                                </Card>
                            </div>
                            <div className="col-12 col-sm-6">
                                {currentItem && currentItem.id !== 1 && (
                                    <Card>
                                        <ItemTable item={currentItem} />
                                    </Card>
                                )}
                            </div>
                        </div>
                    </div>
                    <Loading>
                        <div className="modal-footer">
                            <ActionButton type="button" label={t('button.close')} color="danger" action={clickClose} />
                            {currentItem && currentItem.id !== 1 && <ActionButton type="button" label={t('button.drop')} color="danger" action={clickDrop} />}
                            {(currentItem === null || currentItem.id === 1) && <ActionButton isEmpty={true} />}
                        </div>
                    </Loading>
                </div>
            </div>
        </div>
    ) : null;
};

export default Backpack;
