import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ActionButton from '../../components/elements/ActionButton/ActionButton';
import ActionMenu from '../../components/elements/ActionMenu/ActionMenu';
import { IDepositBoxUpgrades } from '../../interfaces/gameInterfaces';
import { CharContext } from '../../context/CharContext';
import { StatsContext } from '../../context/StatsContext';
import apiProvider from '../../api';
import { Center, colors } from '../../ui/constants';
import Item from '../../ui/HeroBar/Item';
import Card from '../../ui/Card/Card';
import Table from '../../components/elements/Table/Table';

interface IProps {}

const DepositBoxUpgrades = (props: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [refreshState, setRefreshState] = React.useState<number>(1);
    const [depositBoxUpgrades, setDepositBoxUpgrades] = React.useState<IDepositBoxUpgrades[]>([]);
    const [myAmount, setMyAmount] = React.useState<number | null>(null);

    // Context
    const [char, setChar] = React.useContext(CharContext);
    const [, setStats] = React.useContext(StatsContext);

    // Logout function
    const logout = () => {
        setChar(null);
        setStats(null);
        navigate('/');
    };

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    useEffect(() => {
        async function fetchData() {
            setDepositBoxUpgrades([]);
            setMyAmount(null);

            const [responseGlassMarbles, responseUpgrades, responseStats] = await Promise.all([
                apiProvider.backpackGetAmountOfGlassMarbles(char.jwt, logout),
                apiProvider.depositBoxUpgrades(char.jwt, logout),
                apiProvider.statsGetKaracterStats(char.jwt, logout),
            ]);

            if (!responseGlassMarbles || responseGlassMarbles.error) return;
            if (!responseUpgrades || responseUpgrades.error) return;
            if (!responseStats || responseStats.error) return;

            setStats(responseStats);
            setMyAmount(responseGlassMarbles.glassMarbles);
            setDepositBoxUpgrades(responseUpgrades);
        }

        fetchData();
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshState]);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------
    const clickBack = () => {
        navigate('/bank');
    };

    const clickUpgrade = async (upgrade: any) => {
        if (upgrade) {
            const response = await apiProvider.postDepositBoxUpgrades(char.jwt, upgrade.id, logout);

            if (!response || response.error) return;

            // todo toast
        }

        setRefreshState(refreshState + 1);
    };

    const listUpgrades = () => {
        return (
            depositBoxUpgrades.length > 0 &&
            depositBoxUpgrades.map(function (data: IDepositBoxUpgrades, index: number) {
                return (
                    <tr key={data.id}>
                        <td className="text-center w-col-30">{data.name}</td>
                        <td className="text-center text-success w-col-10 fw-bold">+{data.size}</td>
                        <td className="text-center text-primary w-col-10 fw-bold">{data.buy}</td>
                        <td className="text-center w-col-10">
                            <Center>
                                <span onClick={() => (data.id === 1 || depositBoxUpgrades[index - 1].hasExtension === 1) && data.hasExtension === 0 && clickUpgrade(data)}>
                                    <Item
                                        image="Upgrade"
                                        title={t('button.upgrade')}
                                        color={(data.id === 1 || depositBoxUpgrades[index - 1].hasExtension === 1) && data.hasExtension === 0 ? colors.primary : colors.disabled}
                                    />
                                </span>
                            </Center>
                        </td>
                    </tr>
                );
            })
        );
    };

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return (
        <>
            <ActionMenu>
                <ActionButton type="button" label={t('button.back')} color="danger" action={clickBack} />
            </ActionMenu>
            <Card title="button.upgrade">
                <p>
                    {t('common.i-have')}&nbsp;
                    <span className="lead fw-bold">{myAmount}</span>&nbsp;{t('common.money')}.
                </p>
                <Table
                    label={t('skinner.available-upgrades')}
                    thead={[
                        { label: t('skinner.name'), class: 'text-center' },
                        { label: t('skinner.size'), class: 'text-center' },
                        { label: t('skinner.price'), class: 'text-center' },
                        { label: t('skinner.action'), class: ' text-center' },
                    ]}
                    tbody={listUpgrades()}
                />
            </Card>
        </>
    );
};

export default DepositBoxUpgrades;
