import React, { ComponentProps, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import './ActionMenu.css';
import { useWindowSize } from 'react-use';
import styled from '@emotion/styled';
import Bordered from '../../../ui/Styled/Bordered';
import { spacing } from '../../../ui/constants';

interface IProps extends ComponentProps<any> {}

const ActionNav = styled(Bordered)`
    width: 300px;
    margin-right: ${spacing.r1};
    position: relative;
    z-index: 80;
    padding: 10px 0 1px 10px;
`;

const ActionMenu = (props: IProps) => {
    const { width } = useWindowSize();
    const footerEl = useRef<HTMLDivElement>(null);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        const mainContent: HTMLElement | null = document.querySelector('.main-content-container');
        if (mainContent && footerEl.current) {
            mainContent.style.marginBottom = `calc(${footerEl.current.offsetHeight}px - 3.5rem)`;
        }
    }, [width]);

    useEffect(() => {
        setMounted(true);
    }, []);

    return mounted ? ReactDOM.createPortal(<ActionNav ref={footerEl}>{props.children}</ActionNav>, document.getElementById('actionMenuPortalTarget') as any) : null;
};

export default ActionMenu;
