import React from 'react';
import styled from '@emotion/styled';
import { GiBarn, GiBlacksmith, GiCash, GiFallDown, GiGreekTemple, GiHospital, GiHut, GiSaloon, GiShop, GiSwordSmithing } from 'react-icons/gi';

interface IProps {
    image: string;
}

const iconsMap: { [key: string]: any } = {
    armorsmith: <GiBlacksmith />,
    craftsman: <GiHut />,
    merchant: <GiShop />,
    pharmacist: <GiHospital />,
    skinner: <GiBarn />,
    weaponsmith: <GiSwordSmithing />,
    inn: <GiSaloon />,
    bank: <GiGreekTemple />,
    gamble: <GiCash />,
    map: <GiFallDown />,
};

const Image = styled.div`
    position: absolute;
    width: 70%;
    height: 70%;
    left: 50%;
    top: 45%;
    transform: translateY(-50%) translateX(-50%);

    svg {
        width: 100%;
        height: 100%;
    }
`;

type BgProps = {
    color?: string;
    isPath?: boolean;
};

const Bg = styled.div<BgProps>`
    background: #;
    color: ${(p: BgProps) => p.color || '#fff'};
    width: 125px;
    max-width: 100%;
    border-radius: 3px;
    box-shadow: inset 0 0 15px #0003;
    cursor: ${(p: BgProps) => (p.isPath ? 'pointer' : 'normal')};

    &::before {
        display: block;
        content: '';
        padding-bottom: 100%;
    }
`;

export default function TownImage(props: IProps) {
    const code = props.image.split('_');
    const image = code.length === 2 ? code[1] : code[0];
    const type = code[0];

    return (
        <Bg data-image={image} data-type={type} isPath={image.length > 1}>
            {iconsMap.hasOwnProperty(image) && <Image>{iconsMap[image]}</Image>}
        </Bg>
    );
}
