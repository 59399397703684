import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './LootEnemy.css';

import { IItem } from '../../interfaces/gameInterfaces';

import ItemTable from '../../components/elements/ItemTable/ItemTable';
import ActionMenu from '../../components/elements/ActionMenu/ActionMenu';
import ActionButton from '../../components/elements/ActionButton/ActionButton';
import Loading from '../../ui/Loading/Loading';
import Card from '../../ui/Card/Card';

import apiProvider from '../../api';
import { CharContext } from '../../context/CharContext';
import { BattleContext } from '../../context/BattleContext';
import { StatsContext } from '../../context/StatsContext';

interface IProps {}

const LootEnemy = (props: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [foundNothing, setFoundNothing] = React.useState<boolean>(false);
    const [foundItem, setFoundItem] = React.useState<IItem | null>(null);

    // Context
    const [battle, setBattle] = React.useContext(BattleContext);
    const [char, setChar] = React.useContext(CharContext);
    const [, setStats] = React.useContext(StatsContext);

    // Logout function
    const logout = () => {
        setChar(null);
        setBattle(null);
        setStats(null);
        navigate('/');
    };

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    useEffect(() => {
        async function fetchData() {
            setFoundNothing(false);
            setFoundItem(null);

            // todo is on field

            const [responseLoot, responseStats] = await Promise.all([apiProvider.lootEnemy(battle.jwt, logout), apiProvider.statsGetKaracterStats(char.jwt, logout)]);

            if (!responseLoot || responseLoot.error) return;
            if (!responseStats || responseStats.error) return;

            setStats(responseStats);

            if (responseLoot.foundNothing) {
                setFoundNothing(true);
            } else {
                setFoundItem(responseLoot);
            }
        }

        fetchData();
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------

    const clickBack = async () => {
        await goToMap();
    };

    const goToMap = async () => {
        setBattle(null);

        const response = await apiProvider.gameSaveLocation(char.jwt, 'map', logout);

        if (!response || response.error) return;

        navigate('/map');
    };

    const clickStore = async () => {
        if (foundItem) {
            const response = await apiProvider.searchFieldStoreItem(char.jwt, foundItem.id, logout);

            if (!response || response.error) return;

            // TODO Toast succ
            await goToMap();
        }
    };

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return (
        <>
            <ActionMenu>
                <Loading>
                    <ActionButton type="button" label={t('button.back')} color="danger" action={clickBack} />
                    {foundItem && <ActionButton type="button" label={t('button.store')} color="success" action={() => clickStore()} />}
                </Loading>
            </ActionMenu>
            <Card>
                {foundNothing && <h3 className="text-center">{t('search-field.empty-loot')}</h3>}
                {foundItem && (
                    <>
                        <h3 className="text-center">
                            {t('search-field.loot')}&nbsp;{foundItem.name},{t('search-field.should-store')}
                        </h3>
                        <br />
                        <div className="row">
                            <div className="col-2 d-none d-sm-block" />
                            <div className="col-12 col-sm-8">
                                <ItemTable item={foundItem} />
                            </div>
                            <div className="col-2 d-none d-sm-block" />
                        </div>
                    </>
                )}
            </Card>
        </>
    );
};

export default LootEnemy;
