import React from 'react';
import Bordered from '../Styled/Bordered';
import Title from '../Styled/Title';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { colors } from '../constants';

type SectionProps = {
    fit?: boolean;
};
const Section = styled.div<SectionProps>`
    display: ${(props: SectionProps) => (props.fit ? 'flex' : 'block')};
    flex-direction: column;
`;

type ContentProps = {
    scroll?: boolean;
};

const Content = styled(Bordered)<ContentProps>`
    padding: ${(props: ContentProps) => (props.scroll ? '0' : '1rem')};
    flex: 1;
    height: 100%;
    overflow: ${(props: ContentProps) => (props.scroll ? 'hidden' : 'initial')};
    position: relative;

    @media screen and (min-width: 600px) {
        padding: ${(props: ContentProps) => (props.scroll ? '0' : '1.5rem')};
    }

    a {
        color: white;
    }
`;

type InnerProps = {
    scroll?: boolean;
};

const Inner = styled.div<InnerProps>`
    position: ${(props: InnerProps) => (props.scroll ? 'absolute' : 'static')};
    left: 10px;
    top: 10px;
    right: 10px;
    bottom: 10px;
    border-radius: 8px;
    overflow: ${(props: InnerProps) => (props.scroll ? 'auto' : 'initial')};
    padding: ${(props: ContentProps) => (props.scroll ? '0.7rem' : '0')};

    ::-webkit-scrollbar {
        width: 7px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${colors.primary};
        border-radius: 6px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: ${colors.primary};
    }

    @media screen and (min-width: 600px) {
        padding: ${(props: ContentProps) => (props.scroll ? '1rem' : '0')};
    }
`;

interface IProps {
    title?: string;
    children?: any;
    loading?: boolean;
    fit?: boolean;

    [prop: string]: any;
}

export default function Card(props: IProps) {
    const { t } = useTranslation();
    const { title, children, scroll, ...otherProps } = props;

    return (
        <Section {...otherProps}>
            <Content scroll={scroll}>
                <Inner scroll={scroll}>
                    {title && <Title>{t(`${title}`)}</Title>}
                    {children}
                </Inner>
            </Content>
        </Section>
    );
}
