import React from 'react';
import CharContextProvider from './CharContext';
import BattleContextProvider from './BattleContext';
import StatsContextProvider from './StatsContext';
import UserContextProvider from './UserContext';
import ArenaContextProvider from './ArenaContext';

interface IProps {
    children: React.ReactNode;
}

const ContextCombiner: React.FC<IProps> = (props: IProps) => {
    return (
        <UserContextProvider>
            <CharContextProvider>
                <BattleContextProvider>
                    <ArenaContextProvider>
                        <StatsContextProvider>{props.children}</StatsContextProvider>
                    </ArenaContextProvider>
                </BattleContextProvider>
            </CharContextProvider>
        </UserContextProvider>
    );
};

export default ContextCombiner;
