import React, { ComponentProps } from 'react';
import Button from '../Button/Button';

import './ActionButton.css';

interface IProps extends ComponentProps<any> {
    type?: 'button' | 'submit' | 'reset';
    color?: 'primary' | 'success' | 'danger' | 'warning' | 'secondary';
    label?: string;
    action?: any;
    isEmpty?: boolean;
    disabled?: boolean;
}

const ActionButton = (props: IProps) => {
    return (
        <div className={`action-button ${props.isEmpty ? 'd-none d-sm-block' : ''}`}>
            {!props.isEmpty ? (
                <Button type={props.type || 'button'} color={props.color || 'primary'} label={props.label || ''} action={props.action} disabled={props.disabled || false} />
            ) : (
                <></>
            )}
        </div>
    );
};

export default ActionButton;
