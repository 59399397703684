import React from 'react';
import styled from '@emotion/styled';
import { spacing } from '../constants';
import ReactTooltip from 'react-tooltip';
import Icon from '../Icon/Icon';

const itemSize = `48px`;

const ItemStyled = styled.div`
    width: ${itemSize};
    margin-right: ${spacing.p1};
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
`;

interface IItemProps {
    image?: string;
    title?: string;
    color?: string;
}

export default function Item(props: IItemProps) {
    const { image, title, color } = props;
    const itemId = `itemId_${Math.random()}`;
    return (
        <>
            <ItemStyled data-tip data-for={itemId}>
                {image && <Icon name={image} color={color} />}
            </ItemStyled>
            <ReactTooltip id={itemId} aria-haspopup="true" className="soTooltip">
                {title}
            </ReactTooltip>
        </>
    );
}
