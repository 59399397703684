import React, { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import './BattleButton.css';

interface IProps extends ComponentProps<any> {
    label: string;
    action: any;
}

const BattleButton = (props: IProps) => {
    const { t } = useTranslation();

    const mapAttack = (label: string) => {
        const labels = label.split('/');

        return labels.map((action: string) => {
            const split = action.split(':');
            const stars = split[0];
            const name = split[1];

            let startSigns = '';
            let j;

            for (j = 0; j < parseInt(stars); j++) {
                startSigns += '★';
            }

            return (
                <div key={uuidv4()}>
                    <div className="row">
                        {startSigns.length === 0 && <div className="col text-center fw-bold">{t(`${name}`)}</div>}
                        {startSigns.length !== 0 && (
                            <>
                                <div className="col text-end">{startSigns}</div>
                                <div className="col text-start fw-bold">{t(`${name}`)}</div>
                            </>
                        )}
                    </div>
                </div>
            );
        });
    };

    return (
        <div className="battle-button">
            <div className="d-grid gap-2">
                <button onClick={props.action} style={{ height: '100%' }} type="button" className="btn btn-outline-secondary">
                    {mapAttack(props.label)}
                </button>
            </div>
        </div>
    );
};

export default BattleButton;
