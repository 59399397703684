import React, { ComponentProps } from 'react';

interface IProps extends ComponentProps<any> {
    color?: 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'danger';
    icon?: string;
    text?: string;
    value?: string | number;
    onClick?: any;
    isPercentage?: boolean;
}

const BankDeal = (props: IProps) => {
    const { color, text, value, onClick, isPercentage } = props;

    return (
        <div className={`card text-white bg-${color} mb-3`} onClick={onClick}>
            <div className="card-header text-center fw-bold">{text}</div>
            <div className="card-body bank-deal">
                <h3 className="text-center">
                    {value}
                    {isPercentage && <span>%</span>}
                </h3>
            </div>
        </div>
    );
};

export default BankDeal;
