import styled from '@emotion/styled';

export const colors = {
    bg: '#0005',
    bgSolid: '#000e',
    text: '#fff',
    black: '#000',
    highlight: '#fbff38',
    lighter: '#D2BDA1',
    darker: '#0a223c',
    primary: '#0067FF',
    secondary: '#FFCB00',
    tertiary: '#FF6400',
    action: '#15d7ea',
    disabled: '#686868',
    border: '#1448af',
};

export const spacing = {
    p1: '5px',
    p2: '8px',
    r1: '1rem',
};

export const shadow = {
    box: '3px 6px 6px #0002, -3px 6px 6px #0002',
    single: '3px 3px 6px #0008',
};

export const Center = styled.div`
    display: flex;
    justify-content: center;
`;
