import React, { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import './ItemTable.css';

import { IEquipmentItem, IItem, IPotionItem, IStatsItem } from '../../../interfaces/gameInterfaces';

import Icon from '../../../ui/Icon/Icon';

interface IProps extends ComponentProps<any> {
    item: IItem | IEquipmentItem | IPotionItem;
}

const ItemTable = (props: IProps) => {
    const { t } = useTranslation();

    return (
        <table className="table table-sm table-borderless text-light">
            <thead>
                <tr>
                    <th className="text-center" colSpan={2}>
                        <Icon name={props.item.image} rarity={props.item.itemRarity} />
                    </th>
                </tr>
                <tr>
                    <th className="text-center" colSpan={2}>
                        {props.item.name}
                    </th>
                </tr>
                <tr>
                    <th className="text-center" colSpan={2}>
                        {props.item.description}
                    </th>
                </tr>
                <tr>
                    <th className="text-center" colSpan={2}>
                        {props.item.location}
                    </th>
                </tr>
            </thead>
            <tbody>
                {(props.item as IStatsItem).health !== undefined && (props.item as IStatsItem).health !== null && (
                    <tr>
                        <td className="text-end w-col-50">{t('common.health')}</td>
                        <td className="text-start w-col-50">{(props.item as IStatsItem).health}</td>
                    </tr>
                )}
                {(props.item as IStatsItem).damage !== undefined && (props.item as IStatsItem).damage !== null && (
                    <tr>
                        <td className="text-end w-col-50">{t('common.damage')}</td>
                        <td className="text-start w-col-50">{(props.item as IStatsItem).damage}</td>
                    </tr>
                )}
                {(props.item as IStatsItem).spell !== undefined && (props.item as IStatsItem).spell !== null && (
                    <tr>
                        <td className="text-end w-col-50">{t('common.spell')}</td>
                        <td className="text-start w-col-50">{(props.item as IStatsItem).spell}</td>
                    </tr>
                )}
                {(props.item as IStatsItem).defense !== undefined && (props.item as IStatsItem).defense !== null && (
                    <tr>
                        <td className="text-end w-col-50">{t('common.defense')}</td>
                        <td className="text-start w-col-50">{(props.item as IStatsItem).defense}</td>
                    </tr>
                )}
                {(props.item as IStatsItem).agility !== undefined && (props.item as IStatsItem).agility !== null && (
                    <tr>
                        <td className="text-end w-col-50">{t('common.agility')}</td>
                        <td className="text-start w-col-50">{(props.item as IStatsItem).agility}</td>
                    </tr>
                )}
                {(props.item as IEquipmentItem).forClass !== undefined && (props.item as IEquipmentItem).forClass !== null && (
                    <tr>
                        <td className="text-end w-col-50">{t('common.for-class')}</td>
                        <td className="text-start w-col-50">{(props.item as IEquipmentItem).forClass}</td>
                    </tr>
                )}
                {(props.item as IEquipmentItem).forRace !== undefined && (props.item as IEquipmentItem).forRace !== null && (
                    <tr>
                        <td className="text-end w-col-50">{t('common.for-race')}</td>
                        <td className="text-start w-col-50">{(props.item as IEquipmentItem).forRace}</td>
                    </tr>
                )}
                {props.item.buy !== undefined && (
                    <tr>
                        <td className="text-end w-col-50">{t('common.purchase-price')}</td>
                        <td className="text-start w-col-50">{props.item.buy}</td>
                    </tr>
                )}
                {props.item.sell !== undefined && (
                    <tr>
                        <td className="text-end w-col-50">{t('common.sell-price')}</td>
                        <td className="text-start w-col-50">{props.item.sell}</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};

export default ItemTable;
