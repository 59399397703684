import React from 'react';
import { useTranslation } from 'react-i18next';

import './Tutorial.css';

import ActionButton from '../elements/ActionButton/ActionButton';

interface IProps {
    showModal: Function;
}

const Tutorial = (props: IProps) => {
    const { t } = useTranslation();

    const [showModal, setShowModal] = React.useState<boolean>(true);

    const clickClose = async () => {
        setShowModal(false);
        props.showModal(false);
    };

    const dynamicModalClass = () => (showModal ? { display: 'block' } : { display: 'none' });

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return showModal ? (
        <div id="modal fade" tabIndex={-1} role="dialog" className={`modal ${showModal ? 'show' : 'hide'}`} style={dynamicModalClass()}>
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <h4 className="text-center text-dark">{t('tutorial.game-ui')}</h4>
                                <p className="text-dark">{t('tutorial.game-ui-1')}</p>
                                <p className="text-dark">{t('tutorial.game-ui-2')}</p>
                            </div>
                            <div className="col-12 col-sm-6">
                                <h4 className="text-center text-dark">{t('tutorial.stats')}</h4>
                                <p className="text-dark">{t('tutorial.stats-1')}</p>
                                <ul>
                                    <li className="text-dark">{t('tutorial.stats-1-a')}</li>
                                    <li className="text-dark">{t('tutorial.stats-1-b')}</li>
                                    <li className="text-dark">{t('tutorial.stats-1-c')}</li>
                                    <li className="text-dark">{t('tutorial.stats-1-d')}</li>
                                    <li className="text-dark">{t('tutorial.stats-1-e')}</li>
                                </ul>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <h4 className="text-center text-dark">{t('location.map')}</h4>
                                <p className="text-dark">{t('tutorial.map-1')}</p>
                                <p className="text-dark">{t('tutorial.map-2')}</p>
                                <p className="text-dark">{t('tutorial.map-3')}</p>
                                <p className="text-dark">{t('tutorial.map-4')}</p>
                                <p className="text-dark">{t('tutorial.map-5')}</p>
                                <ul>
                                    <li className="text-dark">{t('tutorial.map-5-a')}</li>
                                    <li className="text-dark">{t('tutorial.map-5-b')}</li>
                                    <li className="text-dark">{t('tutorial.map-5-c')}</li>
                                    <li className="text-dark">{t('tutorial.map-5-d')}</li>
                                </ul>
                                <p className="text-dark">{t('tutorial.map-6')}</p>
                                <p className="text-dark">
                                    <kbd>W</kbd> / <kbd>↑</kbd> - {t('tutorial.map-w')}
                                </p>
                                <p className="text-dark">
                                    <kbd>A</kbd> / <kbd>←</kbd> - {t('tutorial.map-a')}
                                </p>
                                <p className="text-dark">
                                    <kbd>S</kbd> / <kbd>↓</kbd> - {t('tutorial.map-s')}
                                </p>
                                <p className="text-dark">
                                    <kbd>D</kbd> / <kbd>→</kbd> - {t('tutorial.map-d')}
                                </p>
                            </div>
                            <div className="col-12 col-sm-6">
                                <h4 className="text-center text-dark">{t('location.battle-pve')}</h4>
                                <p className="text-dark">{t('tutorial.battle-pve-1')}</p>
                                <p className="text-dark">{t('tutorial.battle-pve-2')}</p>
                                <p className="text-dark">{t('tutorial.battle-pve-3')}</p>
                                <ul>
                                    <li className="text-dark">{t('tutorial.battle-pve-3-a')}</li>
                                    <li className="text-dark">{t('tutorial.battle-pve-3-b')}</li>
                                    <li className="text-dark">{t('tutorial.battle-pve-3-c')}</li>
                                </ul>
                                <p className="text-dark">{t('tutorial.battle-pve-4')}</p>
                                <p className="text-dark">
                                    <kbd>{t('battle-action.dmg')}</kbd> - {t('tutorial.battle-pve-4-a')}
                                </p>
                                <p className="text-dark">
                                    <kbd>{t('battle-action.dpt')}</kbd> - {t('tutorial.battle-pve-4-b')}
                                </p>
                                <p className="text-dark">
                                    <kbd>{t('battle-action.heal')}</kbd> - {t('tutorial.battle-pve-4-c')}
                                </p>
                                <p className="text-dark">
                                    <kbd>{t('battle-action.hpt')}</kbd> - {t('tutorial.battle-pve-4-d')}
                                </p>
                                <p className="text-dark">
                                    <kbd>{t('battle-action.sd')}</kbd> - {t('tutorial.battle-pve-4-e')}
                                </p>
                                <p className="text-dark">
                                    <kbd>{t('battle-action.sdpt')}</kbd> - {t('tutorial.battle-pve-4-f')}
                                </p>
                                <p className="text-dark">
                                    <kbd>{t('battle-action.sclr')}</kbd> - {t('tutorial.battle-pve-4-g')}
                                </p>
                                <p className="text-dark">
                                    <kbd>{t('battle-action.oclr')}</kbd> - {t('tutorial.battle-pve-4-h')}
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <h4 className="text-center text-dark">{t('location.bank')}</h4>
                                <p className="text-dark">{t('tutorial.bank-1')}</p>
                                <p className="text-dark">{t('tutorial.bank-2')}</p>
                                <p className="text-dark">{t('tutorial.bank-3')}</p>
                                <p className="text-dark">{t('tutorial.bank-4')}</p>
                            </div>
                            <div className="col-12 col-sm-6">
                                <h4 className="text-center text-dark">{t('location.arena')}</h4>
                                <p className="text-dark">{t('tutorial.arena-1')}</p>
                                <p className="text-dark">{t('tutorial.arena-2')}</p>
                                <p className="text-dark">{t('tutorial.arena-3')}</p>
                                <p className="text-dark">
                                    <kbd>{t('arena-action.spare')}</kbd> - {t('tutorial.arena-3-a')}
                                </p>
                                <p className="text-dark">
                                    <kbd>{t('arena-action.kill')}</kbd> - {t('tutorial.arena-3-b')}
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <h4 className="text-center text-dark">{t('location.armorsmith')}</h4>
                                <p className="text-dark">{t('tutorial.armorsmith-1')}</p>
                                <p className="text-dark">{t('tutorial.armorsmith-2')}</p>
                            </div>
                            <div className="col-12 col-sm-6">
                                <h4 className="text-center text-dark">{t('location.weaponsmith')}</h4>
                                <p className="text-dark">{t('tutorial.weaponsmith-1')}</p>
                                <p className="text-dark">{t('tutorial.weaponsmith-2')}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <h4 className="text-center text-dark">{t('location.pharmacist')}</h4>
                                <p className="text-dark">{t('tutorial.pharmacist-1')}</p>
                                <p className="text-dark">{t('tutorial.pharmacist-2')}</p>
                                <p className="text-dark">{t('tutorial.pharmacist-3')}</p>
                            </div>
                            <div className="col-12 col-sm-6">
                                <h4 className="text-center text-dark">{t('location.merchant')}</h4>
                                <p className="text-dark">{t('tutorial.merchant-1')}</p>
                                <p className="text-dark">{t('tutorial.merchant-2')}</p>
                                <p className="text-dark">{t('tutorial.merchant-3')}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <h4 className="text-center text-dark">{t('location.gamble')}</h4>
                                <p className="text-dark">{t('tutorial.gamble-1')}</p>
                                <p className="text-dark">{t('tutorial.gamble-2')}</p>
                            </div>
                            <div className="col-12 col-sm-6">
                                <h4 className="text-center text-dark">{t('location.inn')}</h4>
                                <p className="text-dark">{t('tutorial.inn-1')}</p>
                                <p className="text-dark">{t('tutorial.inn-2')}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <h4 className="text-center text-dark">{t('location.skinner')}</h4>
                                <p className="text-dark">{t('tutorial.skinner-1')}</p>
                                <p className="text-dark">{t('tutorial.skinner-2')}</p>
                            </div>
                            <div className="col-12 col-sm-6">
                                <h4 className="text-center text-dark">{t('location.craftsman')}</h4>
                                <p className="text-dark">{t('tutorial.craftsman-1')}</p>
                                <p className="text-dark">{t('tutorial.craftsman-2')}</p>
                                <p className="text-dark">{t('tutorial.craftsman-3')}</p>
                                <p className="text-dark">{t('tutorial.craftsman-4')}</p>
                                <p className="text-dark">{t('tutorial.craftsman-5')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <ActionButton isEmpty={true} />
                        <ActionButton isEmpty={true} />
                        <ActionButton type="button" label={t('button.close')} color="danger" action={clickClose} />
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

export default Tutorial;
