import React from 'react';
import styled from '@emotion/styled';
import {
    GiAnimalHide,
    GiArmoredPants,
    GiArmorVest,
    GiAxeSword,
    GiBackpack,
    GiBadGnome,
    GiBarbute,
    GiBarrel,
    GiBatteredAxe,
    GiBelt,
    GiBeltArmor,
    GiBlackball,
    GiBlackBelt,
    GiBlackKnightHelm,
    GiBo,
    GiBoneKnife,
    GiBookmarklet,
    GiBoots,
    GiBorderedShield,
    GiBowieKnife,
    GiBranchArrow,
    GiBreastplate,
    GiBrickPile,
    GiBrickWall,
    GiBroadsword,
    GiBrokenShield,
    GiBrutalHelm,
    GiCandleFlame,
    GiCandleHolder,
    GiCapeArmor,
    GiChestArmor,
    GiClayBrick,
    GiCloakDagger,
    GiClosedBarbute,
    GiClothes,
    GiCottonFlower,
    GiCrescentStaff,
    GiCrystalWand,
    GiDragonShield,
    GiDreadSkull,
    GiDwarfFace,
    GiDwarfHelmet,
    GiElfHelmet,
    GiEnergyShield,
    GiEnergySword,
    GiEnlightenment,
    GiFairyWand,
    GiFallingLeaf,
    GiFishingPole,
    GiFlowerPot,
    GiFlowerTwirl,
    GiFoxTail,
    GiFragmentedSword,
    GiGladius,
    GiGoblinHead,
    GiGroundSprout,
    GiHalberd,
    GiHalfLog,
    GiHand,
    GiHaunting,
    GiHeartArmor,
    GiHolyOak,
    GiHoneycomb,
    GiKatana,
    GiLamellar,
    GiLanternFlame,
    GiLayeredArmor,
    GiLeafSwirl,
    GiLeatherArmor,
    GiLeatherVest,
    GiLindenLeaf,
    GiLog,
    GiLunarWand,
    GiMagicPotion,
    GiMailShirt,
    GiMatchbox,
    GiMeat,
    GiMussel,
    GiNewspaper,
    GiOakLeaf,
    GiOrbWand,
    GiOrcHead,
    GiOre,
    GiOysterPearl,
    GiPeach,
    GiPearlNecklace,
    GiPlanks,
    GiPlastron,
    GiPointyHat,
    GiPopeCrown,
    GiPotato,
    GiPouringChalice,
    GiPrayingMantis,
    GiPteruges,
    GiRaspberry,
    GiRelicBlade,
    GiRolledCloth,
    GiRoundBottomFlask,
    GiScrollUnfurled,
    GiSesame,
    GiSewingNeedle,
    GiSewingString,
    GiShardSword,
    GiSharpAxe,
    GiShieldcomb,
    GiShinyApple,
    GiShorts,
    GiShutRose,
    GiSkirt,
    GiSkullShield,
    GiSkullStaff,
    GiSonicShoes,
    GiSpellBook,
    GiSquareBottle,
    GiSteeltoeBoots,
    GiStoneAxe,
    GiTatteredBanner,
    GiTiedScroll,
    GiTreeBranch,
    GiTribalPendant,
    GiTridentShield,
    GiTroll,
    GiTrousers,
    GiTumor,
    GiTurban,
    GiUpgrade,
    GiVikingHelmet,
    GiVisoredHelm,
    GiWarAxe,
    GiWarlockEye,
    GiWingedScepter,
    GiWireCoil,
    GiWitchFlight,
    GiWizardStaff,
    GiWomanElfFace,
    GiWoodBeam,
    GiWoodStick,
    GiWool,
    GiZigzagLeaf,
} from 'react-icons/gi';
import { Center, colors } from '../constants';

// const itemType: { [key: string]: any } = {
//     Natural: '#fff6',
//     Loot: '#fff6',
//     Farm: '#fff6',
//     Mine: '#fff6',
//     Craft: '#fff6',
//     Quest: '#fff6',
//     Unique: '#fff6',
//     Tree: '#fff6',
//     Shop: '#fff6',
// };

const itemRarities: { [key: string]: any } = {
    Poor: '#333',
    Common: '#fff',
    Uncommon: '#0f0',
    Rare: '#00f',
    Epic: '#c0f',
    Legendary: '#f60',
    Artifact: '#fc0',
};

const iconColors: { [key: string]: any } = {
    None: '#999',
    Megra: '#fff',
    Ambut: '#fff',
    Ruc: '#fff',
    Agarwood: '#a8937d',
    Ebony: '#cc6b00',
    Blackwood: '#292c40',
    Coal: '#333333',
    Iron: '#e6e7e8',
    Mineral: '#3c8282',
    Sand: '#c19a6b',
    Health: '#ff0000',
    GlassMarbles: '#f5a623',
};

const iconsMap: { [key: string]: any } = {
    // Races
    Barbute: GiBarbute,
    DwarfFace: GiDwarfFace,
    WomanElfFace: GiWomanElfFace,
    BadGnome: GiBadGnome,
    OrcHead: GiOrcHead,
    DreadSkull: GiDreadSkull,
    Troll: GiTroll,
    GoblinHead: GiGoblinHead,

    // Classes
    Haunting: GiHaunting,
    HolyOak: GiHolyOak,
    FlowerTwirl: GiFlowerTwirl,
    TridentShield: GiTridentShield,
    Barrel: GiBarrel,
    TribalPendant: GiTribalPendant,
    PouringChalice: GiPouringChalice,
    WarlockEye: GiWarlockEye,
    WitchFlight: GiWitchFlight,
    BranchArrow: GiBranchArrow,
    TatteredBanner: GiTatteredBanner,
    PrayingMantis: GiPrayingMantis,
    CloakDagger: GiCloakDagger,
    AxeSword: GiAxeSword,

    //Items
    Sesame: GiSesame,
    GroundSprout: GiGroundSprout,
    FallingLeaf: GiFallingLeaf,
    Potato: GiPotato,
    SewingNeedle: GiSewingNeedle,
    SquareBottle: GiSquareBottle,
    CottonFlower: GiCottonFlower,
    WireCoil: GiWireCoil,
    Honeycomb: GiHoneycomb,
    Matchbox: GiMatchbox,
    FoxTail: GiFoxTail,
    Meat: GiMeat,
    AnimalHide: GiAnimalHide,
    Tumor: GiTumor,
    ShinyApple: GiShinyApple,
    Peach: GiPeach,
    Raspberry: GiRaspberry,
    Log: GiLog,
    Ore: GiOre,
    // Gravel: GiGravel,
    Wool: GiWool,
    RolledCloth: GiRolledCloth,
    Clothes: GiClothes,
    SewingString: GiSewingString,
    TreeBranch: GiTreeBranch,
    LeafSwirl: GiLeafSwirl,
    OakLeaf: GiOakLeaf,
    ZigzagLeaf: GiZigzagLeaf,
    LindenLeaf: GiLindenLeaf,
    WoodStick: GiWoodStick,
    FishingPole: GiFishingPole,
    ClayBrick: GiClayBrick,
    BrickPile: GiBrickPile,
    BrickWall: GiBrickWall,
    CandleHolder: GiCandleHolder,
    LanternFlame: GiLanternFlame,
    CandleFlame: GiCandleFlame,
    FlowerPot: GiFlowerPot,
    HalfLog: GiHalfLog,
    WoodBeam: GiWoodBeam,
    Planks: GiPlanks,
    OysterPearl: GiOysterPearl,
    Mussel: GiMussel,
    PearlNecklace: GiPearlNecklace,
    ShutRose: GiShutRose,

    // Equipment
    Hand: GiHand,

    // Axes
    BatteredAxe: GiBatteredAxe,
    Halberd: GiHalberd,
    SharpAxe: GiSharpAxe,
    StoneAxe: GiStoneAxe,
    WarAxe: GiWarAxe,

    // Belts
    Belt: GiBelt,
    BeltArmor: GiBeltArmor,
    BlackBelt: GiBlackBelt,
    Pteruges: GiPteruges,

    // Books
    SpellBook: GiSpellBook,
    ScrollUnfurled: GiScrollUnfurled,
    TiedScroll: GiTiedScroll,
    Bookmarklet: GiBookmarklet,
    Enlightenment: GiEnlightenment,

    // Chest
    ArmorVest: GiArmorVest,
    Breastplate: GiBreastplate,
    CapeArmor: GiCapeArmor,
    ChestArmor: GiChestArmor,
    HeartArmor: GiHeartArmor,
    Lamellar: GiLamellar,
    LeatherArmor: GiLeatherArmor,
    LeatherVest: GiLeatherVest,
    Plastron: GiPlastron,
    MailShirt: GiMailShirt,

    // Feet
    Boots: GiBoots,
    SonicShoes: GiSonicShoes,
    SteeltoeBoots: GiSteeltoeBoots,

    // Helmets
    BrutalHelm: GiBrutalHelm,
    ClosedBarbute: GiClosedBarbute,
    DwarfHelmet: GiDwarfHelmet,
    ElfHelmet: GiElfHelmet,
    BlackKnightHelm: GiBlackKnightHelm,
    PopeCrown: GiPopeCrown,
    Turban: GiTurban,
    VikingHelmet: GiVikingHelmet,
    VisoredHelm: GiVisoredHelm,
    PointyHat: GiPointyHat,

    // Pants
    ArmoredPants: GiArmoredPants,
    Shorts: GiShorts,
    Skirt: GiSkirt,
    Trousers: GiTrousers,

    // Shields
    BorderedShield: GiBorderedShield,
    BrokenShield: GiBrokenShield,
    EnergyShield: GiEnergyShield,
    LayeredArmor: GiLayeredArmor,
    Shieldcomb: GiShieldcomb,
    SkullShield: GiSkullShield,
    DragonShield: GiDragonShield,

    // Staff
    Bo: GiBo,
    WizardStaff: GiWizardStaff,
    SkullStaff: GiSkullStaff,
    CrescentStaff: GiCrescentStaff,
    WingedScepter: GiWingedScepter,

    // Swords
    BoneKnife: GiBoneKnife,
    BowieKnife: GiBowieKnife,
    Broadsword: GiBroadsword,
    EnergySword: GiEnergySword,
    FragmentedSword: GiFragmentedSword,
    Gladius: GiGladius,
    Katana: GiKatana,
    RelicBlade: GiRelicBlade,
    ShardSword: GiShardSword,

    // Wands
    OrbWand: GiOrbWand,
    LunarWand: GiLunarWand,
    CrystalWand: GiCrystalWand,
    FairyWand: GiFairyWand,

    // News
    Newspaper: GiNewspaper,

    // Potions
    RoundBottomFlask: GiRoundBottomFlask,

    // Glass Marbles
    Blackball: GiBlackball,
    Backpack: GiBackpack,
    MagicPotion: GiMagicPotion,
    Upgrade: GiUpgrade,
};

type ImageProps = {
    flipX?: boolean;
    flipY?: boolean;
    color?: string;
};

const Image = styled.div<ImageProps>`
    position: absolute;
    width: 80%;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%) ${(p: ImageProps) => (p.flipX ? 'rotateX(180deg)' : '')} ${(p: ImageProps) => (p.flipY ? 'rotateY(180deg)' : '')};

    svg {
        width: 100%;
        height: 100%;
        fill: ${(p: ImageProps) => p.color || '#fff'};
    }
`;

type BgProps = {
    color?: string;
    rarity?: string;
    animateRaise?: boolean;
    home?: boolean;
};

const Bg = styled.div<BgProps>`
    background: ${(p: BgProps) => p.color || '#0005'};
    color: '#ff0';
    position: relative;
    ${(p: BgProps) => (p.home ? `min-width: 100px;` : `min-width: 32px;`)}
    ${(p: BgProps) => (p.home ? `max-width: 100px;` : `max-width: 96px;`)}
    width: 100%;
    border-radius: 12px;
    border: 2px solid ${(p: BgProps) => p.rarity || colors.darker};

    &::before {
        display: block;
        content: '';
        padding-bottom: 100%;
    }

    ${(p: BgProps) =>
        p.animateRaise &&
        `
    &:hover,
    &:focus {
        box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
        transform: translateY(-0.25em);
    }`}
`;

interface IProps {
    name?: string;
    rarity?: string;
    flipX?: boolean;
    flipY?: boolean;
    color?: string;
    animateRaise?: boolean;
    home?: boolean;
}

export default function Icon(props: IProps) {
    const { name = 'none' } = props;
    const { rarity = null } = props;
    const { color = null } = props;
    const { animateRaise = false, home = false } = props;
    const split = name.split('_');

    let imageName = split.length > 1 ? split[1] : split[0];
    let itemType = split.length > 1 && split[0];

    const renderColor = color ? color : itemType ? iconColors[itemType] : null;

    return (
        <Center>
            <Bg data-image={imageName} data-type={itemType} rarity={rarity && itemRarities[rarity]} animateRaise={animateRaise} home={home}>
                <Image {...props} color={renderColor}>
                    {iconsMap.hasOwnProperty(imageName) ? iconsMap[imageName]() : ``}
                </Image>
            </Bg>
        </Center>
    );
}
