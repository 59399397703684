import React, { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import './BattleTable.css';

interface IProps extends ComponentProps<any> {
    myStats: any;
    opponent: any;
}

const BattleTable = (props: IProps) => {
    const { t } = useTranslation();

    const getStatsColor = (myStats: any, opponent: any) => {
        if (myStats > opponent) {
            return 'text-success';
        } else if (myStats === opponent) {
            return 'text-primary';
        } else {
            return 'text-danger';
        }
    };

    const { myStats, opponent } = props;

    return (
        <div className="table-responsive">
            <table className="table table-borderless text-light">
                <tbody>
                    <tr>
                        <td className={`text-end fw-bold ${getStatsColor(myStats.combatLevel, opponent.combatLevel)}`}>{myStats.combatLevel}</td>
                        <td className="text-center">{t('common.level')}</td>
                        <td className={`text-start fw-bold ${getStatsColor(opponent.combatLevel, myStats.combatLevel)}`}>{opponent.combatLevel}</td>
                    </tr>
                    <tr>
                        <td className={`text-end fw-bold ${getStatsColor(myStats.health, opponent.health)}`}>
                            {myStats.currentHealth} / {myStats.health}
                        </td>
                        <td className="text-center">{t('common.health')}</td>
                        <td className={`text-start fw-bold ${getStatsColor(opponent.health, myStats.health)}`}>
                            {opponent.currentHealth} / {opponent.health}
                        </td>
                    </tr>
                    <tr>
                        <td className={`text-end fw-bold ${getStatsColor(myStats.damage, opponent.damage)}`}>{myStats.damage}</td>
                        <td className="text-center">{t('common.damage')}</td>
                        <td className={`text-start fw-bold ${getStatsColor(opponent.damage, myStats.damage)}`}>{opponent.damage}</td>
                    </tr>
                    <tr>
                        <td className={`text-end fw-bold ${getStatsColor(myStats.spell, opponent.spell)}`}>{myStats.spell}</td>
                        <td className="text-center">{t('common.spell')}</td>
                        <td className={`text-start fw-bold ${getStatsColor(opponent.spell, myStats.spell)}`}>{opponent.spell}</td>
                    </tr>
                    <tr>
                        <td className={`text-end fw-bold ${getStatsColor(myStats.defense, opponent.defense)}`}>{myStats.defense}</td>
                        <td className="text-center">{t('common.defense')}</td>
                        <td className={`text-start fw-bold ${getStatsColor(opponent.defense, myStats.defense)}`}>{opponent.defense}</td>
                    </tr>
                    <tr>
                        <td className={`text-end fw-bold ${getStatsColor(myStats.agility, opponent.agility)}`}>{myStats.agility}</td>
                        <td className="text-center">{t('common.agility')}</td>
                        <td className={`text-start fw-bold ${getStatsColor(opponent.agility, myStats.agility)}`}>{opponent.agility}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default BattleTable;
