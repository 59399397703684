import React, { ComponentProps, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

import './Main.css';

import Sidebar from '../../components/Sidebar/Sidebar';
import Pharmacist from '../../views/Pharmacist/Pharmacist';
import BattlePve from '../../views/BattlePve/BattlePve';
import PickFruit from '../../views/PickFruit/PickFruit';
import CutTree from '../../views/CutTree/CutTree';
import SearchField from '../../views/SearchField/SearchField';
import Map from '../../views/Map/Map';
import HighLow from '../../views/HighLow/HighLow';
import Gamble from '../../views/Gamble/Gamble';
import Inn from '../../views/Inn/Inn';
import Skinner from '../../views/Skinner/Skinner';
import Merchant from '../../views/Merchant/Merchant';
import Weaponsmith from '../../views/Weaponsmith/Weaponsmith';
import Armorsmith from '../../views/Armorsmith/Armorsmith';
import Arena from '../../views/Arena/Arena';
import Town from '../../views/Town/Town';
import CharacterCreation from '../../views/CharacterCreation/CharacterCreation';
import Home from '../../views/Home/Home';
import UserProfile from '../../views/UserProfile/UserProfile';
import BankSavings from '../../views/BankSavings/BankSavings';
import LootEnemy from '../../views/LootEnemy/LootEnemy';
import Craftsman from '../../views/Craftsman/Craftsman';
import Camp from '../../views/Camp/Camp';
import HeroBar from '../../ui/HeroBar/HeroBar';
import Backpack from '../../components/Backpack/Backpack';
import Equipment from '../../components/Equipment/Equipment';
import PotionHolder from '../../components/PotionHolder/PotionHolder';
import Hero from '../../components/Statistics/Statistics';
import Bank from '../../views/Bank/Bank';
import DepositBoxUpgrades from '../../views/DepositBoxUpgrades/DepositBoxUpgrades';
import DepositBox from '../../views/DepositBox/DepositBox';

import { UserContext } from '../../context/UserContext';
import { spacing } from '../../ui/constants';

interface IProps extends ComponentProps<any> {}

const Wrapper = styled.div`
    margin: 0 auto;
    display: flex;
    height: 100vh;
    flex-direction: column;
    overflow: hidden;
    max-width: 1200px;
`;

const MainContent = styled.main`
    position: relative;
    flex: 1;
    height: 100vh;
    padding: 100px ${spacing.r1} 20px;
`;

const Main = (props: IProps) => {
    const navigate = useNavigate();

    const [showBackpack, setShowBackpack] = React.useState<boolean>(false);
    const [showEquipment, setShowEquipment] = React.useState<boolean>(false);
    const [showPotionHolder, setShowPotionHolder] = React.useState<boolean>(false);
    const [showHero, setShowHero] = React.useState<boolean>(false);

    // Context
    const [user] = React.useContext(UserContext);

    useEffect(() => {
        async function forceLogin() {
            if (user === null || user.jwt === null || user.jwt.length < 10) {
                navigate('/login');
                return;
            }
        }
        forceLogin();
    }, [user, navigate]);

    const clickBackpack = () => {
        setShowBackpack(true);
    };

    const clickEquipment = () => {
        setShowEquipment(true);
    };

    const clickPotionHolder = () => {
        setShowPotionHolder(true);
    };

    const clickHero = () => {
        setShowHero(true);
    };

    return (
        <Wrapper className="wrapper">
            <Sidebar />
            <MainContent>
                <Routes>
                    <Route path="/camp" element={<Camp />} />
                    <Route path="/craftsman" element={<Craftsman />} />
                    <Route path="/loot-enemy" element={<LootEnemy />} />
                    <Route path="/deposit-box" element={<DepositBox />} />
                    <Route path="/upgrade-deposit-box" element={<DepositBoxUpgrades />} />
                    <Route path="/bank-savings" element={<BankSavings />} />
                    <Route path="/bank" element={<Bank />} />
                    <Route path="/pharmacist" element={<Pharmacist />} />
                    <Route path="/battlepve" element={<BattlePve />} />
                    <Route path="/pickfruit" element={<PickFruit />} />
                    <Route path="/cuttree" element={<CutTree />} />
                    <Route path="/searchfield" element={<SearchField />} />
                    <Route path="/map" element={<Map />} />
                    <Route path="/highlow" element={<HighLow />} />
                    <Route path="/gamble" element={<Gamble />} />
                    <Route path="/inn" element={<Inn />} />
                    <Route path="/skinner" element={<Skinner />} />
                    <Route path="/merchant" element={<Merchant />} />
                    <Route path="/weaponsmith" element={<Weaponsmith />} />
                    <Route path="/armorsmith" element={<Armorsmith />} />
                    <Route path="/arena" element={<Arena />} />
                    <Route path="/town" element={<Town />} />
                    <Route path="/character-creation" element={<CharacterCreation />} />
                    <Route path="/my-profile" element={<UserProfile />} />
                    <Route path="*" element={<Home />} />
                </Routes>
                <HeroBar onBackpackClick={clickBackpack} onEquipmentClick={clickEquipment} onPotionHolderClick={clickPotionHolder} onHeroClick={clickHero} />
                {showBackpack && <Backpack showModal={setShowBackpack} />}
                {showEquipment && <Equipment showModal={setShowEquipment} />}
                {showPotionHolder && <PotionHolder showModal={setShowPotionHolder} />}
                {showHero && <Hero showModal={setShowHero} />}
            </MainContent>
        </Wrapper>
    );
};

export default Main;
