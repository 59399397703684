import React from 'react';
import { useLocalStorage } from 'react-use';

import { IJwtToken } from '../interfaces/gameInterfaces';

export const CharContext = React.createContext<any>(null);

interface IProps {
    children: React.ReactNode;
}

const CharContextProvider: React.FC<IProps> = (props: IProps) => {
    const [char, setChar] = useLocalStorage<IJwtToken | null>('CHAR_TOKEN', null);

    return <CharContext.Provider value={[char, setChar]}>{props.children}</CharContext.Provider>;
};

export default CharContextProvider;
