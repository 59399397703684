import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import './News.css';

import { INews } from '../../interfaces/gameInterfaces';

import apiProvider from '../../api';
import { UserContext } from '../../context/UserContext';

import NewsEntry from '../elements/NewsEntry/NewsEntry';
import ActionButton from '../elements/ActionButton/ActionButton';
import Loading from '../../ui/Loading/Loading';

interface IProps {
    showModal: Function;
}

const News = (props: IProps) => {
    const { t } = useTranslation();

    const [showModal, setShowModal] = React.useState<boolean>(true);
    const [news, setNews] = React.useState<INews[]>([]);

    // Context
    const [user] = React.useContext(UserContext);

    // Logout function
    const logout = async () => {
        await clickClose();
    };

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    useEffect(() => {
        async function fetchData() {
            const response = await apiProvider.newsGetLatestNews(user.jwt, logout);

            if (!response || response.error) return;

            setNews(response);
        }

        fetchData();
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------

    const clickClose = async () => {
        setShowModal(false);
        props.showModal(false);
    };

    // --------------------------------------------
    // UI Render Util Dynamic Generation Methods
    // --------------------------------------------

    const listNews = (list: any) => {
        return (
            list.length > 0 &&
            list.map(function (data: INews) {
                return <NewsEntry key={uuidv4()} data={data} />;
            })
        );
    };

    const dynamicModalClass = () => (showModal ? { display: 'block' } : { display: 'none' });

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return showModal ? (
        <div id="modal fade" tabIndex={-1} role="dialog" className={`modal ${showModal ? 'show' : 'hide'}`} style={dynamicModalClass()}>
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="row justify-content-md-center">{listNews(news)}</div>
                    </div>
                    <Loading>
                        <div className="modal-footer">
                            <ActionButton isEmpty={true} />
                            <ActionButton type="button" label={t('button.close')} color="danger" action={clickClose} />
                            <ActionButton isEmpty={true} />
                        </div>
                    </Loading>
                </div>
            </div>
        </div>
    ) : null;
};

export default News;
