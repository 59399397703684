import React, { ComponentProps } from 'react';
import { v4 as uuidv4 } from 'uuid';

import './CraftItem.css';

import { IItem } from '../../../interfaces/gameInterfaces';

import Icon from '../../../ui/Icon/Icon';

interface IProps extends ComponentProps<any> {
    item: IItem;
    onClick: Function;
    onDoubleClick: Function;
    selected: boolean;
}

const CraftItem = (props: IProps) => {
    const { item, onClick, onDoubleClick, selected } = props;

    return (
        <div key={uuidv4()} className="col-4 p-1">
            <div className="d-grid gap-2">
                <span onDoubleClick={() => onDoubleClick()} onClick={() => onClick()} className={`btn btn-outline-secondary ${selected ? 'active' : ''}`}>
                    <Icon name={item.image} rarity={item.itemRarity} />
                </span>
            </div>
        </div>
    );
};

export default CraftItem;
