import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { IBackpack, IEquipmentItem, IItem } from '../../interfaces/gameInterfaces';

import ActionButton from '../../components/elements/ActionButton/ActionButton';
import ActionMenu from '../../components/elements/ActionMenu/ActionMenu';
import ItemTable from '../../components/elements/ItemTable/ItemTable';
import Card from '../../ui/Card/Card';
import ItemList from '../../components/elements/ItemList/ItemList';

import apiProvider from '../../api';
import { CharContext } from '../../context/CharContext';
import { StatsContext } from '../../context/StatsContext';
import { defaultItem } from '../../interfaces/defaultValues';
import CraftItem from '../../components/elements/CraftItem/CraftItem';
import CraftResult from '../../components/elements/CraftResult/CraftResult';
import { toast } from 'react-toastify';
import Loading from '../../ui/Loading/Loading';

interface IProps {}

const Craftsman = (props: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [refreshState, setRefreshState] = React.useState<number>(1);
    const [selectedItem, setSelectedItem] = React.useState<IItem | null>(null);
    const [selectedIndex, setSelectedIndex] = React.useState<number | null>(null);
    const [craftItem1, setCraftItem1] = React.useState<IItem>(defaultItem);
    const [craftItem2, setCraftItem2] = React.useState<IItem>(defaultItem);
    const [craftItem3, setCraftItem3] = React.useState<IItem>(defaultItem);
    const [craftResult, setCraftResult] = React.useState<IItem>(defaultItem);
    const [backpack, setBackpack] = React.useState<IBackpack>([]);
    const [, setMyAmount] = React.useState<number | null>(null);

    // Context
    const [char, setChar] = React.useContext(CharContext);
    const [, setStats] = React.useContext(StatsContext);

    // Logout function
    const logout = () => {
        setChar(null);
        setStats(null);
        navigate('/');
    };

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    useEffect(() => {
        async function fetchData() {
            setBackpack([]);
            setCraftItem1(defaultItem);
            setCraftItem2(defaultItem);
            setCraftItem3(defaultItem);

            const [responseGlassMarbles, responseBackpack, responseStats] = await Promise.all([
                apiProvider.backpackGetAmountOfGlassMarbles(char.jwt, logout),
                apiProvider.backpackDownloadBackpack(char.jwt, logout),
                apiProvider.statsGetKaracterStats(char.jwt, logout),
            ]);

            if (!responseGlassMarbles || responseGlassMarbles.error) return;
            if (!responseBackpack || responseBackpack.error) return;
            if (!responseStats || responseStats.error) return;

            setStats(responseStats);
            setMyAmount(responseGlassMarbles.glassMarbles);
            setBackpack(responseBackpack);
        }

        fetchData();
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshState]);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------

    const clickCraft = async () => {
        const response = await apiProvider.craftingPostCraft(char.jwt, craftItem1.id, craftItem2.id, craftItem3.id, logout);
        if (!response || response.error) return;

        if (response.item) {
            setCraftResult(response.item);
            toast.success(response.msg);
        }

        setRefreshState(refreshState + 1);
    };

    const clickBack = () => {
        navigate('/town');
    };

    const clickBackpackItem = (data: IItem | IEquipmentItem) => {
        if (data.id === 1) {
            setSelectedItem(null);
        }

        setSelectedItem(data);

        if (selectedIndex === 1) {
            setCraftItem1(data);
        }

        if (selectedIndex === 2) {
            setCraftItem2(data);
        }

        if (selectedIndex === 3) {
            setCraftItem3(data);
        }
    };

    const clickCraftItem1 = () => {
        setSelectedIndex(1);
        setCraftItem1(defaultItem);
    };

    const clickCraftItem2 = () => {
        setSelectedIndex(2);
        setCraftItem2(defaultItem);
    };

    const clickCraftItem3 = () => {
        setSelectedIndex(3);
        setCraftItem3(defaultItem);
    };

    const doubleClickFastPick = (data: IItem | IEquipmentItem) => {
        if (craftItem1 === defaultItem) {
            setCraftItem1(data);
        } else if (craftItem2 === defaultItem) {
            setCraftItem2(data);
        } else if (craftItem3 === defaultItem) {
            setCraftItem3(data);
        }
    };

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return (
        <>
            <ActionMenu>
                <Loading>
                    <ActionButton type="button" label={t('button.back')} color="danger" action={clickBack} />
                    <ActionButton type="button" label={t('button.craft')} color="success" action={clickCraft} />
                </Loading>
            </ActionMenu>
            <Card>
                <div className="row">
                    <div className="col-8">
                        <div className="row">
                            <CraftItem onClick={clickCraftItem1} onDoubleClick={() => {}} item={craftItem1} selected={selectedIndex === 1} />
                            <CraftItem onClick={clickCraftItem2} onDoubleClick={() => {}} item={craftItem2} selected={selectedIndex === 2} />
                            <CraftItem onClick={clickCraftItem3} onDoubleClick={() => {}} item={craftItem3} selected={selectedIndex === 3} />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="row">
                            <CraftResult onClick={() => {}} onDoubleClick={() => {}} item={craftResult} />
                        </div>
                    </div>
                </div>
            </Card>
            <div className="row">
                <div className="col-12 col-sm-6">
                    <Card title="sidebar.backpack">
                        <ItemList items={backpack} onClick={clickBackpackItem} onDoubleClick={doubleClickFastPick} color="danger" />
                    </Card>
                </div>
                <div className="d-none d-sm-block col-12 col-sm-6">
                    <Card title="common.item-details">{selectedItem && selectedItem.id !== 1 && <ItemTable item={selectedItem} />}</Card>
                </div>
            </div>
        </>
    );
};

export default Craftsman;
