import React from 'react';
import styled from '@emotion/styled';
import { FaSyncAlt } from 'react-icons/fa';

import { useQue } from '../../api/api';

interface IProps {
    children?: any;
    apiKey?: string;
}

const Wrap = styled.div`
    position: relative;
    display: block;
`;

type LoaderProps = {
    show: boolean;
};
const Loader = styled.div<LoaderProps>`
    pointer-events: ${(p: LoaderProps) => (p.show ? 'all' : 'none')};
    opacity: ${(p: LoaderProps) => (p.show ? '1' : '0')};
    transition: opacity 0.1s, transform 0.2s;
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 40;
    background-color: #0006;
    justify-content: center;
    align-items: center;
`;

const Content = styled.div<LoaderProps>`
    pointer-events: ${(p: LoaderProps) => (p.show ? 'none' : 'all')};
`;

const StyledFaSyncAlt = styled(FaSyncAlt)`
    color: #fff;
    font-size: 3rem;
`;

export default function Loading(props: IProps) {
    const { children, apiKey } = props;
    const que = useQue();

    const showLoading = apiKey ? que.has(apiKey) : que.size > 0;

    return (
        <Wrap>
            <Loader show={showLoading}>
                <StyledFaSyncAlt className={'fa-spin'} />
            </Loader>
            <Content show={showLoading}>{children}</Content>
        </Wrap>
    );
}
