import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { IPotionHolder, IPotionItem } from '../../interfaces/gameInterfaces';

import ActionButton from '../../components/elements/ActionButton/ActionButton';
import ActionMenu from '../../components/elements/ActionMenu/ActionMenu';
import Button from '../../components/elements/Button/Button';
import ItemTable from '../../components/elements/ItemTable/ItemTable';
import ItemList from '../../components/elements/ItemList/ItemList';
import Card from '../../ui/Card/Card';

import apiProvider from '../../api';
import { CharContext } from '../../context/CharContext';
import { StatsContext } from '../../context/StatsContext';
import Loading from '../../ui/Loading/Loading';

interface IProps {}

const Pharmacist = (props: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [refreshState, setRefreshState] = React.useState<number>(1);
    const [pharmacist, setPharmacist] = React.useState<IPotionItem[]>([]);
    const [selectedItem, setSelectedItem] = React.useState<IPotionItem | null>(null);
    const [isMerchantItem, setIsMerchantItem] = React.useState<boolean>(false);
    const [potionHolder, setPotionHolder] = React.useState<IPotionHolder>([]);
    const [isSoldOut, setSoldOut] = React.useState<boolean>(false);
    const [myAmount, setMyAmount] = React.useState<number | null>(null);

    // Context
    const [char, setChar] = React.useContext(CharContext);
    const [, setStats] = React.useContext(StatsContext);

    // Logout function
    const logout = () => {
        setChar(null);
        setStats(null);
        navigate('/');
    };

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    useEffect(() => {
        async function fetchData() {
            setSelectedItem(null);
            setSoldOut(false);
            setMyAmount(null);
            setPotionHolder([]);
            setPharmacist([]);

            const [responseGlassMarbles, responsePharmacist, responsePotionHolder, responseStats] = await Promise.all([
                apiProvider.backpackGetAmountOfGlassMarbles(char.jwt, logout),
                apiProvider.pharmacistDownloadPharmacistMerchandise(char.jwt, logout),
                apiProvider.potionHolderDownloadPotionHolder(char.jwt, logout),
                apiProvider.statsGetKaracterStats(char.jwt, logout),
            ]);

            if (!responseGlassMarbles || responseGlassMarbles.error) return;
            if (!responsePharmacist || responsePharmacist.error) return;
            if (!responsePotionHolder || responsePotionHolder.error) return;
            if (!responseStats || responseStats.error) return;

            setStats(responseStats);
            setMyAmount(responseGlassMarbles.glassMarbles);
            setPharmacist(responsePharmacist);

            if (responsePharmacist.length === 0) {
                setSoldOut(true);
            }

            setPotionHolder(responsePotionHolder);
        }

        fetchData();
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshState]);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------

    const clickBack = () => {
        navigate('/town');
    };

    const clickMerchantItem = (data: IPotionItem) => {
        setIsMerchantItem(true);
        setSelectedItem(data);
    };

    const clickPotionHolderItem = (data: IPotionItem) => {
        setIsMerchantItem(false);
        setSelectedItem(data);
    };

    const clickBuy = async () => {
        if (selectedItem && selectedItem.id !== 8000) {
            const response = await apiProvider.pharmacistBuyPotion(char.jwt, selectedItem.id, logout);

            if (!response || response.error) return;

            setSelectedItem(null);
            setRefreshState(refreshState + 1);
        }
    };

    const clickSell = async () => {
        if (selectedItem && selectedItem.id !== 8000) {
            const response = await apiProvider.pharmacistSellPotion(char.jwt, selectedItem.id, logout);

            if (!response || response.error) return;

            setSelectedItem(null);
            setRefreshState(refreshState + 1);
        }
    };

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return (
        <>
            <ActionMenu>
                <Loading>
                    <ActionButton type="button" label={t('button.back')} color="danger" action={clickBack} />
                </Loading>
            </ActionMenu>
            <div className="row">
                <div className="col-12 col-sm-4">
                    <Card title="button.buy">
                        {isSoldOut ? <p>{t('common.sold-out')}</p> : <ItemList items={pharmacist} onClick={clickMerchantItem} onDoubleClick={clickBuy} />}
                    </Card>
                </div>
                <div className="col-12 col-sm-4">
                    <Card title="common.item-details">
                        {selectedItem && selectedItem.id !== 8000 && (
                            <div className="col-12">
                                <ItemTable item={selectedItem} />
                                <p className="text-center">
                                    {t('common.i-have')}&nbsp;
                                    <span className="lead fw-bold">{myAmount}</span>&nbsp;{t('common.money')}.
                                </p>
                                <p className="text-center">
                                    {t('common.amount-after')} {isMerchantItem ? 'purchase' : 'sell'}:&nbsp;
                                    {myAmount && <span className="lead fw-bold">{isMerchantItem ? myAmount - selectedItem.buy : myAmount + selectedItem.sell}</span>}.
                                </p>
                                {isMerchantItem ? (
                                    <Button type="button" label={t('button.buy')} color="success" action={() => clickBuy()} />
                                ) : (
                                    <Button type="button" label={t('button.sell')} color="danger" action={() => clickSell()} />
                                )}
                            </div>
                        )}
                    </Card>
                </div>
                <div className="col-12 col-sm-4">
                    <Card title="button.sell">
                        <ItemList items={potionHolder} onClick={clickPotionHolderItem} onDoubleClick={clickSell} color="danger" />
                    </Card>
                </div>
            </div>
        </>
    );
};

export default Pharmacist;
