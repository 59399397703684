import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import styled from '@emotion/styled';

import './Map.css';

import { IMap } from '../../interfaces/gameInterfaces';

import apiProvider from '../../api';
import { CharContext } from '../../context/CharContext';
import { Center } from '../../ui/constants';

import Button from '../../components/elements/Button/Button';
import Card from '../../ui/Card/Card';
import ActionMenu from '../../components/elements/ActionMenu/ActionMenu';
import ActionButton from '../../components/elements/ActionButton/ActionButton';
import FieldImage from '../../ui/FieldImage/FieldImage';
import Loading from '../../ui/Loading/Loading';

interface IProps {}

interface IFieldActions {
    name: string;
    action: string;
    used: string;
    currentTime: string;
}

const Row = styled.div`
    display: flex;
`;

type ColType = {
    light?: boolean;
};

const Col = styled.div<ColType>`
    max-width: 128px;
    width: 20%;
    position: relative;
    border: 1px solid #0003;
    opacity: ${(p: ColType) => (p.light ? '0.4' : '1')};
`;

const Coords = styled.span`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.7rem;
    font-weight: 600;
`;

const MapWrap = styled.div`
    max-width: 500px;
    width: 100%;
    border-radius: 40px;
    overflow: hidden;
`;

const Map = (props: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [refreshState, setRefreshState] = React.useState<number>(1);
    const [map, setMap] = React.useState<IMap[]>([]);
    const [actions, setActions] = React.useState<IFieldActions[]>([]);
    const [selectedField, setSelectedField] = React.useState<IMap | null>(null);

    // Context
    const [char, setChar] = React.useContext(CharContext);

    // Logout function
    const logout = () => {
        setChar(null);
        navigate('/');
    };

    useEffect(() => {
        async function fetchData() {
            const [responseLocation, responseMap, responseActions] = await Promise.all([
                apiProvider.currentLocation(char.jwt, logout),
                apiProvider.mapDownloadMap(char.jwt, logout),
                apiProvider.mapDownloadActions(char.jwt, logout),
            ]);

            if (!responseLocation || responseLocation.error) return;

            if (responseLocation !== 'map') {
                navigate(`/${responseLocation}`);
                return;
            }

            if (!responseMap || responseMap.error) return;
            if (!responseActions || responseActions.error) return;

            setMap(responseMap);
            setSelectedField(responseMap[12]);
            setActions(responseActions);
        }

        fetchData();
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshState]);

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    const WAIT_INTERVAL: number = 40;
    let timer: any;
    let keys: any = {};

    const escFunction = useCallback(
        async (event: any) => {
            clearTimeout(timer);
            keys[event.keyCode] = event.type === 'keydown';
            // eslint-disable-next-line react-hooks/exhaustive-deps
            timer = setTimeout(triggerChange, WAIT_INTERVAL);
        },
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [refreshState, map]
    );

    const triggerChange = async () => {
        if ((keys[87] && keys[65]) || (keys[38] && keys[37])) {
            // WA
            keys = [];
            await clickTravel(map[6]);
        } else if ((keys[87] && keys[68]) || (keys[38] && keys[39])) {
            // WD
            keys = [];
            await clickTravel(map[8]);
        } else if ((keys[83] && keys[65]) || (keys[40] && keys[37])) {
            // SA
            keys = [];
            await clickTravel(map[16]);
        } else if ((keys[83] && keys[68]) || (keys[40] && keys[39])) {
            // SD
            keys = [];
            await clickTravel(map[18]);
        } else if (keys[87] || keys[38]) {
            // W / UP
            keys = [];
            await clickTravel(map[7]);
        } else if (keys[83] || keys[40]) {
            // S / DOWN
            keys = [];
            await clickTravel(map[17]);
        } else if (keys[65] || keys[37]) {
            // A / LEFT
            keys = [];
            await clickTravel(map[11]);
        } else if (keys[68] || keys[39]) {
            // D / RIGHT
            keys = [];
            await clickTravel(map[13]);
        }
    };

    useEffect(() => {
        document.addEventListener('keyup', escFunction, false);
        document.addEventListener('keydown', escFunction, false);

        return () => {
            document.removeEventListener('keyup', escFunction, false);
            document.removeEventListener('keydown', escFunction, false);
        };

        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshState, map]);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------

    const isCenterTile = (indexRow?: number, indexField?: number) => indexRow !== 0 && indexRow !== 4 && indexField !== 0 && indexField !== 4;

    const clickField = (data: IMap, indexRow: number, indexField: number) => {
        const centerTile = isCenterTile(indexRow, indexField);
        if (!centerTile) {
            return;
        }

        setSelectedField(data);
    };

    const clickTravel = async (data: IMap, indexRow?: number, indexField?: number) => {
        const isCenter = indexRow === 2 && indexField === 2;
        const centerTile = isCenterTile(indexRow, indexField);

        if (isCenter || !centerTile) {
            return;
        }

        if (data) {
            const response = await apiProvider.mapTravel(char.jwt, data.id, logout);

            if (!response || response.error) return;

            if (response.battle) {
                await apiProvider.gameSaveLocation(char.jwt, 'battlepve', logout);
                navigate('/battlepve');
            }
        }

        setRefreshState(refreshState + 1);
    };

    const clickAction = async (link: string) => {
        if (link === '/town') {
            const resultSave = await apiProvider.gameSaveLocation(char?.jwt, 'town', logout);
            if (resultSave.error) return;
        }

        navigate(link);
    };

    // --------------------------------------------
    // UI Render Util Dynamic Generation Methods
    // --------------------------------------------

    // const getBorderRadius = (indexRow: number, indexField: number) => {
    //     if (indexRow === 0 && indexField === 0) {
    //         return '50% 0 0 0';
    //     } else if (indexRow === 0 && indexField === 4) {
    //         return '0 50% 0 0';
    //     } else if (indexRow === 4 && indexField === 0) {
    //         return '0 0 0 50%';
    //     } else if (indexRow === 4 && indexField === 4) {
    //         return '0 0 50% 0';
    //     }
    // };

    const getOpacity = (indexRow: number, indexField: number) => {
        if (indexRow === 0 || indexRow === 4) {
            return '25%';
        } else if (indexField === 0 || indexField === 4) {
            return '25%';
        } else {
            return '100%';
        }
    };

    const getCursor = (indexRow: number, indexField: number) => {
        if (indexRow === 0 || indexRow === 4) {
            return 'initial';
        } else if (indexField === 0 || indexField === 4) {
            return 'initial';
        } else {
            return 'pointer';
        }
    };

    const listMap = (maps: IMap[]) => {
        const mapRows: Array<IMap[]> = [];
        let index = 0;

        while (index < maps.length) {
            mapRows.push(maps.slice(index, 5 + index));
            index += 5;
        }

        return (
            mapRows.length > 0 &&
            mapRows.map((rows: IMap[], indexRow: number) => (
                <Row key={indexRow}>
                    {rows.map((field: IMap, indexField: number) => (
                        <Col light={!isCenterTile(indexRow, indexField)} key={field.id}>
                            <span
                                onDoubleClick={() => clickTravel(field, indexRow, indexField)}
                                onClick={() => clickField(field, indexRow, indexField)}
                                style={{
                                    cursor: getCursor(indexRow, indexField),
                                    opacity: getOpacity(indexRow, indexField),
                                }}
                            >
                                <FieldImage image={field.image} />
                                <Coords>
                                    {field.x} : {field.y}
                                </Coords>
                            </span>
                        </Col>
                    ))}
                </Row>
            ))
        );
    };

    const listActions = (mapActions: IFieldActions[]) => {
        if (mapActions.length === 0) {
            return <ActionButton key={uuidv4()} type="button" label={t('map-action.no-actions')} color="secondary" disabled />;
        }

        return (
            mapActions.length > 0 &&
            mapActions.map((data: IFieldActions) => <ActionButton key={uuidv4()} type="button" label={t(data.name)} color="primary" action={() => clickAction(data.action)} />)
        );
    };

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return (
        <>
            <ActionMenu>
                <Loading apiKey="mapDownloadMap">{listActions(actions)}</Loading>
            </ActionMenu>
            <div className="row">
                <div className="col-12 col-sm-6">
                    <Card>
                        <div className="row">
                            <div className="col  d-flex justify-content-center">
                                <MapWrap>
                                    <Loading>{listMap(map)}</Loading>
                                </MapWrap>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="d-none d-sm-block col-12 col-sm-6">
                    <Card>
                        {selectedField && (
                            <table className="table table-sm table-borderless text-light">
                                <thead>
                                    <tr>
                                        <th className="text-center">
                                            <Center>
                                                <FieldImage image={selectedField.image} />
                                            </Center>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="text-center">{selectedField.fieldType}</td>
                                    </tr>
                                    <tr>
                                        <td className={`text-center ${selectedField.canAccess === 'api.accessible' ? 'text-success' : 'text-danger'}`}>
                                            {t(selectedField.canAccess)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={`text-center ${selectedField.canInteract === 'api.scavengeable' ? 'text-success' : 'text-danger'}`}>
                                            {t(selectedField.canInteract)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-center">
                                            {selectedField.x} : {selectedField.y}
                                        </td>
                                    </tr>
                                    {selectedField && map && selectedField !== map[12] && (
                                        <>
                                            <tr>
                                                <td className="text-center">
                                                    <Button type="button" color="primary" label={t('location.travel')} action={() => clickTravel(selectedField)} />
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                </tbody>
                            </table>
                        )}
                    </Card>
                </div>
            </div>
        </>
    );
};
export default Map;
