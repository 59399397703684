import React from 'react';
import { useTranslation } from 'react-i18next';

import './Credits.css';
import Card from '../../ui/Card/Card';

const sponsor = '\\resources\\sponsors\\placeholder-logo.svg';

const Credits = () => {
    const { t } = useTranslation();

    return (
        <Card fit scroll>
            <div className="row">
                <div className="col-12">
                    <h4 className="text-center m-3">{t('credits.sponsor')}</h4>
                    <div className="row">
                        <div className="col text-center">
                            <img src={sponsor} alt="Sponsor" className="max-sponsor-size" />
                        </div>
                    </div>
                    <p className="text-center">
                        <a href="/">Any custom text or link up to 64 characters</a>
                    </p>
                </div>
                <div className="col-12">
                    <h4 className="text-center m-3">{t('credits.credits')}</h4>
                    <div className="row">
                        <div className="col text-end">{t('credits.creator')}</div>
                        <div className="col">
                            <a href="mailto:marko@zanoski.com">Marko Zanoški</a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-end">{t('credits.battle-engineer')}</div>
                        <div className="col">
                            <a href="mailto:marko@zanoski.com">Maarten Craeynest</a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-end">{t('credits.email-generator')}</div>
                        <div className="col">
                            <a href="mailto:megamaaaan@gmail.com">Brendan Mullins</a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-end">{t('credits.frontend')}</div>
                        <div className="col">
                            <a href="mailto:megamaaaan@gmail.com">Brendan Mullins</a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-end">{t('credits.art')}</div>
                        <div className="col">
                            <a href="https://www.instagram.com/abong95_" rel="noopener noreferrer" target="_blank">
                                Halim Abror
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-end">{t('credits.translations')}</div>
                        <div className="col">
                            <a href="mailto:raminata.93@gmail.com">Raminata Litvinova &hearts;</a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-end">{t('credits.quality-assurance')}</div>
                        <div className="col">
                            <a href="mailto:farisskaryanantio@gmail.com">Faris Karyanantio</a>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col text-end">{t('credits.icons')}</div>
                        <div className="col">
                            <a href="https://game-icons.net/" rel="noopener noreferrer" target="_blank">
                                game-icons
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col" />
                        <div className="col">
                            <a href="http://www.freepik.com/" title="Freepik">
                                Freepik
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <h4 className="text-center m-3">{t('credits.backers')}</h4>
            <div className="img-container">
                <div className="grid">
                    <div className="cell">
                        <img alt="" src="https://via.placeholder.com/80" className="responsive-image rounded" />
                    </div>
                    <div className="cell">
                        <img alt="" src="https://via.placeholder.com/80" className="responsive-image rounded" />
                    </div>
                    <div className="cell">
                        <img alt="" src="https://via.placeholder.com/80" className="responsive-image rounded" />
                    </div>
                    <div className="cell">
                        <img alt="" src="https://via.placeholder.com/80" className="responsive-image rounded" />
                    </div>
                    <div className="cell">
                        <img alt="" src="https://via.placeholder.com/80" className="responsive-image rounded" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <h5 className="text-center m-3">{t('credits.contributors')}</h5>
                    <div className="row">
                        <div className="col">
                            <a href="https://www.reddit.com/user/Fungunkle/" target="_blank" rel="noopener noreferrer">
                                Fungunkle
                            </a>
                        </div>
                        <div className="col">
                            <a href="https://www.instagram.com/masternik12/" target="_blank" rel="noopener noreferrer">
                                Nikola Biruš
                            </a>
                        </div>
                        <div className="col">&nbsp;</div>
                    </div>
                </div>
                <div className="col-12">
                    <h5 className="text-center m-3">{t('credits.alpha-testers')}</h5>
                    <div className="row">
                        <div className="col">Nikola Biruš</div>
                        <div className="col">Dino Višnjić</div>
                        <div className="col">&nbsp;</div>
                    </div>
                    <div className="row">
                        <div className="col">Raminata Litvinova</div>
                        <div className="col">Brendan Mullins</div>
                        <div className="col">Maarten Craeynest</div>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default Credits;
