import React, { ComponentProps, useEffect } from 'react';
import styled from '@emotion/styled';

import './Showcase.css';

import apiProvider from '../../api';

import ItemList from '../../components/elements/ItemList/ItemList';

interface IProps extends ComponentProps<any> {}

const Wrapper = styled.div`
    margin: 0 auto;
    display: flex;
    height: 100vh;
    flex-direction: column;
    overflow: hidden;
`;

const MainContent = styled.main`
    position: relative;
    flex: 1;
    height: 100vh;
`;

const Showcase = (props: IProps) => {
    const [equipment, setEquipment] = React.useState<any[]>([]);

    useEffect(() => {
        async function fetchData() {
            const response = await apiProvider.showcaseEquipment();
            setEquipment(response);
        }

        fetchData();
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Wrapper className="wrapper">
            <MainContent>
                <ItemList onClick={() => {}} onDoubleClick={() => {}} items={equipment} />
            </MainContent>
        </Wrapper>
    );
};

export default Showcase;
