import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { IEquipmentItem } from '../../interfaces/gameInterfaces';

import ActionMenu from '../../components/elements/ActionMenu/ActionMenu';
import ActionButton from '../../components/elements/ActionButton/ActionButton';
import Button from '../../components/elements/Button/Button';
import ItemTable from '../../components/elements/ItemTable/ItemTable';
import Card from '../../ui/Card/Card';
import ItemList from '../../components/elements/ItemList/ItemList';

import apiProvider from '../../api';
import { CharContext } from '../../context/CharContext';
import { StatsContext } from '../../context/StatsContext';
import Loading from '../../ui/Loading/Loading';

interface IProps {}

const Armorsmith = (props: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [refreshState, setRefreshState] = React.useState<number>(1);
    const [armorsmith, setArmorsmith] = React.useState<IEquipmentItem[]>([]);
    const [merchantItem, setMerchantItem] = React.useState<IEquipmentItem | null>(null);
    const [isSoldOut, setSoldOut] = React.useState<boolean>(false);
    const [myAmount, setMyAmount] = React.useState<number | null>(null);

    // Context
    const [char, setChar] = React.useContext(CharContext);
    const [, setStats] = React.useContext(StatsContext);

    // Logout function
    const logout = () => {
        setChar(null);
        setStats(null);
        navigate('/');
    };

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    useEffect(() => {
        async function fetchData() {
            setMerchantItem(null);
            setSoldOut(false);
            setArmorsmith([]);
            setMyAmount(null);

            const [responseGlassMarbles, responseArmorsmith, responseStats] = await Promise.all([
                apiProvider.backpackGetAmountOfGlassMarbles(char.jwt, logout),
                apiProvider.armorsmithDownloadArmorsmithMerchandise(char.jwt, logout),
                apiProvider.statsGetKaracterStats(char.jwt, logout),
            ]);

            if (!responseGlassMarbles || responseGlassMarbles.error) return;
            if (!responseArmorsmith || responseArmorsmith.error) return;
            if (!responseStats || responseStats.error) return;

            setStats(responseStats);
            setMyAmount(responseGlassMarbles.glassMarbles);
            setArmorsmith(responseArmorsmith);

            if (responseArmorsmith.length === 0) {
                setSoldOut(true);
            }
        }

        fetchData();
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshState]);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------

    const clickBack = () => {
        navigate('/town');
    };

    const clickMerchantItem = (data: IEquipmentItem) => {
        setMerchantItem(data);
    };

    const clickBuy = async () => {
        if (merchantItem) {
            const response = await apiProvider.merchantPurchaseAction(char.jwt, merchantItem.id, logout);

            if (!response || response.error) return;

            setMerchantItem(null);
        }

        setRefreshState(refreshState + 1);
    };

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return (
        <>
            <ActionMenu>
                <Loading>
                    <ActionButton type="button" label={t('button.back')} color="danger" action={clickBack} />
                </Loading>
            </ActionMenu>
            <div className="row">
                <div className="col-12 col-sm-6">
                    <Card title="button.buy">
                        <div className="row">
                            {isSoldOut ? (
                                <div className="col-12">
                                    <p>{t('common.sold-out')}</p>
                                </div>
                            ) : (
                                <div className="col-12">
                                    <ItemList items={armorsmith} onClick={clickMerchantItem} onDoubleClick={clickBuy} />
                                </div>
                            )}
                        </div>
                    </Card>
                </div>
                <div className="col-12 col-sm-6">
                    <Card title="common.item-details">
                        <div className="row">
                            {merchantItem && (
                                <div className="col">
                                    <ItemTable item={merchantItem} />
                                    <p className="text-center">
                                        {t('common.i-have')}&nbsp;
                                        <span className="lead fw-bold">{myAmount}</span>&nbsp;{t('common.money')}. {t('common.after-purchase')}:&nbsp;
                                        {myAmount && <span className="lead fw-bold">{myAmount - merchantItem.buy}</span>}.
                                    </p>
                                    <Button type="button" label={t('button.buy')} color="success" action={clickBuy} />
                                </div>
                            )}
                        </div>
                    </Card>
                </div>
            </div>
        </>
    );
};

export default Armorsmith;
