import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import apiProvider from '../../api';
import { CharContext } from '../../context/CharContext';
import { UserContext } from '../../context/UserContext';
import { StatsContext } from '../../context/StatsContext';
import Character, { IAliveCharacter, IFallenCharacter } from '../../ui/Character/Character';
import Button from '../../ui/Button/Button';
import Credits from '../../components/Credits/Credits';
import TabCards from '../../ui/TabCards/TabCards';
import styled from '@emotion/styled';
import Loading from '../../ui/Loading/Loading';
import { Center } from '../../ui/constants';

interface IProps {}

const PageGrid = styled.div`
    display: flex;
    width: 100%;
    height: 100%;

    div[data-left] {
        grid-area: left;
        width: 540px;
        height: 100%;
        max-height: 100%;

        > div {
            height: 100%;
        }
    }
    div[data-right] {
        grid-area: right;
        height: 100%;
        max-height: 100%;
        flex: 1;

        > div {
            height: 100%;
        }
    }
`;

const Home = (props: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [aliveCharacters, setAliveCharacters] = React.useState<IAliveCharacter[]>([]);
    const [fallenCharacters, setFallenCharacters] = React.useState<IFallenCharacter[]>([]);

    // Context
    const [user, setUser] = React.useContext(UserContext);
    const [char, setChar] = React.useContext(CharContext);
    const [, setStats] = React.useContext(StatsContext);

    // Logout function
    const logout = () => {
        setUser(null);
        setChar(null);
        setStats(null);
        navigate('/');
    };

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    useEffect(() => {
        async function fetchData() {
            if (user === null || user.jwt === null || user.jwt.length < 10) {
                navigate('/login');
                return;
            }

            const response = await apiProvider.homeGetLiveCharacters(user.jwt, logout);

            if (!response || response.error) return;

            setAliveCharacters(response || []);

            if (char) {
                const selected = response.find((l: any) => l.name === char.name);
                if (selected) {
                    await setCharacterStats(char.jwt);
                }
            }
        }

        fetchData();
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        async function fetchData() {
            if (user === null || user.jwt === null || user.jwt.length < 10) {
                navigate('/login');
                return;
            }

            const response = await apiProvider.homeGetDeadCharacters(user.jwt, logout);

            if (!response || response.error) return;

            setFallenCharacters(response || []);
        }

        fetchData();
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------
    const clickCreateNewCharacter = () => {
        navigate('/character-creation');
    };

    const clickPlay = async (data: IAliveCharacter) => {
        const response = await apiProvider.homePlay(user.jwt, data.id, logout);

        if (!response || response.error) return;

        setChar(response);

        await setCharacterStats(response.jwt);
    };

    const setCharacterStats = async (charToken: string) => {
        const response = await apiProvider.statsGetKaracterStats(charToken, logout);

        if (!response || response.error) return;

        setStats(response);

        // TODO Move armorsmith / weaponsmith / of current town to save data only
        navigate(`/${response.location}`);
    };

    // --------------------------------------------
    // UI Render Util Dynamic Generation Methods
    // --------------------------------------------

    const renderCharacterLimit = (characters: IAliveCharacter[]) => {
        if (characters.length >= 5) {
            return (
                <div className="callout callout-danger">
                    <h3>{t('home.too-many')}</h3>
                    <p>{t('home.too-many-reason')}</p>
                </div>
            );
        }

        return <></>;
    };

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    const renderAliveCharacters = (characters: IAliveCharacter[]) => {
        const showCreateButton = aliveCharacters.length < 5;
        return (
            <Loading>
                {characters.length > 0 && characters.map((char) => <Character key={uuidv4()} onClick={() => clickPlay(char)} char={char} />)}
                {showCreateButton && (
                    <Center>
                        <Button onClick={clickCreateNewCharacter}>{t('button.new-character')}</Button>
                    </Center>
                )}
            </Loading>
        );
    };

    const renderFallenCharacters = (characters: IFallenCharacter[]) => {
        if (characters.length > 0) {
            return <Loading>{characters.length > 0 && characters.map((char) => <Character key={uuidv4()} char={char} />)}</Loading>;
        }

        return <h3 className="text-center">{t('home.no-fallen')}</h3>;
    };

    return (
        <PageGrid>
            {aliveCharacters && renderCharacterLimit(aliveCharacters)}
            <TabCards
                fit
                data-left
                titles={['home.alive-characters', 'home.fallen-characters']}
                content={[() => renderAliveCharacters(aliveCharacters), () => renderFallenCharacters(fallenCharacters)]}
            />
            <div style={{ marginLeft: '1rem' }} data-right>
                <Credits />
            </div>
        </PageGrid>
    );
};

export default Home;
