import { IBankSavingDeal, ICurrentSaving, IEquipment, IExperience, IHighLowCard, IItem, IPotionItem } from './gameInterfaces';

export const defaultItem: IItem = {
    id: 1,
    active: 0,
    name: 'Empty Space',
    description: 'Empty Space',
    image: 'None',
    itemRarity: 'Poor',
    itemType: 'Natural',
    location: 'Empty Space',
    buy: 0,
    sell: 0,
};

export const defaultExperience: IExperience = { type: 'Loading', experience: 0, level: 0 };

export const defaultPotionItem: IPotionItem = {
    id: 8000,
    active: 0,
    itemType: 'Natural',
    itemRarity: 'Poor',
    image: 'none',
    name: 'No Potion',
    description: 'No Potion',
    location: 'Myself',
    buy: 0,
    sell: 0,
    level: 1,
    health: 0,
    damage: 0,
    spell: 0,
    defense: 0,
    agility: 0,
};

export const defaultHighLowCard: IHighLowCard = {
    name: 'Mystery Card',
    image: '\\resources\\card\\card_random.svg',
};

export const defaultBankSavingDeal: IBankSavingDeal = {
    id: 0,
    turnDuration: 0,
    minInterest: 0,
    maxInterest: 0,
};

export const defaultCurrentSaving: ICurrentSaving = {
    id: 0,
    openedAt: 0,
    currentTurn: 0,
    createdAt: new Date(),
    turnDuration: 0,
    interest: 0,
    equity: 0,
};

export const defaultEquipment: IEquipment = {
    helmet: {
        id: 9001,
        active: 1,
        itemType: 'Natural',
        itemRarity: 'Poor',
        image: 'None_ClosedBarbute',
        name: 'No Helmet',
        description: 'No Helmet',
        location: 'Myself',
        buy: 0,
        sell: 0,
        equipmentType: 'Helmet',
        level: 1,
        health: 0,
        damage: 0,
        spell: 0,
        defense: 0,
        agility: 0,
        forClass: null,
        forRace: null,
    },
    armor: {
        id: 9002,
        active: 1,
        itemType: 'Natural',
        itemRarity: 'Poor',
        image: 'None_ChestArmor',
        name: 'No Armor',
        description: 'No Armor',
        location: 'Myself',
        buy: 0,
        sell: 0,
        equipmentType: 'Armor',
        level: 1,
        health: 0,
        damage: 0,
        spell: 0,
        defense: 0,
        agility: 0,
        forClass: null,
        forRace: null,
    },
    belt: {
        id: 9003,
        active: 1,
        itemType: 'Natural',
        itemRarity: 'Poor',
        image: 'None_Belt',
        name: 'No Belt',
        description: 'No Belt',
        location: 'Myself',
        buy: 0,
        sell: 0,
        equipmentType: 'Belt',
        level: 1,
        health: 0,
        damage: 0,
        spell: 0,
        defense: 0,
        agility: 0,
        forClass: null,
        forRace: null,
    },
    pants: {
        id: 9004,
        active: 1,
        itemType: 'Natural',
        itemRarity: 'Poor',
        image: 'None_Trousers',
        name: 'No Pants',
        description: 'No Pants',
        location: 'Myself',
        buy: 0,
        sell: 0,
        equipmentType: 'Pants',
        level: 1,
        health: 0,
        damage: 0,
        spell: 0,
        defense: 0,
        agility: 0,
        forClass: null,
        forRace: null,
    },
    feet: {
        id: 9005,
        active: 1,
        itemType: 'Natural',
        itemRarity: 'Poor',
        image: 'None_Boots',
        name: 'No Feetwear',
        description: 'No Feetwear',
        location: 'Myself',
        buy: 0,
        sell: 0,
        equipmentType: 'Feet',
        level: 1,
        health: 0,
        damage: 0,
        spell: 0,
        defense: 0,
        agility: 0,
        forClass: null,
        forRace: null,
    },
    mainHand: {
        id: 9006,
        active: 1,
        itemType: 'Natural',
        itemRarity: 'Poor',
        image: 'None_Hand',
        name: 'No Mainhand',
        description: 'No Mainhand',
        location: 'Myself',
        buy: 0,
        sell: 0,
        equipmentType: 'MainHand',
        level: 1,
        health: 0,
        damage: 0,
        spell: 0,
        defense: 0,
        agility: 0,
        forClass: null,
        forRace: null,
    },
    offHand: {
        id: 9007,
        active: 1,
        itemType: 'Natural',
        itemRarity: 'Poor',
        image: 'None_Hand',
        name: 'No Offhand',
        description: 'No Offhand',
        location: 'Myself',
        buy: 0,
        sell: 0,
        equipmentType: 'OffHand',
        level: 1,
        health: 0,
        damage: 0,
        spell: 0,
        defense: 0,
        agility: 0,
        forClass: null,
        forRace: null,
    },
};
