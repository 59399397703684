import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import apiProvider from '../../api';
import { UserContext } from '../../context/UserContext';
import { Center, colors } from '../../ui/constants';
import { USERNAME_REGEX } from '../../utils/utils';

import Card from '../../ui/Card/Card';
import Input from '../../components/elements/Input/Input';
import Button from '../../ui/Button/Button';
import Icon from '../../ui/Icon/Icon';
import Loading from '../../ui/Loading/Loading';

interface INewCharacterStats {
    health: number;
    damage: number;
    spell: number;
    defense: number;
    agility: number;
    level: number;
    name: string;
    traits: string;
    description: string;
    id: number;
    image: string;
}

const defaultProps: INewCharacterStats = {
    id: -1,
    health: 0,
    damage: 0,
    spell: 0,
    defense: 0,
    agility: 0,
    level: 0,
    name: 'Loading...',
    traits: 'Loading...',
    description: 'Loading...',
    image: 'empty',
};

interface IProps {}

const CharacterCreation = (props: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [characterName, setCharacterName] = React.useState<string>('');
    const [totalHealth, setTotalHealth] = React.useState<number>(0);
    const [totalDamage, setTotalDamage] = React.useState<number>(0);
    const [totalSpell, setTotalSpell] = React.useState<number>(0);
    const [totalDefense, setTotalDefense] = React.useState<number>(0);
    const [totalAgility, setTotalAgility] = React.useState<number>(0);
    const [level, setLevel] = React.useState<number>(1);
    const [glassMarbles, setGlassMarbles] = React.useState<number>(0);
    const [races, setRaces] = React.useState<any[]>([]);
    const [classes, setClasses] = React.useState<any[]>([]);

    const [selectedRace, setSelectedRace] = React.useState<INewCharacterStats>(defaultProps);
    const [selectedClass, setSelectedClass] = React.useState<INewCharacterStats>(defaultProps);

    // Context
    const [user, setUser] = React.useContext(UserContext);

    // Logout function
    const logout = () => {
        setUser(null);
        navigate('/');
    };

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    useEffect(() => {
        async function fetchData() {
            const [responseRaces, responseClases] = await Promise.all([
                apiProvider.characterCreationGetRaces(user.jwt, logout),
                apiProvider.characterCreationGetClasses(user.jwt, logout),
            ]);

            if (!responseRaces || responseRaces.error) return;
            if (!responseClases || responseClases.error) return;

            setRaces(responseRaces || []);
            setSelectedRace(responseRaces[0]);

            setClasses(responseClases || []);
            setSelectedClass(responseClases[0]);
        }

        fetchData();
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        calculateAttributes(selectedRace, selectedClass);
    }, [selectedRace, selectedClass]);

    const calculateAttributes = (wantedRace: INewCharacterStats, wantedClass: INewCharacterStats) => {
        if (wantedRace != null && wantedClass != null) {
            setTotalHealth(wantedRace.health + wantedClass.health);
            setTotalDamage(wantedRace.damage + wantedClass.damage);
            setTotalSpell(wantedRace.spell + wantedClass.spell);
            setTotalDefense(wantedRace.defense + wantedClass.defense);
            setTotalAgility(wantedRace.agility + wantedClass.agility);
            setLevel(wantedRace.level + wantedClass.level);
            // TODO From Api
            setGlassMarbles(1000);
        }
    };

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------

    const clickBack = () => {
        navigate('/');
    };

    const clickCreate = async () => {
        const response = await apiProvider.characterCreationCreateNewCharacter(user.jwt, selectedRace.id, selectedClass.id, characterName, logout);

        if (!response || response.error) return;

        navigate('/');
    };

    // --------------------------------------------
    // UI Render Util Dynamic Generation Methods
    // --------------------------------------------

    const listClasses = (list: INewCharacterStats[]) => {
        return (
            list.length > 0 &&
            list.map(function (data: INewCharacterStats) {
                return (
                    <div key={uuidv4()} className="col-6 col-sm-4 p-1">
                        <div className="d-grid gap-2">
                            <button
                                onClick={() => setSelectedClass(data)}
                                type="button"
                                style={{
                                    color: data.id === selectedClass.id ? colors.text : colors.black,
                                    background: data.id === selectedClass.id ? colors.primary : colors.secondary,
                                    borderColor: data.id === selectedClass.id ? colors.primary : colors.secondary,
                                    boxShadow: data.id === selectedClass.id ? '0 0.5em 0.5em -0.4em var(--hover)' : '',
                                    transform: data.id === selectedClass.id ? 'translateY(+0.25em)' : '',
                                }}
                                className={`btn btn-warning ${data.id === selectedClass.id ? 'active' : ''}`}
                            >
                                <span className="fw-bold">{data.name}</span>
                            </button>
                        </div>
                    </div>
                );
            })
        );
    };

    const listRaces = (list: INewCharacterStats[]) => {
        return (
            list.length > 0 &&
            list.map(function (data: INewCharacterStats) {
                return (
                    <div key={uuidv4()} className="col-6 col-sm-4  p-1">
                        <div className="d-grid gap-2">
                            <button
                                onClick={() => setSelectedRace(data)}
                                style={{
                                    color: data.id === selectedRace.id ? colors.text : colors.black,
                                    background: data.id === selectedRace.id ? colors.primary : colors.secondary,
                                    borderColor: data.id === selectedRace.id ? colors.primary : colors.secondary,
                                    boxShadow: data.id === selectedRace.id ? '0 0.5em 0.5em -0.4em var(--hover)' : '',
                                    transform: data.id === selectedRace.id ? 'translateY(+0.25em)' : '',
                                }}
                                type="button"
                                className="btn btn-warning"
                            >
                                <span className="fw-bold">{data.name}</span>
                            </button>
                        </div>
                    </div>
                );
            })
        );
    };

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return (
        <>
            <Card>
                <div className="row">
                    <div className="col-12 col-sm-6 offset-sm-3">
                        <Input
                            id={'characterName'}
                            type={'text'}
                            name={'characterName'}
                            value={characterName}
                            placeholder={'Legend_27'}
                            onChange={(e: any) => setCharacterName(e.target.value)}
                            isInvalid={!USERNAME_REGEX.test(characterName)}
                            isInvalidHelp={'Enter a valid username. At least 4 characters.'}
                            required={true}
                        />
                    </div>
                </div>
            </Card>
            <Card>
                <div className="row">
                    <div className="col-12 col-sm-4">
                        <h4 className="text-center m-3">{t('character-creation.select-race')}</h4>
                        <div className="mb-3 row">
                            <div className="d-grid gap-2">
                                <Icon name={selectedRace.image} />
                            </div>
                        </div>
                        <div className="mb-3 row">{listRaces(races)}</div>
                        <div className="mb-3 row">
                            <label>
                                {t('character-creation.traits')}: <span className="fw-bold">{selectedRace.traits}</span>
                            </label>
                        </div>
                        <div className="mb-3 row">
                            <label>
                                {t('common.description')}: <span className="fw-bold">{selectedRace.description}</span>
                            </label>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4">
                        <h4 className="text-center m-3">{t('character-creation.stats')}</h4>
                        <div className="table-responsive">
                            <table className="table table-borderless text-light">
                                <tbody>
                                    <tr>
                                        <td>{t('common.level')}</td>
                                        <td>
                                            <span className="fw-bold">{level}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('common.money')}</td>
                                        <td>
                                            <span className="fw-bold">{glassMarbles}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('common.health')}</td>
                                        <td>
                                            <span className="fw-bold">{totalHealth}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('common.damage')}</td>
                                        <td>
                                            <span className="fw-bold">{totalDamage}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('common.spell')}</td>
                                        <td>
                                            <span className="fw-bold">{totalSpell}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('common.defense')}</td>
                                        <td>
                                            <span className="fw-bold">{totalDefense}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('common.agility')}</td>
                                        <td>
                                            <span className="fw-bold">{totalAgility}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4">
                        <h4 className="text-center m-3">{t('character-creation.select-class')}</h4>
                        <div className="mb-3 row">
                            <div className="d-grid gap-2">
                                <Icon name={selectedClass.image} />
                            </div>
                        </div>
                        <div className="mb-3 row">{listClasses(classes)}</div>
                        <div className="mb-3 row">
                            <label>
                                {t('character-creation.traits')}:<span className="fw-bold">{selectedClass.traits}</span>
                            </label>
                        </div>
                        <div className="mb-3 row">
                            <label>
                                {t('common.description')}:<span className="fw-bold">{selectedClass.description}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </Card>

            <br />
            <Loading>
                <div className="row">
                    <div className="col">
                        <Center>
                            <Button style={{ width: '40%', backgroundColor: `${colors.tertiary}` }} onClick={clickBack}>
                                {t('button.back')}
                            </Button>
                        </Center>
                    </div>
                    <div className="col">
                        <Center>
                            <Button style={{ width: '40%' }} onClick={clickCreate} disabled={!USERNAME_REGEX.test(characterName)}>
                                {t('button.new-character')}
                            </Button>
                        </Center>
                    </div>
                </div>
            </Loading>
        </>
    );
};

export default CharacterCreation;
