import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import Card from '../../ui/Card/Card';
import Loading from '../../ui/Loading/Loading';
import TownImage from '../../ui/TownImage/TownImage';

import apiProvider from '../../api';
import { CharContext } from '../../context/CharContext';
import styled from '@emotion/styled';
import { spacing } from '../../ui/constants';

interface IProps {}

const TownWrap = styled.div`
    max-width: 500px;
    width: 100%;
    border-radius: 40px;
    overflow: hidden;
`;

const Row = styled.div`
    display: flex;
`;

type ColType = {
    light?: boolean;
};

const Col = styled.div<ColType>`
    max-width: 128px;
    width: 20%;
    position: relative;
    border: 1px solid #0003;
    opacity: ${(p: ColType) => (p.light ? '0.4' : '1')};
`;

const ItemStyled = styled.div`
    margin-right: ${spacing.p1};
    border-radius: 10px;
    overflow: hidden;
`;

const Town = (props: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [townName, setTownName] = React.useState<string>('Loading...');

    // Context
    const [char, setChar] = React.useContext(CharContext);

    // Logout function
    const logout = () => {
        setChar(null);
        navigate('/');
    };

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------
    useEffect(() => {
        async function fetchData() {
            await apiProvider.townIsCharacterInTown(char.jwt, logout);

            const responseTown = await apiProvider.townGetTownDetails(char.jwt, logout);

            if (!responseTown || responseTown.error) return;

            setTownName(responseTown.townName);
        }

        fetchData();
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------

    const townLocations = [
        'armorsmith',
        '',
        'craftsman',
        '',
        'weaponsmith',
        'merchant',
        '',
        '',
        '',
        'inn',
        'pharmacist',
        '',
        '',
        '',
        'bank',
        'skinner',
        '',
        '',
        '',
        'gamble',
        '',
        '',
        'map',
        '',
        '',
    ];

    const clickLocation = async (location: string) => {
        if (location === '') {
            return;
        }

        if (location === 'map') {
            await apiProvider.gameSaveLocation(char?.jwt, 'map', logout);
        }

        navigate('/' + location);
    };

    const renderTownLocations = (locations: string[]) => {
        const renderLocations: Array<string[]> = [];
        let index = 0;

        while (index < locations.length) {
            renderLocations.push(locations.slice(index, 5 + index));
            index += 5;
        }

        return (
            renderLocations.length > 0 &&
            renderLocations.map((rows: string[], indexRow: number) => (
                <Row key={indexRow}>
                    {rows.map((field: string, indexField: number) => (
                        <Col key={indexField}>
                            <span onClick={() => clickLocation(field)}>
                                <ItemStyled data-tip data-for={field}>
                                    <TownImage data-tip data-for={field} image={field} />
                                </ItemStyled>
                                <ReactTooltip id={field} aria-haspopup="true" className="soTooltip">
                                    {field !== '' ? t(`location.${field}`) : ''}
                                </ReactTooltip>
                            </span>
                        </Col>
                    ))}
                </Row>
            ))
        );
    };

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return (
        <Card>
            <h3 className="text-center">
                {t('town.welcome')}
                {townName}
            </h3>
            <p className="text-center">{t('town.advice')}</p>
            <div className="row">
                <div className="col d-flex justify-content-center">
                    <TownWrap>
                        <Loading>{renderTownLocations(townLocations)}</Loading>
                    </TownWrap>
                </div>
            </div>
        </Card>
    );
};

export default Town;
