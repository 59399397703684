import React, { ComponentProps } from 'react';
import styled from '@emotion/styled';

import { colors } from '../../../ui/constants';

const Icon = styled.i`
    font-size: 1.1rem;
    margin-left: 0.05rem;
    margin-right: 0.2rem;
    width: 1.6rem;
`;

const DropdownElement = styled.span`
    color: ${colors.black};
    cursor: pointer;

    &:hover,
    &:focus {
        color: ${colors.primary};
    }
`;

interface IProps extends ComponentProps<any> {
    icon: string;
    title: string;
    active: boolean;
    onClick: Function;
}

const SidebarDropdownElement = (props: IProps) => {
    const { icon, title, active, onClick } = props;

    return (
        <DropdownElement className={`dropdown-item ${active ? 'active' : ''}`} onClick={() => onClick()}>
            <Icon className={icon} />
            {title}
        </DropdownElement>
    );
};

export default SidebarDropdownElement;
