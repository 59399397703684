import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import styled from '@emotion/styled';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';

import packageJson from '../package.json';

import './App.css';

import Login from './pages/Login/Login';
import Signup from './pages/Signup/Signup';
import Main from './pages/Main/Main';
import VerifyAccount from './pages/VerifyAccount/VerifyAccount';
import ForgotMyPassword from './pages/ForgotMyPassword/ForgotMyPassword';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import Showcase from './pages/Showcase/Showcase';

import ContextCombiner from './context/ContextCombiner';

import { colors } from './ui/constants';
import { GoogleOAuthProvider } from '@react-oauth/google';

Sentry.init({
    dsn: 'https://49da0c2697f3417bbe2a2a8d3ba7b769@o435848.ingest.sentry.io/5395876',
    sampleRate: 0.25,
    release: 'csr-web@' + packageJson.version,
    environment: process.env.REACT_APP_ENV,
    debug: process.env.REACT_APP_ENV === 'development',
});

const Background = styled(Particles)`
    background: rgb(0, 122, 147);
    background: radial-gradient(farthest-corner at 0px 0px, rgb(28 78 169) 0%, rgb(11 7 72) 70%);
    color: ${colors.text};
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
`;

const App: React.FC = () => {
    document.addEventListener('contextmenu', function (event) {
        event.preventDefault();
    });

    const appId = '' + process.env.REACT_APP_GOOGLE_APP_ID;

    const particlesInit = async (main: any) => {
        await loadFull(main);
    };

    return (
        <GoogleOAuthProvider clientId={appId}>
            <ContextCombiner>
                <Router>
                    <Background
                        id="tsparticles"
                        init={particlesInit}
                        options={{
                            particles: {
                                number: {
                                    value: 80,
                                    density: {
                                        enable: true,
                                        area: 1500,
                                    },
                                },
                                line_linked: {
                                    enable: true,
                                    opacity: 0.02,
                                },
                                move: {
                                    direction: 'right',
                                    speed: 0.05,
                                },
                                size: {
                                    value: 1,
                                },
                                opacity: {
                                    anim: {
                                        enable: true,
                                        speed: 1,
                                        opacity_min: 0.05,
                                    },
                                },
                            },
                            detectRetina: false,
                            fpsLimit: 30,
                        }}
                    />
                    <ToastContainer autoClose={3000} position={toast.POSITION.TOP_RIGHT} />
                    <Routes>
                        <Route path="/showcase" element={<Showcase />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="/forgot-my-password" element={<ForgotMyPassword />} />
                        <Route path="/verify-account" element={<VerifyAccount />} />
                        <Route path="/signup" element={<Signup />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="*" element={<Main />} />
                    </Routes>
                </Router>
            </ContextCombiner>
        </GoogleOAuthProvider>
    );
};

export default App;
