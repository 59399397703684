import React from 'react';
import { IJwtToken } from '../interfaces/gameInterfaces';

export const UserContext = React.createContext<any>(null);

interface IProps {
    children: React.ReactNode;
}

const UserContextProvider: React.FC<IProps> = (props: IProps) => {
    const [user, setUser] = React.useState<IJwtToken | null>(null);

    return <UserContext.Provider value={[user, setUser]}>{props.children}</UserContext.Provider>;
};

export default UserContextProvider;
