import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { IItem } from '../../interfaces/gameInterfaces';

import ItemTable from '../../components/elements/ItemTable/ItemTable';
import ActionMenu from '../../components/elements/ActionMenu/ActionMenu';
import ActionButton from '../../components/elements/ActionButton/ActionButton';
import Loading from '../../ui/Loading/Loading';
import Card from '../../ui/Card/Card';

import apiProvider from '../../api';
import { CharContext } from '../../context/CharContext';

interface IProps {}

const PickFruit = (props: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [foundNothing, setFoundNothing] = React.useState<boolean>(false);
    const [foundItem, setFoundItem] = React.useState<IItem | null>(null);

    // Context
    const [char, setChar] = React.useContext(CharContext);

    // Logout function
    const logout = () => {
        setChar(null);
        navigate('/');
    };

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    useEffect(() => {
        async function fetchData() {
            setFoundNothing(false);
            setFoundItem(null);

            const [responseIsOnField, responsePickFruit] = await Promise.all([
                apiProvider.pickFruitIsKaracterOnPickFruit(char.jwt, logout),
                apiProvider.pickFruitPickFruit(char.jwt, logout),
            ]);

            if (!responseIsOnField || responseIsOnField.error) return;
            if (!responsePickFruit || responsePickFruit.error) return;

            if (responsePickFruit.foundNothing) {
                setFoundNothing(true);
            } else {
                setFoundItem(responsePickFruit);
            }
        }

        fetchData();
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------

    const clickBack = () => {
        navigate('/map');
    };

    const clickStore = async () => {
        if (foundItem) {
            const response = await apiProvider.searchFieldStoreItem(char.jwt, foundItem.id, logout);

            if (!response || response.error) return;

            // TODO Toast succ
            navigate('/map');
        }
    };

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return (
        <>
            <ActionMenu>
                <Loading>
                    <ActionButton type="button" label={t('button.back')} color="danger" action={clickBack} />
                    {foundItem && <ActionButton type="button" label={t('button.store')} color="success" action={() => clickStore()} />}
                </Loading>
            </ActionMenu>
            <Card>
                {foundNothing && <h3 className="text-center">{t('search-field.empty-fruit')}</h3>}
                {foundItem && (
                    <>
                        <h3 className="text-center">
                            {t('search-field.pick')}&nbsp;{foundItem.name},{t('search-field.should-store')}
                        </h3>
                        <br />
                        <div className="row">
                            <div className="col-2 d-none d-sm-block" />
                            <div className="col-12 col-sm-8">
                                <ItemTable item={foundItem} />
                            </div>
                            <div className="col-2 d-none d-sm-block" />
                        </div>
                    </>
                )}
            </Card>
        </>
    );
};

export default PickFruit;
