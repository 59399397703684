import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';

import './Signup.css';

import apiProvider from '../../api';
import { UserContext } from '../../context/UserContext';
import { CharContext } from '../../context/CharContext';
import { ArenaContext } from '../../context/ArenaContext';
import { BattleContext } from '../../context/BattleContext';
import { StatsContext } from '../../context/StatsContext';
import { EMAIL_REGEX } from '../../utils/utils';

import Input from '../../components/elements/Input/Input';
import Loading from '../../ui/Loading/Loading';

const logo = '\\resources\\img\\logo.svg';

interface IProps {}

const Wrapper = styled.div`
    margin: 0 auto;
    display: flex;
    height: 100vh;
    flex-direction: column;
    overflow: hidden;
`;

const MainContent = styled.main`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    justify-content: center;

    position: relative;
    flex: 1;
    height: 100vh;
`;

const Signup = (props: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [agree, setAgree] = React.useState<boolean>(false);
    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [repeatPassword, setRepeatPassword] = React.useState<string>('');

    // Context
    const [, setUser] = React.useContext(UserContext);
    const [, setChar] = React.useContext(CharContext);
    const [, setArena] = React.useContext(ArenaContext);
    const [, setBattle] = React.useContext(BattleContext);
    const [, setStats] = React.useContext(StatsContext);

    // Logout function
    const logout = () => {
        setUser(null);
        setChar(null);
        setArena(null);
        setBattle(null);
        setStats(null);
        navigate('/');
    };

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    useEffect(() => {
        setUser(null);
        setChar(null);
        setArena(null);
        setBattle(null);
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------

    const clickRegister = async () => {
        const response = await apiProvider.signupRegisterAccount(email, password, logout);

        if (!response || response.error) {
            toast.error(response.msg);
            return;
        }

        toast.success(response);
        navigate('/login');
    };

    const clickLogin = async () => {
        navigate('/login');
    };

    const clickPrivacyPolicy = async () => {
        window.open('/privacy-policy', '_blank');
    };

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return (
        <Wrapper className="wrapper">
            <MainContent>
                <div style={{ minWidth: '360px', width: '100%', maxWidth: '360px' }}>
                    <div
                        style={{
                            marginBottom: '.9rem',
                            textAlign: 'center',
                        }}
                    >
                        <img src={logo} alt="CSR Logo" style={{ maxHeight: '128px' }} />
                    </div>
                    <div className="card">
                        <Loading>
                            <div className="card-body">
                                <form>
                                    <p className="text-center">{t('common.signup')}</p>
                                    <Input
                                        id={'email'}
                                        type={'email'}
                                        name={'email'}
                                        icon={'fas fa-envelope'}
                                        value={email}
                                        placeholder={'user@example.com'}
                                        onChange={(e: any) => setEmail(e.target.value)}
                                        isInvalid={!EMAIL_REGEX.test(email)}
                                        isInvalidHelp={t('input.valid-email')}
                                        required={true}
                                        autocomplete={'email'}
                                    />
                                    <Input
                                        id={'password'}
                                        type={'password'}
                                        name={'password'}
                                        icon={'fas fa-lock'}
                                        value={password}
                                        placeholder={'••••••••'}
                                        onChange={(e: any) => setPassword(e.target.value)}
                                        isInvalid={password.length < 6}
                                        isInvalidHelp={t('input.valid-password')}
                                        required={true}
                                        autocomplete={'new-password'}
                                    />
                                    <Input
                                        id={'repeatPassword'}
                                        type={'password'}
                                        name={'repeatPassword'}
                                        icon={'fas fa-lock'}
                                        value={repeatPassword}
                                        placeholder={'••••••••'}
                                        onChange={(e: any) => setRepeatPassword(e.target.value)}
                                        isInvalid={password.length < 6}
                                        isInvalidHelp={t('input.valid-repeat-password')}
                                        required={true}
                                        autocomplete={'new-password'}
                                    />
                                    <div className="row">
                                        <div className="col-8">
                                            <div className="form-check cursor-pointer">
                                                <input
                                                    className="form-check-input cursor-pointer"
                                                    type="checkbox"
                                                    id="agreeTerms"
                                                    name="terms"
                                                    value="agree"
                                                    onChange={() => setAgree(!agree)}
                                                />
                                                <label className="form-check-label cursor-pointer" htmlFor="agreeTerms">
                                                    {t('input.accept')}&nbsp;
                                                    <span className="text-primary cursor-pointer" onClick={clickPrivacyPolicy}>
                                                        {t('input.terms')}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="d-grid gap-2">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={clickRegister}
                                                    disabled={!agree || !EMAIL_REGEX.test(email) || password.length < 6 || repeatPassword !== password}
                                                >
                                                    {t('button.register')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-0">
                                        <span className="text-primary cursor-pointer" onClick={clickLogin}>
                                            {t('common.already-registered')}
                                        </span>
                                    </p>
                                </form>
                            </div>
                        </Loading>
                    </div>
                </div>
            </MainContent>
        </Wrapper>
    );
};

export default Signup;
