import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';

import './ForgotMyPassword.css';

import apiProvider from '../../api';
import { UserContext } from '../../context/UserContext';
import { CharContext } from '../../context/CharContext';
import { ArenaContext } from '../../context/ArenaContext';
import { BattleContext } from '../../context/BattleContext';
import { StatsContext } from '../../context/StatsContext';
import { EMAIL_REGEX } from '../../utils/utils';

import Input from '../../components/elements/Input/Input';
import Loading from '../../ui/Loading/Loading';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-use';

const logo = '\\resources\\img\\logo.svg';

interface IProps {}

const Wrapper = styled.div`
    margin: 0 auto;
    display: flex;
    height: 100vh;
    flex-direction: column;
    overflow: hidden;
`;

const MainContent = styled.main`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    justify-content: center;

    position: relative;
    flex: 1;
    height: 100vh;
`;

const ForgotMyPassword = (props: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const [email, setEmail] = React.useState<string>('');
    const [token, setToken] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [repeatPassword, setRepeatPassword] = React.useState<string>('');
    const [showPasswordFields, setShowPasswordFields] = React.useState<boolean>(false);

    // Context
    const [, setUser] = React.useContext(UserContext);
    const [, setChar] = React.useContext(CharContext);
    const [, setArena] = React.useContext(ArenaContext);
    const [, setBattle] = React.useContext(BattleContext);
    const [, setStats] = React.useContext(StatsContext);

    // Logout function
    const logout = () => {
        setUser(null);
        setChar(null);
        setArena(null);
        setBattle(null);
        setStats(null);
        navigate('/');
    };

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    useEffect(() => {
        setUser(null);
        setChar(null);
        setArena(null);
        setBattle(null);

        const query = new URLSearchParams(location.search);
        const queryToken = query.get('token');
        if (queryToken != null) {
            setShowPasswordFields(true);
            setToken(queryToken);
        }

        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------

    const clickResetPassword = async () => {
        const response = await apiProvider.userRequestPasswordReset(email, logout);

        if (!response || response.error) {
            toast.error(response.msg);
            return;
        }

        toast.success(response);
    };

    const clickLogin = async () => {
        navigate('/login');
    };

    const clickRegister = async () => {
        navigate('/signup');
    };

    const clickSetNewPassword = async () => {
        const response = await apiProvider.userPasswordReset(token, password, logout);

        if (!response || response.error) {
            toast.error(response.msg);
            return;
        }

        toast.success(response);
        navigate('/login');
    };

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return (
        <Wrapper className="wrapper">
            <MainContent>
                <div style={{ minWidth: '360px', width: '100%', maxWidth: '360px' }}>
                    <div
                        style={{
                            marginBottom: '.9rem',
                            textAlign: 'center',
                        }}
                    >
                        <img src={logo} alt="CSR Logo" style={{ maxHeight: '128px' }} />
                    </div>
                    <div className="card">
                        <Loading>
                            {!showPasswordFields && (
                                <div className="card-body">
                                    <form>
                                        <p className="text-center">{t('forgot-my-password.title')}</p>
                                        <Input
                                            id={'email'}
                                            type={'email'}
                                            name={'email'}
                                            icon={'fas fa-envelope'}
                                            value={email}
                                            placeholder={'user@example.com'}
                                            onChange={(e: any) => setEmail(e.target.value)}
                                            isInvalid={!EMAIL_REGEX.test(email)}
                                            isInvalidHelp={t('input.valid-email')}
                                            required={true}
                                            autocomplete={'email'}
                                        />
                                        <div className="row">
                                            <div className="col mb-3">
                                                <div className="d-grid gap-2">
                                                    <button type="button" className="btn btn-primary" onClick={clickResetPassword} disabled={!EMAIL_REGEX.test(email)}>
                                                        {t('forgot-my-password.reset-password')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="social-auth-links text-center mb-3">
                                            <p>-- {t('common.or').toUpperCase()} --</p>
                                        </div>

                                        <p className="mb-1">
                                            <span className="text-primary cursor-pointer" onClick={clickLogin}>
                                                {t('common.already-registered')}
                                            </span>
                                        </p>
                                        <p className="mb-0">
                                            <span className="text-primary cursor-pointer" onClick={clickRegister}>
                                                {t('common.register')}
                                            </span>
                                        </p>
                                    </form>
                                </div>
                            )}
                            {showPasswordFields && (
                                <div className="card-body">
                                    <form>
                                        <p className="text-center">{t('forgot-my-password.enter-new-password')}</p>
                                        <Input
                                            id={'password'}
                                            type={'password'}
                                            name={'password'}
                                            icon={'fas fa-lock'}
                                            value={password}
                                            placeholder={'••••••••'}
                                            onChange={(e: any) => setPassword(e.target.value)}
                                            isInvalid={password.length < 6}
                                            isInvalidHelp={t('input.valid-password')}
                                            required={true}
                                            autocomplete={'new-password'}
                                        />
                                        <Input
                                            id={'password'}
                                            type={'password'}
                                            name={'password'}
                                            icon={'fas fa-lock'}
                                            value={repeatPassword}
                                            placeholder={'••••••••'}
                                            onChange={(e: any) => setRepeatPassword(e.target.value)}
                                            isInvalid={password.length < 6}
                                            isInvalidHelp={t('input.valid-repeat-password')}
                                            required={true}
                                            autocomplete={'new-password'}
                                        />
                                        <div className="row">
                                            <div className="col">
                                                <div className="d-grid gap-2">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={clickSetNewPassword}
                                                        disabled={password.length < 6 || repeatPassword !== password}
                                                    >
                                                        {t('button.set-new-password')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            )}
                        </Loading>
                    </div>
                </div>
            </MainContent>
        </Wrapper>
    );
};

export default ForgotMyPassword;
