import React, { ComponentProps } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';

interface IProps extends ComponentProps<any> {
    onClick?: any;
    onFailure?: any;
}

const GoogleButton = (props: IProps) => {
    const { t } = useTranslation();

    const login = useGoogleLogin({
        onSuccess: props.onClick,
        onError: props.onFailure,
    });

    return (
        <div className="d-grid gap-2">
            <button className="btn btn-primary" onClick={() => login()}>
                <i className="fab fa-google" />
                &nbsp;&nbsp;{t('button.sign-in-google')}
            </button>
        </div>
    );
};

export default GoogleButton;
