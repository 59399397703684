import React, { ComponentProps, useState } from 'react';
import styled from '@emotion/styled';
import { colors, spacing } from '../constants';
import Bordered from '../Styled/Bordered';

const logo = '\\resources\\img\\logo.svg';

interface IProps extends ComponentProps<any> {
    children?: any;
    clickHome: () => {};
}

const Wrapper = styled(Bordered)`
    padding: ${spacing.p1};
`;

const NavPositioner = styled.div`
    position: fixed;
    top: ${spacing.r1};
    left: ${spacing.r1};
    right: ${spacing.r1};
    z-index: 80;
    padding: 2px;
`;

const NavEl = styled.nav`
    display: flex;
`;

const Trigger = styled.button`
    margin-left: auto;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: ${colors.text};
    width: 60px;
    text-align: center;
`;

const Constant = styled.div`
    align-self: flex-start;
`;

type DropdownMenuProps = {
    visible: boolean;
};
const DropdownMenu = styled(Bordered)<DropdownMenuProps>`
    position: absolute;
    right: 0;
    top: calc(100% + ${spacing.r1});
    background: ${colors.bgSolid};
    display: ${(props: DropdownMenuProps) => (props.visible ? 'block' : 'none')};
    max-height: 80vh;
    overflow: auto;
    padding: 1rem;
    width: 300px;
`;

export function Nav(props: IProps) {
    const [showMenu, setShowMenu] = useState<boolean>(false);

    const toggleMenu = () => setShowMenu(!showMenu);

    return (
        <NavPositioner>
            <Wrapper>
                <NavEl>
                    <Constant>
                        <div onClick={() => props.clickHome()} className="navbar-brand mt-1 mb-1">
                            <img src={logo} alt="CSR Logo" className="me-2 ms-4" style={{ maxHeight: '32px', opacity: '.8' }} />
                            <span className="brand-text fw-light">CS: Reborn</span>
                        </div>
                    </Constant>

                    <Trigger onClick={toggleMenu}>
                        <span className="p-2" style={{ lineHeight: 0 }}>
                            {showMenu ? <i className="fas fa-times"></i> : <i className="fas fa-bars" />}
                        </span>
                    </Trigger>
                    <DropdownMenu visible={showMenu}>{props.children || null}</DropdownMenu>
                </NavEl>
            </Wrapper>
        </NavPositioner>
    );
}
