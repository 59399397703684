import React, { ComponentProps } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import './ExperienceStats.css';

import { IExperience } from '../../../interfaces/gameInterfaces';

interface IProps extends ComponentProps<any> {
    experience: IExperience[];
}

// --------------------------------------------
// UI Render Util Dynamic Generation Methods
// --------------------------------------------

const listExperience = (list: IExperience[]) => {
    return (
        list.length > 0 &&
        list.map(function (data: IExperience) {
            return (
                <tr key={uuidv4()}>
                    <td className="text-start"> {data.type} </td>
                    <td className="text-center"> {data.level} </td>
                    <td className="text-center"> {data.experience} </td>
                </tr>
            );
        })
    );
};

const ExperienceStats = (props: IProps) => {
    const { t } = useTranslation();

    return (
        <div className="table-responsive">
            <table className="table table-sm table-borderless text-light">
                <thead>
                    <tr>
                        <th className="text-start w-25">{t('common.type')}</th>
                        <th className="text-center w-25">{t('common.level')}</th>
                        <th className="text-center w-25">{t('common.experience')}</th>
                    </tr>
                </thead>
                <tbody>{listExperience(props.experience)}</tbody>
            </table>
        </div>
    );
};

export default ExperienceStats;
