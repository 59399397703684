import React, { ComponentProps } from 'react';

import './Input.css';

interface IProps extends ComponentProps<any> {
    id?: string;
    type?: string;
    name?: string;
    value?: string | number | any;
    icon?: string;
    min?: any;
    max?: any;
    placeholder?: string | any;
    onChange?: any;
    isInvalid?: boolean;
    isInvalidHelp?: string;
    required?: boolean;
    autocomplete?: string;
}

const Input = (props: IProps) => {
    const [showHelpOnFocus, setShowHelpOnFocus] = React.useState<boolean>(false);

    const inputElement = (
        <input
            id={props.id || ''}
            type={props.type || ''}
            name={props.name || ''}
            value={props.value || ''}
            min={props.min || undefined}
            max={props.max || undefined}
            onChange={props.onChange || ''}
            onFocus={() => setShowHelpOnFocus(true)}
            className={`form-control ${showHelpOnFocus && props.isInvalid ? 'is-invalid' : showHelpOnFocus ? 'is-valid' : ''}`}
            placeholder={props.placeholder || ''}
            required={props.required || false}
            autoComplete={props.autocomplete || ''}
        />
    );

    return (
        <div className="row">
            <div className="col mb-3">
                {!props.icon && (
                    <>
                        {inputElement}
                        {showHelpOnFocus && props.isInvalid && <div className="invalid-feedback">{props.isInvalidHelp || ''}</div>}
                    </>
                )}
                {props.icon && (
                    <div className="input-group">
                        {inputElement}
                        <div className="input-group-text">
                            <span className={props.icon} />
                        </div>
                        {showHelpOnFocus && props.isInvalid && <div className="invalid-feedback">{props.isInvalidHelp || ''}</div>}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Input;
