import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import apiProvider from '../../api';
import { CharContext } from '../../context/CharContext';
import { StatsContext } from '../../context/StatsContext';

import { v4 as uuidv4 } from 'uuid';

import ActionButton from '../../components/elements/ActionButton/ActionButton';
import ActionMenu from '../../components/elements/ActionMenu/ActionMenu';
import Card from '../../ui/Card/Card';
import Input from '../../components/elements/Input/Input';
import BankDeal from '../../components/elements/BankDeal/BankDeal';
import Loading from '../../ui/Loading/Loading';
import BankChart from '../../components/elements/BankChart/BankChart';

import { IBankSavingDeal, ICurrentSaving } from '../../interfaces/gameInterfaces';

interface IProps {}

const BankSavings = (props: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [refreshState, setRefreshState] = React.useState<number>(1);
    const [currentSavings, setCurrentSavings] = React.useState<ICurrentSaving[]>([]);
    const [bankSavingsDeals, setBankSavingsDeals] = React.useState<IBankSavingDeal[]>([]);
    const [selectedDeal, setSelectedDeal] = React.useState<IBankSavingDeal | null>(null);
    const [selectedSavings, setSelectedSavings] = React.useState<ICurrentSaving | null>(null);
    const [equity, setEquity] = React.useState<number>(10);
    const [currentInterest, setCurrentInterest] = React.useState<number>(0);

    // Context
    const [char, setChar] = React.useContext(CharContext);
    const [, setStats] = React.useContext(StatsContext);

    // Logout function
    const logout = () => {
        setChar(null);
        setStats(null);
        navigate('/');
    };

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    useEffect(() => {
        async function fetchData() {
            setSelectedSavings(null);
            setSelectedDeal(null);

            const [responseCurrentSavings, responseStats, responseBankSavingDeals] = await Promise.all([
                apiProvider.bankGetCurrentSavings(char.jwt, logout),
                apiProvider.statsGetKaracterStats(char.jwt, logout),
                apiProvider.bankGetBankDeals(char.jwt, logout),
            ]);

            if (!responseCurrentSavings || responseCurrentSavings.error) return;
            if (!responseStats || responseStats.error) return;
            if (!responseBankSavingDeals || responseBankSavingDeals.error) return;

            setBankSavingsDeals(responseBankSavingDeals);
            setCurrentSavings(responseCurrentSavings);
            setStats(responseStats);
        }

        fetchData();
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshState]);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------

    const generateRandomInterest = () => {
        if (selectedDeal) {
            return Math.random() * (selectedDeal.minInterest - selectedDeal.maxInterest) + selectedDeal.maxInterest;
        }

        return 0;
    };

    const onRefresh = (chart: any) => {
        const randomInterest = generateRandomInterest();
        setCurrentInterest(randomInterest);

        chart.data.datasets[0].data.push({
            x: Date.now(),
            y: randomInterest,
        });
    };

    const clickBack = () => {
        navigate('/bank');
    };

    const clickStartSaving = async () => {
        const response = await apiProvider.bankOpenSavings(char.jwt, selectedDeal!.id, currentInterest, equity, logout);

        if (!response || response.error) return;

        toast.success(t(response));
        setRefreshState(refreshState + 1);
    };

    const clickCloseSaving = async () => {
        const response = await apiProvider.bankCloseSavings(char.jwt, selectedSavings!.id, logout);

        if (!response || response.error) return;

        toast.success(t(response));
        setRefreshState(refreshState + 1);
    };

    // --------------------------------------------
    // UI Render Util Dynamic Generation Methods
    // --------------------------------------------

    const listBankDeals = (list: IBankSavingDeal[]) => {
        return (
            list.length > 0 &&
            list.map(function (data: IBankSavingDeal) {
                return (
                    <div key={uuidv4()} className="col-4">
                        <BankDeal
                            color={data === selectedDeal ? 'primary' : 'secondary'}
                            icon="fas fa-chart-bar"
                            value={data.turnDuration}
                            text={t('bank.turns')}
                            onClick={() => {
                                setSelectedSavings(null);
                                setSelectedDeal(data);
                            }}
                        />
                    </div>
                );
            })
        );
    };

    const listCurrentSavings = (list: ICurrentSaving[]) => {
        if (list.length === 0) {
            return (
                <div className="col">
                    <h3 className="text-center">{t('bank.no-savings')}</h3>
                </div>
            );
        }

        return (
            list.length > 0 &&
            list.map((data: ICurrentSaving) => {
                return (
                    <div key={uuidv4()} className="col-6 col-sm-4">
                        <BankDeal
                            color={data === selectedSavings ? 'primary' : 'secondary'}
                            icon="fas fa-chart-bar"
                            value={data.interest.toFixed(4)}
                            isPercentage
                            text={t('bank.equity') + ' ' + data.equity}
                            onClick={() => {
                                setSelectedDeal(null);
                                setSelectedSavings(data);
                            }}
                        />
                    </div>
                );
            })
        );
    };
    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return (
        <>
            <ActionMenu>
                <Loading>
                    <ActionButton type="button" label={t('button.back')} color="danger" action={clickBack} />
                    {!(selectedDeal || selectedSavings) && <ActionButton isEmpty={true} />}
                    {selectedDeal && <ActionButton type="button" label={t('button.open-savings')} color="primary" action={clickStartSaving} />}
                    {selectedSavings && <ActionButton type="button" label={t('button.close-savings')} color="danger" action={clickCloseSaving} />}
                </Loading>
            </ActionMenu>
            <div className="row">
                <div className="col-12 col-sm-6">
                    <Card>
                        <div className="row">{listCurrentSavings(currentSavings)}</div>
                    </Card>
                </div>
                <div className="col-12 col-sm-6">
                    <Card>
                        <div className="row">{listBankDeals(bankSavingsDeals)}</div>
                    </Card>
                </div>
            </div>
            <div className="row">
                <div className="col-12 offset-sm-2 col-sm-8">
                    {selectedSavings && (
                        <Card>
                            <table className="table table-borderless text-white">
                                <tbody>
                                    <tr>
                                        <td className="text-end w-col-50">
                                            {t('bank.equity')}
                                            &nbsp;
                                            <i className="fas fa-sign-in-alt" />
                                        </td>
                                        <td className="text-start w-col-50 fw-bold">{selectedSavings.equity}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-end w-col-50">
                                            {t('bank.interest')}
                                            &nbsp;
                                            <i className="fas fa-percent" />
                                        </td>
                                        <td className="text-start w-col-50 fw-bold">{selectedSavings.interest.toFixed(4)}%</td>
                                    </tr>
                                    <tr>
                                        <td className="text-end w-col-50">
                                            {t('bank.duration')}
                                            &nbsp;
                                            <i className="fas fa-recycle" />
                                        </td>
                                        <td className="text-start w-col-50 fw-bold">{selectedSavings.turnDuration}</td>
                                    </tr>
                                    {selectedSavings.openedAt + selectedSavings.turnDuration <= selectedSavings.currentTurn && (
                                        <tr>
                                            <td className="text-end w-col-50">
                                                {t('bank.payout')}
                                                &nbsp;
                                                <i className="fas fa-sign-out-alt" />
                                            </td>
                                            <td className="text-start w-col-50 fw-bold text-success">
                                                {Math.floor(selectedSavings.equity + selectedSavings.equity * selectedSavings.interest * 0.6)}
                                            </td>
                                        </tr>
                                    )}
                                    {selectedSavings.openedAt + selectedSavings.turnDuration > selectedSavings.currentTurn && (
                                        <tr>
                                            <td className="text-end w-col-50">
                                                {t('bank.premature-payout')}
                                                &nbsp;
                                                <i className="fas fa-sign-out-alt" />
                                            </td>
                                            <td className="text-start w-col-50 fw-bold text-danger">{Math.floor(selectedSavings.equity * 0.8)}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </Card>
                    )}
                </div>
                <div className="col-12 offset-sm-2 col-sm-8">
                    {selectedDeal && (
                        <Card>
                            <BankChart bankSavingDeal={selectedDeal} onRefresh={onRefresh} />
                            <div className="row">
                                <div className="col-6">
                                    <div className="text-center">
                                        <h3>{currentInterest.toFixed(4)}%</h3>
                                    </div>

                                    <h3 className="profile-username text-center">
                                        <p className="text-muted">{t('bank.current-interest')}</p>
                                    </h3>
                                </div>
                                <div className="col-6">
                                    <div className="text-center">
                                        <h3>{Math.floor(equity + equity * currentInterest * 0.6)}</h3>
                                    </div>
                                    <h3 className="profile-username text-center">
                                        <p className="text-muted">{t('bank.payout')}</p>
                                    </h3>
                                </div>
                            </div>
                            <table className="table table-borderless text-white">
                                <tbody>
                                    <tr>
                                        <td className="text-end w-col-50">
                                            {t('bank.number-turns')}
                                            &nbsp;
                                            <i className="fas fa-calendar" />
                                        </td>
                                        <td className="text-start w-col-50 fw-bold">{selectedDeal.turnDuration}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <Input
                                id={'equity'}
                                type={'number'}
                                name={'Equity'}
                                value={equity}
                                label={t('bank.equity')}
                                placeholder={10}
                                onChange={(e: any) => setEquity(parseInt(e.target.value))}
                                isInvalid={equity < 10}
                                isInvalidHelp={t('input.valid-equity')}
                                required={true}
                            />
                        </Card>
                    )}
                </div>
            </div>
        </>
    );
};
export default BankSavings;
