import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import './UserProfile.css';

import apiProvider from '../../api';
import { UserContext } from '../../context/UserContext';
import { CharContext } from '../../context/CharContext';
import { ArenaContext } from '../../context/ArenaContext';
import { BattleContext } from '../../context/BattleContext';
import { Center, colors } from '../../ui/constants';
import { NAME_REGEX } from '../../utils/utils';

import Card from '../../ui/Card/Card';
import Input from '../../components/elements/Input/Input';
import Loading from '../../ui/Loading/Loading';
import Button from '../../ui/Button/Button';

const logo = '\\resources\\img\\logo.svg';

interface IUserProfile {
    active: boolean;
    registrationDate: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    webEmail: string;
    facebookEmail: string;
    googleEmail: string;
    facebookImage: string;
    googleImage: string;
    consentGivenAt: string;
}

const defaultUserProfile: IUserProfile = {
    active: true,
    registrationDate: 'Loading...',
    firstName: 'Codename',
    lastName: 'Stormolow',
    dateOfBirth: 'Loading...',
    webEmail: 'loading@email.com',
    facebookEmail: 'loading@email.com',
    googleEmail: 'loading@gmail.com',
    facebookImage: logo,
    googleImage: logo,
    consentGivenAt: 'Loading...',
};

interface IProps {}

const UserProfile = (props: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [userProfile, setUserProfile] = React.useState<IUserProfile>(defaultUserProfile);
    const [refreshState, setRefreshState] = React.useState<number>(1);
    const [dateOfBirth, setDateOfBirth] = React.useState<string>('');
    const [firstName, setFirstName] = React.useState<string>('');
    const [lastName, setLastName] = React.useState<string>('');

    // Context
    const [user, setUser] = React.useContext(UserContext);
    const [, setChar] = React.useContext(CharContext);
    const [, setArena] = React.useContext(ArenaContext);
    const [, setBattle] = React.useContext(BattleContext);

    // Logout function
    const logout = () => {
        setUser(null);
        setChar(null);
        setArena(null);
        setBattle(null);
        navigate('/');
    };

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    useEffect(() => {
        async function fetchData() {
            const response = await apiProvider.userProfileGetUser(user.jwt, logout);

            if (!response || response.error) return;

            setFirstName(response.firstName);
            setLastName(response.lastName);
            setDateOfBirth(response.dateOfBirth);
            setUserProfile(response);
        }

        fetchData();
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshState]);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------
    const clickDeleteMyProfile = async () => {
        const response = await apiProvider.userProfileDeleteUser(user.jwt, logout);

        if (!response || response.error) return;

        setUser(null);
        setChar(null);
        setArena(null);
        setBattle(null);

        localStorage.clear();
        navigate('/login');
    };

    const clickUpdateMyProfile = async () => {
        const response = await apiProvider.userProfileUpdateUser(user.jwt, firstName, lastName, dateOfBirth, logout);

        if (!response || response.error) return;

        toast.success(response);

        setRefreshState(refreshState + 1);
    };

    const validateBirthday = (date: string) => {
        const userBirthday = new Date(date);
        const currentDate = new Date();

        if (userBirthday.getFullYear() < 1950) {
            return false;
        }

        if (userBirthday.getFullYear() > 2020) {
            return false;
        }

        return currentDate.getFullYear() - userBirthday.getFullYear() >= 13;
    };

    const clickBack = () => {
        navigate('/');
    };

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return (
        <>
            <div className="row">
                <div className="col-12 col-sm-6">
                    <Card title="user-profile.profile">
                        {userProfile && (
                            <>
                                <div className="text-center">
                                    <img
                                        style={{ maxHeight: '96px', maxWidth: '96px', height: '100%', width: '100%' }}
                                        className="rounded"
                                        alt="User"
                                        src={userProfile?.facebookImage || userProfile?.googleImage || logo}
                                    />
                                </div>

                                <h3 className="text-center">
                                    {userProfile.firstName} {userProfile.lastName}
                                </h3>

                                <ul className="mb-3 list-group">
                                    <li className="list-group-item text-light">
                                        <i className="fas fa-calendar" />
                                        &nbsp;
                                        <span className="fw-bold">{t('user-profile.dob')}</span> <span className="float-end">{userProfile.dateOfBirth}</span>
                                    </li>
                                    <li className="list-group-item text-light">
                                        <i className="fas fa-envelope" />
                                        &nbsp;
                                        <span className="fw-bold">{t('user-profile.email-web')}</span>
                                        <span className="float-end">{userProfile.webEmail}</span>
                                    </li>
                                    <li className="list-group-item text-light">
                                        <i className="fab fa-facebook" />
                                        &nbsp;
                                        <span className="fw-bold">{t('user-profile.email-facebook')}</span>
                                        <span className="float-end">{userProfile.facebookEmail}</span>
                                    </li>
                                    <li className="list-group-item text-light">
                                        <i className="fab fa-google" />
                                        &nbsp;
                                        <span className="fw-bold">{t('user-profile.email-google')}</span> <span className="float-end">{userProfile.googleEmail}</span>
                                    </li>
                                    <li className="list-group-item text-light">
                                        <i className="fas fa-clock" />
                                        &nbsp;
                                        <span className="fw-bold">{t('user-profile.consent-given')}</span>
                                        <span className="float-end">{userProfile.consentGivenAt}</span>
                                    </li>
                                    <li className="list-group-item text-light">
                                        <i className="fas fa-signature" />
                                        &nbsp;
                                        <span className="fw-bold">{t('user-profile.consented')}</span>
                                        <span className="float-end">
                                            <a href="/privacy-policy" target="_blank">
                                                {t('user-profile.privacy-policy')}
                                            </a>
                                        </span>
                                    </li>
                                </ul>
                            </>
                        )}
                    </Card>
                </div>
                <div className="col-12 col-sm-6">
                    <Card title="user-profile.update">
                        {userProfile && (
                            <>
                                <Input
                                    id={'dateOfBirth'}
                                    type={'date'}
                                    name={'Date of birth'}
                                    value={dateOfBirth}
                                    placeholder={new Date(userProfile.dateOfBirth).toLocaleString()}
                                    onChange={(e: any) => setDateOfBirth(e.target.value)}
                                    isInvalid={!validateBirthday(dateOfBirth)}
                                    isInvalidHelp={t('input.valid-dob')}
                                    required={true}
                                    autocomplete={'bday'}
                                />
                                <Input
                                    id={'firstName'}
                                    type={'text'}
                                    name={'First name'}
                                    value={firstName}
                                    placeholder={userProfile.firstName}
                                    onChange={(e: any) => setFirstName(e.target.value)}
                                    isInvalid={!NAME_REGEX.test(firstName)}
                                    isInvalidHelp={t('input.valid-first-name')}
                                    required={true}
                                    autocomplete={'given-name'}
                                />
                                <Input
                                    id={'lastName'}
                                    type={'text'}
                                    name={'Last name'}
                                    value={lastName}
                                    placeholder={userProfile.lastName}
                                    onChange={(e: any) => setLastName(e.target.value)}
                                    isInvalid={!NAME_REGEX.test(lastName)}
                                    isInvalidHelp={t('input.valid-last-name')}
                                    required={true}
                                    autocomplete={'family-name'}
                                />
                            </>
                        )}
                    </Card>
                </div>
            </div>
            <br />
            <Loading>
                <div className="row">
                    <div className="col">
                        <Center>
                            <Button style={{ width: '50%', backgroundColor: `${colors.tertiary}` }} onClick={clickBack}>
                                {t('button.back')}
                            </Button>
                        </Center>
                    </div>
                    <div className="col">
                        <Center>
                            <Button
                                style={{ width: '50%' }}
                                onClick={clickUpdateMyProfile}
                                disabled={!NAME_REGEX.test(firstName) || !NAME_REGEX.test(lastName) || !validateBirthday(dateOfBirth)}
                            >
                                {t('button.update-account')}
                            </Button>
                        </Center>
                    </div>
                    <div className="col">
                        <Center>
                            <Button style={{ width: '50%', backgroundColor: `${colors.tertiary}` }} onClick={clickDeleteMyProfile}>
                                {t('button.delete-account')}
                            </Button>
                        </Center>
                    </div>
                </div>
            </Loading>
        </>
    );
};

export default UserProfile;
