import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import './Sidebar.css';

import { UserContext } from '../../context/UserContext';
import { CharContext } from '../../context/CharContext';
import { ArenaContext } from '../../context/ArenaContext';
import { BattleContext } from '../../context/BattleContext';
import { StatsContext } from '../../context/StatsContext';

import { Nav } from '../../ui/Nav/Nav';

import SidebarLink from '../elements/SidebarLink/SidebarLink';
import News from '../News/News';
import Tutorial from '../Tutorial/Tutorial';

import i18n from '../../translations/i18n';
import packageJson from '../../../package.json';
import SidebarDropdownElement from '../elements/SidebarDropdownElement/SidebarDropdownElement';
import SidebarDropdown from '../elements/SidebarDropdown/SidebarDropdown';

interface IProps {}

const Sidebar = (props: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const version = packageJson.version;

    const [showNews, setShowNews] = React.useState<boolean>(false);
    const [showTutorial, setShowTutorial] = React.useState<boolean>(false);

    // Context
    const [user] = React.useContext(UserContext);
    const [, setChar] = React.useContext(CharContext);
    const [, setArena] = React.useContext(ArenaContext);
    const [, setBattle] = React.useContext(BattleContext);
    const [, setStats] = React.useContext(StatsContext);

    const clickLogout = () => {
        localStorage.clear();
        navigate('/login');
    };

    const clickNews = () => {
        setShowNews(true);
    };

    const clickTutorial = () => {
        setShowTutorial(true);
    };

    const clickDiscord = () => {
        window.open('https://discord.gg/v5BAKBq', '_blank');
    };

    const clickIssue = () => {
        window.open('https://gitlab.com/Zanoshky/codename-stormolow-reborn-web/-/issues', '_blank');
    };

    const clickHome = async () => {
        setArena(null);
        setBattle(null);
        setChar(null);
        setStats(null);
        localStorage.removeItem('CHAR_TOKEN');
        navigate('/');
    };

    const clickUserProfile = async () => {
        navigate('/my-profile');
    };

    const clickNewCharacter = async () => {
        navigate('/character-creation');
    };

    const clickCroatian = async () => {
        await i18n.changeLanguage('hr', (err) => {
            if (err) {
                console.error('something went wrong loading', err);
            }

            return true;
        });
    };

    const clickEnglish = async () => {
        await i18n.changeLanguage('en', (err) => {
            if (err) {
                console.error('something went wrong loading', err);
            }

            return true;
        });
    };

    const clickGerman = async () => {
        await i18n.changeLanguage('de', (err) => {
            if (err) {
                console.error('something went wrong loading', err);
            }

            return true;
        });
    };

    const clickRussian = async () => {
        await i18n.changeLanguage('ru', (err) => {
            if (err) {
                console.error('something went wrong loading', err);
            }

            return true;
        });
    };

    const isUserInMenu = () => {
        const menuLocations = ['/', '/home', '/character-creation', '/my-profile'];
        return menuLocations.indexOf(location.pathname) !== -1;
    };

    return (
        <Nav clickHome={clickHome}>
            {!isUserInMenu() && (
                <div className="mt-3">
                    <div className="text-center">
                        <div>{user.email}</div>
                    </div>
                </div>
            )}
            <nav className="mt-2">
                <ul className="navbar-nav nav-pills" data-widget="treeview" role="menu" data-accordion="false">
                    <SidebarLink icon="fas fa-code-branch fa-flip-vertical" title={`${t('footer.version')} ${version}`} onClick={() => {}} />
                    <SidebarLink icon="fas fa-sign-out-alt" title={t('sidebar.logout')} onClick={clickLogout} />
                    <SidebarLink icon="fas fa-home" title={t('sidebar.home')} onClick={clickHome} active={location.pathname === '/' || location.pathname === '/home'} />
                    {isUserInMenu() && (
                        <>
                            <SidebarLink
                                icon="fas fa-plus-square"
                                title={t('button.new-character')}
                                onClick={clickNewCharacter}
                                active={location.pathname === '/character-creation'}
                            />
                            <SidebarLink icon="fas fa-user" title={t('sidebar.my-profile')} onClick={clickUserProfile} active={location.pathname === '/my-profile'} />
                            <SidebarLink icon="fas fa-newspaper" title={t('sidebar.news')} onClick={clickNews} />
                        </>
                    )}
                    <SidebarLink icon="fas fa-question" title={t('sidebar.tutorial')} onClick={clickTutorial} />
                    <SidebarDropdown icon="fas fa-wrench" title={t('sidebar.settings')}>
                        <SidebarDropdownElement icon="fas fa-exclamation-triangle" title={t('sidebar.report')} onClick={clickIssue} active={false} />
                        <SidebarDropdownElement icon="fab fa-discord" title={t('brand.discord')} onClick={clickDiscord} active={false} />
                    </SidebarDropdown>

                    <SidebarDropdown icon="fas fa-language" title={t('sidebar.language')}>
                        <SidebarDropdownElement icon="fas fa-envelope-open-text" title={t('sidebar.croatian')} onClick={clickCroatian} active={i18n.language === 'hr'} />
                        <SidebarDropdownElement icon="fas fa-envelope-open-text" title={t('sidebar.english')} onClick={clickEnglish} active={i18n.language === 'en'} />
                        <SidebarDropdownElement icon="fas fa-envelope-open-text" title={t('sidebar.german')} onClick={clickGerman} active={i18n.language === 'de'} />
                        <SidebarDropdownElement icon="fas fa-envelope-open-text" title={t('sidebar.russian')} onClick={clickRussian} active={i18n.language === 'ru'} />
                    </SidebarDropdown>
                </ul>
            </nav>
            {showNews && <News showModal={setShowNews} />}
            {showTutorial && <Tutorial showModal={setShowTutorial} />}
        </Nav>
    );
};
export default Sidebar;
