import React, { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { ICharacter } from '../../../interfaces/gameInterfaces';

import './CharacterStats.css';

interface IProps extends ComponentProps<any> {
    stats: ICharacter;
}

const CharacterStats = (props: IProps) => {
    const { t } = useTranslation();

    return (
        <div className="table-responsive">
            <table className="table table-sm table-borderless text-light">
                {props.stats && (
                    <tbody>
                        <tr>
                            <td className="text-end fw-bold">{t('common.race')}</td>
                            <td className="text-start"> {props.stats.raceName} </td>
                        </tr>
                        <tr>
                            <td className="text-end fw-bold">{t('common.description')}</td>
                            <td className="text-start"> {props.stats.raceDescription} </td>
                        </tr>
                        <tr>
                            <td className="text-end fw-bold">{t('common.class')}</td>
                            <td className="text-start"> {props.stats.clazzName} </td>
                        </tr>
                        <tr>
                            <td className="text-end fw-bold">{t('common.description')}</td>
                            <td className="text-start"> {props.stats.clazzDescription} </td>
                        </tr>
                        <tr>
                            <td className="text-end fw-bold">{t('common.money')}</td>
                            <td className="text-start"> {props.stats.glassMarbles} </td>
                        </tr>
                        <tr>
                            <td className="text-end fw-bold">{t('common.score')}</td>
                            <td className="text-start"> {props.stats.score} </td>
                        </tr>
                        <tr>
                            <td className="text-end fw-bold">{t('common.health')}</td>
                            <td className="text-start text-danger">
                                {props.stats.currentHealth} / {props.stats.totalMaxHealth}
                            </td>
                        </tr>
                        <tr>
                            <td className="text-end fw-bold">{t('common.damage')}</td>
                            <td className="text-start"> {props.stats.totalDamage} </td>
                        </tr>
                        <tr>
                            <td className="text-end fw-bold">{t('common.spell')}</td>
                            <td className="text-start"> {props.stats.totalSpell} </td>
                        </tr>
                        <tr>
                            <td className="text-end fw-bold">{t('common.defense')}</td>
                            <td className="text-start"> {props.stats.totalDefense} </td>
                        </tr>
                        <tr>
                            <td className="text-end fw-bold">{t('common.agility')}</td>
                            <td className="text-start"> {props.stats.totalAgility} </td>
                        </tr>
                    </tbody>
                )}
            </table>
        </div>
    );
};

export default CharacterStats;
