import React, { ComponentProps } from 'react';
import styled from '@emotion/styled';

interface IProps extends ComponentProps<any> {
    icon: string;
    title: string;
    onClick: any;
    active?: boolean;
}

const Link = styled.li`
    cursor: pointer;
    padding: 0.5rem 1rem;
    font-weight: 700;
`;

const SidebarLink = (props: IProps) => {
    const { icon, title, onClick, active } = props;

    return (
        <Link onClick={onClick} className={`nav-item`}>
            <div className="d-grid gap-2">
                <button className={`fw-bold text-start nav-link ${active ? 'active' : ''}`}>
                    <i className={`ms-2 me-2 ${icon}`} />
                    {title}
                </button>
            </div>
        </Link>
    );
};

export default SidebarLink;
