import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ActionButton from '../../components/elements/ActionButton/ActionButton';
import ActionMenu from '../../components/elements/ActionMenu/ActionMenu';
import { IBackpack, IEquipmentItem, IItem } from '../../interfaces/gameInterfaces';
import { CharContext } from '../../context/CharContext';
import apiProvider from '../../api';
import Card from '../../ui/Card/Card';
import Loading from '../../ui/Loading/Loading';
import ItemList from '../../components/elements/ItemList/ItemList';
import Button from '../../components/elements/Button/Button';
import ItemTable from '../../components/elements/ItemTable/ItemTable';

interface IProps {}

const DepositBox = (props: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [refreshState, setRefreshState] = React.useState<number>(1);
    const [depositBox, setDepositBox] = React.useState<IItem[]>([]);
    const [selectedItem, setSelectedItem] = React.useState<IItem | IEquipmentItem | null>(null);
    const [isDepositBoxItem, setIsDepositBoxItem] = React.useState<boolean>(false);
    const [backpack, setBackpack] = React.useState<IBackpack>([]);

    // Context
    const [char, setChar] = React.useContext(CharContext);

    // Logout function
    const logout = () => {
        setChar(null);
        navigate('/');
    };

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    useEffect(() => {
        async function fetchData() {
            setSelectedItem(null);
            setBackpack([]);
            setDepositBox([]);

            const [responseMerchant, responseBackpack] = await Promise.all([apiProvider.getDepositBox(char.jwt, logout), apiProvider.backpackDownloadBackpack(char.jwt, logout)]);

            if (!responseMerchant || responseMerchant.error) return;
            if (!responseBackpack || responseBackpack.error) return;

            setDepositBox(responseMerchant);
            setBackpack(responseBackpack);
        }

        fetchData();
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshState]);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------

    const clickBack = () => {
        navigate('/bank');
    };

    const clickDepositItem = (data: IItem | IEquipmentItem) => {
        if (data.id === 1) return;

        setIsDepositBoxItem(true);
        setSelectedItem(data);
    };

    const clickBackpackItem = (data: IItem | IEquipmentItem) => {
        if (data.id === 1) return;

        setIsDepositBoxItem(false);
        setSelectedItem(data);
    };

    const clickWithdraw = async () => {
        if (selectedItem && selectedItem.id !== 1) {
            const response = await apiProvider.depositBoxWithdrawItem(char.jwt, selectedItem.id, logout);

            if (!response || response.error) return;

            setSelectedItem(null);
            setRefreshState(refreshState + 1);
        }
    };

    const clickDeposit = async () => {
        if (selectedItem && selectedItem.id !== 1) {
            const response = await apiProvider.depositBoxDepositItem(char.jwt, selectedItem.id, logout);

            if (!response || response.error) return;

            setSelectedItem(null);
            setRefreshState(refreshState + 1);
        }
    };

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return (
        <>
            <ActionMenu>
                <Loading>
                    <ActionButton type="button" label={t('button.back')} color="danger" action={clickBack} />
                </Loading>
            </ActionMenu>
            <div className="row">
                <div className="col-12 col-sm-4">
                    <Card title="location.deposit-box">
                        <ItemList items={depositBox} onClick={clickDepositItem} onDoubleClick={clickWithdraw} />
                    </Card>
                </div>
                <div className="col-12 col-sm-4">
                    <Card title="common.item-details">
                        {selectedItem && selectedItem.id !== 1 && (
                            <div className="col-12">
                                <ItemTable item={selectedItem} />
                                {isDepositBoxItem ? (
                                    <Button type="button" label={t('button.withdraw')} color="success" action={clickWithdraw} />
                                ) : (
                                    <Button type="button" label={t('button.deposit')} color="success" action={clickDeposit} />
                                )}
                            </div>
                        )}
                    </Card>
                </div>
                <div className="col-12 col-sm-4">
                    <Card title="sidebar.backpack">
                        <ItemList items={backpack} onClick={clickBackpackItem} onDoubleClick={clickDeposit} />
                    </Card>
                </div>
            </div>
        </>
    );
};

export default DepositBox;
