import React, { ComponentProps } from 'react';

import './CharacterTable.css';

import { IEquipment, IEquipmentItem } from '../../../interfaces/gameInterfaces';

import Icon from '../../../ui/Icon/Icon';
import { Center } from '../../../ui/constants';
import ItemTooltip from '../ItemTooltip/ItemTooltip';

interface IProps extends ComponentProps<any> {
    equipment: IEquipment;
    color: 'primary' | 'success' | 'danger' | 'warning' | 'secondary';
    battleOpacity?: boolean;
    clickHelmet: Function;
    clickMainHand: Function;
    clickArmor: Function;
    clickOffHand: Function;
    clickBelt: Function;
    clickPants: Function;
    clickFeet: Function;
}

const CharacterTable = (props: IProps) => {
    const RenderIconWithTooltip = (functionOnClick: Function, data: IEquipmentItem, flip: boolean = false) => {
        const randomNum = Math.random();
        return (
            <td key={`itemId_${randomNum}`} data-tip data-for={`itemId_${randomNum}`}>
                <span onClick={() => functionOnClick()}>
                    <Icon name={data.image} rarity={data.itemRarity} animateRaise flipY={flip} />
                    <ItemTooltip id={`itemId_${randomNum}`} item={data} />
                </span>
            </td>
        );
    };

    const { helmet, armor, belt, feet, mainHand, offHand, pants } = props.equipment || {};
    return (
        <div className="table-responsive" style={props.battleOpacity ? { opacity: '0.3' } : {}}>
            <Center>
                <table className="table table-sm table-borderless" style={{ maxWidth: '350px' }}>
                    <tbody>
                        <tr>
                            <td />
                            {RenderIconWithTooltip(props.clickHelmet, helmet)}
                            <td />
                        </tr>
                        <tr>
                            {RenderIconWithTooltip(props.clickMainHand, mainHand, true)}
                            {RenderIconWithTooltip(props.clickArmor, armor)}
                            {RenderIconWithTooltip(props.clickOffHand, offHand)}
                        </tr>
                        <tr>
                            <td />
                            {RenderIconWithTooltip(props.clickBelt, belt)}
                            <td />
                        </tr>
                        <tr>
                            <td />
                            {RenderIconWithTooltip(props.clickPants, pants)}
                            <td />
                        </tr>
                        <tr>
                            <td />
                            {RenderIconWithTooltip(props.clickFeet, feet)}
                            <td />
                        </tr>
                    </tbody>
                </table>
            </Center>
        </div>
    );
};

export default CharacterTable;
