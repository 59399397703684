import React from 'react';
import styled from '@emotion/styled';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

import Item from './Item';

import { colors, shadow, spacing } from '../constants';
import Bordered from '../Styled/Bordered';
import { StatsContext } from '../../context/StatsContext';
import { getColorFromScalar } from '../../utils/color';

const Position = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 70;
`;

const Bar = styled(Bordered)`
    margin: ${spacing.r1};
    padding: 0 0 0.8rem;
`;

const Items = styled.div`
    display: flex;
    padding: 1rem 1rem 0.3rem;
`;

const Actions = styled.div`
    position: absolute;
    right: 0;
    bottom: 100%;
`;

type HealthBarProps = {
    c: number;
    max: number;
};

const HealthBar = styled.div<HealthBarProps>`
    margin: 0 1rem 0;
    height: 15px;
    border-radius: 7px;
    background-color: #fff6;
    border: 2px solid ${colors.darker};
    overflow: hidden;
    position: relative;

    &::before {
        transition: width 1s;
        content: '';
        display: block;
        width: ${(p: HealthBarProps) => (p.c / p.max) * 100}%;
        background-color: ${(p: HealthBarProps) => getColorFromScalar(p.c / p.max)};
        box-shadow: ${shadow.single};
        height: 100%;
    }
`;

interface IHeroBarProps {
    onBackpackClick: Function;
    onEquipmentClick: Function;
    onPotionHolderClick: Function;
    onHeroClick: Function;
}

export default function HeroBar(props: IHeroBarProps) {
    const { onBackpackClick, onEquipmentClick, onPotionHolderClick, onHeroClick } = props;

    const { t } = useTranslation();

    const [stats] = React.useContext(StatsContext);

    return (
        stats && (
            <Position>
                <Bar>
                    <Items>
                        <span onClick={() => onHeroClick()}>
                            <Item image={stats.raceImage} title={stats.raceName} />
                        </span>
                        <span onClick={() => onEquipmentClick()}>
                            <Item image={stats.clazzImage} title={stats.clazzName} />
                        </span>
                        <Item image="GlassMarbles_Blackball" title={`${stats.glassMarbles} ${t('common.money')}`} />
                        <span onClick={() => onBackpackClick()}>
                            <Item
                                image="Backpack"
                                title={`${stats.freeBackpackSpace} / ${stats.totalBackpackSpace}`}
                                color={`${getColorFromScalar(stats.freeBackpackSpace / stats.totalBackpackSpace)}`}
                            />
                        </span>
                        <span onClick={() => onPotionHolderClick()}>
                            <Item
                                image="MagicPotion"
                                title={`${stats.freePotionSpace} / ${stats.totalPotionSpace}`}
                                color={`${getColorFromScalar((stats.totalPotionSpace - stats.freePotionSpace) / stats.totalPotionSpace)}`}
                            />
                        </span>

                        <Actions>
                            <div id="actionMenuPortalTarget" />
                        </Actions>
                    </Items>
                    <HealthBar data-tip data-for="healthBar" c={stats.currentHealth} max={stats.totalMaxHealth} />
                    <ReactTooltip id="healthBar" aria-haspopup="true" className="soTooltip">
                        {stats.currentHealth} / {stats.totalMaxHealth} {t('common.health')}
                    </ReactTooltip>
                </Bar>
            </Position>
        )
    );
}
