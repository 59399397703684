import React, { ComponentProps } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, Filler } from 'chart.js';
import 'chartjs-adapter-luxon';
import ChartStreaming from 'chartjs-plugin-streaming';

ChartJS.register(LineElement, PointElement, LinearScale, Filler, Title, ChartStreaming);

interface IProps extends ComponentProps<any> {
    bankSavingDeal: any;
    onRefresh: any;
}

class BankChart extends React.Component<IProps> {
    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<{}>, nextContext: any): boolean {
        return this.props.bankSavingDeal !== nextProps.bankSavingDeal;
    }

    render() {
        const { onRefresh, bankSavingDeal } = this.props;
        return (
            <div style={{ maxHeight: '200px' }}>
                <Line
                    data={{
                        datasets: [
                            {
                                label: 'Interest',
                                backgroundColor: 'rgb(0,104,255, 0.25)',
                                borderColor: 'rgb(0,104,255)',
                                pointRadius: 4,
                                data: [],
                                fill: true,
                                cubicInterpolationMode: 'monotone',
                            },
                        ],
                    }}
                    options={{
                        plugins: {
                            streaming: {
                                onRefresh: (chart: any) => onRefresh(chart),
                                delay: 1000,
                                refresh: 500,
                                frameRate: 60,
                                duration: 10800,
                            },
                        },
                        maintainAspectRatio: false,
                        responsive: true,
                        scales: {
                            x: {
                                type: 'realtime',
                                realtime: {
                                    duration: 10000,
                                    onRefresh: onRefresh,
                                },
                                grid: {
                                    display: false,
                                },
                                ticks: {
                                    display: false,
                                },
                            },
                            y: {
                                min: bankSavingDeal.minInterest - 0.05,
                                max: bankSavingDeal.maxInterest + 0.05,
                                grid: {
                                    display: false,
                                },
                            },
                        },
                        hover: {
                            mode: 'nearest',
                            intersect: false,
                        },
                    }}
                />
            </div>
        );
    }
}

export default BankChart;
