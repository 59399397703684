import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { v4 as uuidv4 } from 'uuid';

import Title from '../Styled/Title';
import Card from '../Card/Card';
import { colors, spacing } from '../constants';

interface IProps {
    titles: string[];
    content: Array<() => React.ElementRef<any>>;
    loading?: boolean;
    fit?: boolean;
}

const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const Tabs = styled.ul`
    display: flex;
    padding: 0;
    list-style: none;
    margin: calc(-${spacing.r1} + 6px) calc(-${spacing.r1} + 6px) ${spacing.r1};
    border-bottom: 2px solid ${colors.border};

    @media screen and (min-width: 600px) {
        margin: calc(-1.5rem + 6px) calc(-1.5rem + 6px) ${spacing.r1};
    }
`;

type TabProps = {
    active?: boolean;
};
const Tab = styled.li<TabProps>`
    position: relative;
    margin: 0;
    padding: ${spacing.r1} 0;
    background: ${(props: TabProps) => (props.active ? colors.bg : 'transparent')};
    margin: 0;
    transition: background 0.2s;
    cursor: pointer;

    &:hover {
        &::after {
            border: 2px solid ${(props: TabProps) => (props.active ? 'transparent' : '#fff2')};
        }
    }

    &::after {
        position: absolute;
        content: '';
        display: block;
        left: 4px;
        right: 4px;
        top: 4px;
        bottom: 4px;
        border-radius: 8px;
        pointer-events: none;
        transition: border 0.2s;
        border: 2px solid transparent;
    }
`;

export default function TabCards(props: IProps) {
    const { t } = useTranslation();

    const { titles, content, fit, ...otherProps } = props;
    const [activeTab, setTab] = useState<number>(0);

    return (
        <Wrap {...otherProps}>
            <Card style={{ flex: 1 }} fit={fit}>
                <Tabs>
                    {titles.map((title, i) => (
                        <Tab key={uuidv4()} active={i === activeTab} onClick={() => setTab(i)}>
                            <Title>{t(title)}</Title>
                        </Tab>
                    ))}
                </Tabs>
                {content[activeTab]()}
            </Card>
        </Wrap>
    );
}
