import React from 'react';
import { IJwtToken } from '../interfaces/gameInterfaces';

export const ArenaContext = React.createContext<any>(null);

interface IProps {
    children: React.ReactNode;
}

const ArenaContextProvider: React.FC<IProps> = (props: IProps) => {
    const [arena, setArena] = React.useState<IJwtToken | null>(null);

    return <ArenaContext.Provider value={[arena, setArena]}>{props.children}</ArenaContext.Provider>;
};

export default ArenaContextProvider;
