import React from 'react';
import { IJwtToken } from '../interfaces/gameInterfaces';

export const BattleContext = React.createContext<any>(null);

interface IProps {
    children: React.ReactNode;
}

const BattleContextProvider: React.FC<IProps> = (props: IProps) => {
    const [battle, setBattle] = React.useState<IJwtToken | null>(null);

    return <BattleContext.Provider value={[battle, setBattle]}>{props.children}</BattleContext.Provider>;
};

export default BattleContextProvider;
