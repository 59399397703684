import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import './Skinner.css';

import { IItemForUpgrade, ISkinnerUpgrade } from '../../interfaces/gameInterfaces';

import ActionButton from '../../components/elements/ActionButton/ActionButton';
import ActionMenu from '../../components/elements/ActionMenu/ActionMenu';
import Table from '../../components/elements/Table/Table';
import Card from '../../ui/Card/Card';
import Loading from '../../ui/Loading/Loading';
import Item from '../../ui/HeroBar/Item';

import apiProvider from '../../api';
import { CharContext } from '../../context/CharContext';
import { StatsContext } from '../../context/StatsContext';
import { Center, colors } from '../../ui/constants';

interface IProps {}

const Skinner = (props: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [refreshState, setRefreshState] = React.useState<number>(1);
    const [skinnerUpgrades, setSkinnerUpgrades] = React.useState<ISkinnerUpgrade[]>([]);
    const [itemsForUpgrade, setItemsForUpgrade] = React.useState<IItemForUpgrade[]>([]);
    const [myAmount, setMyAmount] = React.useState<number | null>(null);

    // Context
    const [char, setChar] = React.useContext(CharContext);
    const [, setStats] = React.useContext(StatsContext);

    // Logout function
    const logout = () => {
        setChar(null);
        setStats(null);
        navigate('/');
    };

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    useEffect(() => {
        async function fetchData() {
            setSkinnerUpgrades([]);
            setItemsForUpgrade([]);
            setMyAmount(null);

            const [responseGlassMarbles, responseUpgradeItems, responseUpgrades, responseStats] = await Promise.all([
                apiProvider.backpackGetAmountOfGlassMarbles(char.jwt, logout),
                apiProvider.backpackAmountOfSkinnerUpgradeItems(char.jwt, logout),
                apiProvider.skinnerGetUpgrades(char.jwt, logout),
                apiProvider.statsGetKaracterStats(char.jwt, logout),
            ]);

            if (!responseGlassMarbles || responseGlassMarbles.error) return;
            if (!responseUpgradeItems || responseUpgradeItems.error) return;
            if (!responseUpgrades || responseUpgrades.error) return;
            if (!responseStats || responseStats.error) return;

            setStats(responseStats);
            setMyAmount(responseGlassMarbles.glassMarbles);
            setItemsForUpgrade(responseUpgradeItems);
            setSkinnerUpgrades(responseUpgrades);
        }

        fetchData();
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshState]);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------

    const clickBack = () => {
        navigate('/town');
    };

    const clickUpgrade = async (upgrade: any) => {
        if (upgrade) {
            const response = await apiProvider.skinnerUpgradeInventory(char.jwt, upgrade.id, logout);

            if (!response || response.error) return;

            // todo toast
        }

        setRefreshState(refreshState + 1);
    };

    // --------------------------------------------
    // UI Render Util Dynamic Generation Methods
    // --------------------------------------------

    const listItemsForUpgrade = () => {
        return (
            itemsForUpgrade.length > 0 &&
            itemsForUpgrade.map(function (data: IItemForUpgrade) {
                return (
                    <span key={uuidv4()}>
                        &nbsp;
                        {data.name}&nbsp;
                        <span className="lead fw-bold">x{data.amount}</span>
                    </span>
                );
            })
        );
    };

    const listUpgrades = () => {
        return (
            skinnerUpgrades.length > 0 &&
            skinnerUpgrades.map(function (data: ISkinnerUpgrade, index: number) {
                return (
                    <tr key={data.id}>
                        <td className="text-center w-col-30">{data.name}</td>
                        <td className="text-center text-success w-col-10 fw-bold">+{data.size}</td>
                        <td className="text-center text-primary w-col-10 fw-bold">{data.buy}</td>
                        <td className="text-center w-col-40">{data.requirement}</td>
                        <td className="text-center w-col-10">
                            <Center>
                                <span
                                    onClick={() =>
                                        (data.id === 1 || data.id === 51 || skinnerUpgrades[index - 1].hasExtension === 1) && data.hasExtension === 0 && clickUpgrade(data)
                                    }
                                >
                                    <Item
                                        image="Upgrade"
                                        title={t('button.upgrade')}
                                        color={
                                            (data.id === 1 || data.id === 51 || skinnerUpgrades[index - 1].hasExtension === 1) && data.hasExtension === 0
                                                ? colors.primary
                                                : colors.disabled
                                        }
                                    />
                                </span>
                            </Center>
                        </td>
                    </tr>
                );
            })
        );
    };

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return (
        <>
            <ActionMenu>
                <Loading>
                    <ActionButton type="button" label={t('button.back')} color="danger" action={clickBack} />
                </Loading>
            </ActionMenu>
            <Card title="button.upgrade">
                <p>
                    {t('common.i-have')}&nbsp;
                    <span className="lead fw-bold">{myAmount}</span>&nbsp;{t('common.money')}.
                </p>
                <p>
                    {t('skinner.available-items')}:{itemsForUpgrade ? listItemsForUpgrade() : <span className="lead fw-bold">{t('skinner.none')}</span>}
                </p>
                <Table
                    label={t('skinner.available-upgrades')}
                    thead={[
                        { label: t('skinner.name'), class: 'text-center' },
                        { label: t('skinner.size'), class: 'text-center' },
                        { label: t('skinner.price'), class: 'text-center' },
                        { label: t('skinner.requirements'), class: 'text-center' },
                        { label: t('skinner.action'), class: ' text-center' },
                    ]}
                    tbody={listUpgrades()}
                />
            </Card>
        </>
    );
};

export default Skinner;
