import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import './PotionHolder.css';

import { IPotionHolder, IPotionItem } from '../../interfaces/gameInterfaces';

import apiProvider from '../../api';
import { CharContext } from '../../context/CharContext';
import { StatsContext } from '../../context/StatsContext';

import { defaultPotionItem } from '../../interfaces/defaultValues';

import ActionButton from '../elements/ActionButton/ActionButton';
import ItemTable from '../elements/ItemTable/ItemTable';
import Card from '../../ui/Card/Card';
import Icon from '../../ui/Icon/Icon';
import Loading from '../../ui/Loading/Loading';

interface IProps {
    showModal: Function;
}

const PotionHolder = (props: IProps) => {
    const { t } = useTranslation();

    const [showModal, setShowModal] = React.useState<boolean>(true);
    const [refreshState, setRefreshState] = React.useState<number>(1);
    const [currentItem, setCurrentItem] = React.useState<IPotionItem>(defaultPotionItem);
    const [potionHolder, setPotionHolder] = React.useState<IPotionHolder>([defaultPotionItem, defaultPotionItem, defaultPotionItem, defaultPotionItem]);

    // Context
    const [char] = React.useContext(CharContext);
    const [, setStats] = React.useContext(StatsContext);

    // Logout function
    const logout = async () => {
        await clickClose();
    };

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------
    useEffect(() => {
        async function fetchData() {
            setCurrentItem(defaultPotionItem);
            setPotionHolder([defaultPotionItem, defaultPotionItem, defaultPotionItem, defaultPotionItem]);

            const [responsePotionHolder, responseStats] = await Promise.all([
                apiProvider.potionHolderDownloadPotionHolder(char.jwt, logout),
                apiProvider.statsGetKaracterStats(char.jwt, logout),
            ]);

            if (!responsePotionHolder || responsePotionHolder.error) return;
            if (!responseStats || responseStats.error) return;

            setPotionHolder(responsePotionHolder);
            setStats(responseStats);
        }

        fetchData();
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshState]);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------

    const clickClose = async () => {
        setShowModal(false);
        props.showModal(false);
    };

    const clickPotion = async (data: IPotionItem) => {
        setCurrentItem(data);
    };

    const clickDrop = async () => {
        if (currentItem) {
            const response = await apiProvider.potionHolderDropItem(char.jwt, currentItem.id, logout);

            if (!response || response.error) return;
        }

        setRefreshState(refreshState + 1);
    };

    const clickConsume = async () => {
        if (currentItem) {
            const response = await apiProvider.potionHolderConsumePotion(char.jwt, currentItem.id, logout);

            if (!response || response.error) return;
        }

        setRefreshState(refreshState + 1);
    };

    // --------------------------------------------
    // UI Render Util Dynamic Generation Methods
    // --------------------------------------------

    const listPotions = () => {
        return (
            potionHolder.length > 0 &&
            potionHolder.map(function (data: IPotionItem) {
                return (
                    <div key={uuidv4()} className="col-3 p-1">
                        <span onClick={() => clickPotion(data)}>
                            <Icon name={data.image} rarity={data.itemRarity} animateRaise />
                        </span>
                    </div>
                );
            })
        );
    };

    const dynamicModalClass = () => (showModal ? { display: 'block' } : { display: 'none' });

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return showModal ? (
        <div id="modal fade" tabIndex={-1} role="dialog" className={`modal ${showModal ? 'show' : 'hide'}`} style={dynamicModalClass()}>
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <Card>
                                    <div className="row">{listPotions()}</div>
                                </Card>
                            </div>
                            <div className="col-12 col-sm-6">
                                {currentItem && currentItem.id !== 8000 && (
                                    <Card>
                                        <ItemTable item={currentItem} />
                                    </Card>
                                )}
                            </div>
                        </div>
                    </div>
                    <Loading>
                        <div className="modal-footer">
                            <ActionButton type="button" label={t('button.close')} color="danger" action={clickClose} />
                            {currentItem && currentItem.id !== 8000 && <ActionButton type="button" label={t('button.drop')} color="danger" action={clickDrop} />}
                            {currentItem && currentItem.id !== 8000 && <ActionButton type="button" label={t('button.consume')} color="success" action={clickConsume} />}
                            {(currentItem === null || currentItem.id === 8000) && <ActionButton isEmpty={true} />}
                            {(currentItem === null || currentItem.id === 8000) && <ActionButton isEmpty={true} />}
                        </div>
                    </Loading>
                </div>
            </div>
        </div>
    ) : null;
};

export default PotionHolder;
