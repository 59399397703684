import React from 'react';
import styled from '@emotion/styled';
import { GiBeech, GiCastle, GiDeadWood, GiFruitTree, GiHutsVillage, GiPathTile, GiPeaks, GiPineTree, GiSaintBasilCathedral, GiSilex, GiStoneWall, GiVillage } from 'react-icons/gi';

interface IProps {
    image: string;
}

const colorMap: { [key: string]: any } = {
    Meadow: '#158511',
    Sand: '#ff9900',
    Karst: '#666666',
    Forrest: '#05270d',
    Water: '#2389da',
};

const iconsMap: { [key: string]: any } = {
    StoneWall: <GiStoneWall />,
    Village: <GiVillage />,
    Castle: <GiCastle />,
    SaintBasilCathedral: <GiSaintBasilCathedral />,
    HutsVillage: <GiHutsVillage />,
    PineTree: <GiPineTree />,
    DeadWood: <GiDeadWood />,
    Beech: <GiBeech />,
    FruitTree: <GiFruitTree />,
    Rock: <GiSilex />,
    PathTile: <GiPathTile />,
    Peaks: <GiPeaks />,
    Mountain: <GiPeaks />,
};

const Image = styled.div`
    position: absolute;
    width: 70%;
    height: 70%;
    left: 50%;
    top: 45%;
    transform: translateY(-50%) translateX(-50%);

    svg {
        width: 100%;
        height: 100%;
    }
`;

type BgProps = {
    color?: string;
};

const Bg = styled.div<BgProps>`
    position: relative;
    background: ${(p: BgProps) => p.color || '#fff8'};
    width: 125px;
    max-width: 100%;
    border-radius: 3px;
    box-shadow: inset 0 0 15px #0003;

    &::before {
        display: block;
        content: '';
        padding-bottom: 100%;
    }
`;

export default function FieldImage(props: IProps) {
    const code = props.image.split('_');
    const image = code.length === 2 ? code[1] : code[0];
    const type = code[0];

    return (
        <Bg data-image={image} data-type={type} color={colorMap[type]}>
            {iconsMap.hasOwnProperty(image) && <Image>{iconsMap[image]}</Image>}
        </Bg>
    );
}
