import React, { ComponentProps } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface Header {
    class?: string;
    label: string;
}

interface IProps extends ComponentProps<any> {
    label?: string;
    thead: Header[];
    tbody: any;
}

const Table = (props: IProps) => {
    const formulateHeader = () => {
        return props.thead.map((header) => {
            return (
                <th key={uuidv4()} className={header.class || undefined}>
                    {header.label}
                </th>
            );
        });
    };
    return (
        <div className="row">
            <div className="col">
                <div className="table-responsive">
                    <table className="table table-valign-middle table-sm table-borderless text-white">
                        <thead>
                            {props.label && (
                                <tr>
                                    <th className="text-center" colSpan={props.thead.length}>
                                        {props.label}
                                    </th>
                                </tr>
                            )}
                            <tr>{formulateHeader()}</tr>
                        </thead>
                        <tbody>{props.tbody}</tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Table;
