import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ActionButton from '../../components/elements/ActionButton/ActionButton';
import ActionMenu from '../../components/elements/ActionMenu/ActionMenu';

interface IProps {}

const Gamble = (props: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------
    const clickBack = () => {
        navigate('/town');
    };

    const clickHighLow = () => {
        navigate('/highlow');
    };

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return (
        <ActionMenu>
            <ActionButton type="button" label={t('button.back')} color="danger" action={clickBack} />
            <ActionButton type="button" label={t('location.high-low')} color="primary" action={() => clickHighLow()} />
        </ActionMenu>
    );
};

export default Gamble;
