import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import ActionMenu from '../../components/elements/ActionMenu/ActionMenu';
import ActionButton from '../../components/elements/ActionButton/ActionButton';
import Button from '../../components/elements/Button/Button';
import Table from '../../components/elements/Table/Table';
import Card from '../../ui/Card/Card';

import apiProvider from '../../api';
import { CharContext } from '../../context/CharContext';
import { ArenaContext } from '../../context/ArenaContext';
import Loading from '../../ui/Loading/Loading';

interface IProps {}

const Arena = (props: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [refreshState, setRefreshState] = React.useState<number>(1);
    const [searchingTrouble, setSearchingTrouble] = React.useState<boolean>(false);
    const [listOfTroubles, setListOfTroubles] = React.useState<[]>([]);

    // Context
    const [char, setChar] = React.useContext(CharContext);
    const [arena, setArena] = React.useContext(ArenaContext);

    // Logout function
    const logout = () => {
        setChar(null);
        setArena(null);
        navigate('/');
    };

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    useEffect(() => {
        async function fetchData() {
            const response = await apiProvider.arenaGetListOfAvailableTroubles(char.jwt, logout);

            if (!response || response.error) return;

            setListOfTroubles(response || []);
        }

        fetchData();
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshState]);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------

    const clickRefresh = async () => {
        setRefreshState(refreshState + 1);
    };

    const clickSearchTrouble = async () => {
        setSearchingTrouble(true);

        const response = await apiProvider.arenaSearchForTrouble(char.jwt, logout);

        if (!response || response.error) return;

        setArena(response);
    };

    const clickCancelSearch = async () => {
        if (searchingTrouble) {
            const response = await apiProvider.arenaCancelSearchForTrouble(arena.jwt, logout);

            if (!response || response.error) return;

            setArena(null);
            setSearchingTrouble(false);
        }
    };

    const clickFight = async (data: any) => {
        if (searchingTrouble) {
            await clickCancelSearch();
        }
        // TODO
    };

    const clickBack = async () => {
        if (searchingTrouble) {
            await clickCancelSearch();
        }

        navigate('/town');
    };

    // --------------------------------------------
    // UI Render Util Dynamic Generation Methods
    // --------------------------------------------

    const listTroubles = () => {
        return (
            listOfTroubles.length > 0 &&
            listOfTroubles.map(function (data: any) {
                return (
                    <tr key={uuidv4()}>
                        <td className="text-center" style={{ width: '20%' }}>
                            {data.karacter_name}
                        </td>
                        <td className="text-center" style={{ width: '20%' }}>
                            {data.race}
                        </td>
                        <td className="text-center" style={{ width: '20%' }}>
                            {data.clazz}
                        </td>
                        <td className="text-center" style={{ width: '20%' }}>
                            {data.combat_level}
                        </td>
                        <td className="text-center" style={{ width: '20%' }}>
                            <Button type="button" label={t('button.fight')} color="danger" action={() => clickFight(data)} />
                        </td>
                    </tr>
                );
            })
        );
    };

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return (
        <>
            <ActionMenu>
                <Loading>
                    <ActionButton type="button" label={t('button.back')} color="danger" action={clickBack} />
                    {!searchingTrouble ? (
                        <ActionButton type="button" label={t('button.search-trouble')} color="danger" action={clickSearchTrouble} />
                    ) : (
                        <ActionButton type="button" label={t('button.cancel-trouble')} color="danger" action={clickCancelSearch} />
                    )}
                    <ActionButton type="button" label={t('button.refresh')} color="primary" action={clickRefresh} />
                </Loading>
            </ActionMenu>
            <div className="row">
                <div className="col-12 col-sm-6">
                    <Card title="arena.available">
                        <Table
                            thead={[
                                { label: t('arena.challenger'), class: 'text-center' },
                                { label: t('common.race'), class: 'text-center' },
                                { label: t('common.class'), class: 'text-center' },
                                { label: t('common.level'), class: 'text-center' },
                                { label: t('arena.action'), class: ' text-center' },
                            ]}
                            tbody={listTroubles()}
                        />
                    </Card>
                </div>
                <div className="col-12 col-sm-6">
                    <Card title="arena.leaderboard">{/*  todo */}</Card>
                </div>
            </div>
        </>
    );
};

export default Arena;
