import React, { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { IEquipmentItem, IItem, IStatsItem } from '../../../interfaces/gameInterfaces';

interface IProps extends ComponentProps<any> {
    id: string;
    item: IItem;
}

const ItemTooltip = (props: IProps) => {
    const { id, item } = props;
    const { t } = useTranslation();

    return (
        <ReactTooltip id={id} aria-haspopup="true" className="soTooltip" multiline>
            {item.name !== 'Empty Space' && !item.name.startsWith('No ') ? (
                <>
                    {item.name}
                    <br />
                    {item.description}
                    <br />
                    {item.location}
                    {(item as IStatsItem).health != null ? (
                        <>
                            <br />
                            {t('common.health')}&nbsp;
                            <span className="float-end">{(item as IStatsItem).health}</span>
                        </>
                    ) : (
                        <></>
                    )}
                    {(item as IStatsItem).damage != null ? (
                        <>
                            <br />
                            {t('common.damage')}&nbsp;
                            <span className="float-end">{(item as IStatsItem).damage}</span>
                        </>
                    ) : (
                        <></>
                    )}
                    {(item as IStatsItem).spell != null ? (
                        <>
                            <br />
                            {t('common.spell')}&nbsp;
                            <span className="float-end">{(item as IStatsItem).spell}</span>
                        </>
                    ) : (
                        <></>
                    )}
                    {(item as IStatsItem).defense != null ? (
                        <>
                            <br />
                            {t('common.defense')}&nbsp;
                            <span className="float-end">{(item as IStatsItem).defense}</span>
                        </>
                    ) : (
                        <></>
                    )}
                    {(item as IStatsItem).agility != null ? (
                        <>
                            <br />
                            {t('common.agility')}&nbsp;
                            <span className="float-end">{(item as IStatsItem).agility}</span>
                        </>
                    ) : (
                        <></>
                    )}
                    {(item as IEquipmentItem).forClass != null ? (
                        <>
                            <br />
                            {t('common.for-class')}&nbsp;
                            <span className="float-end"> {(item as IEquipmentItem).forClass}</span>
                        </>
                    ) : (
                        <></>
                    )}
                    {(item as IEquipmentItem).forRace != null ? (
                        <>
                            <br />
                            {t('common.for-race')}&nbsp;
                            <span className="float-end">{(item as IEquipmentItem).forRace}</span>
                        </>
                    ) : (
                        <></>
                    )}
                    {item.buy != null ? (
                        <>
                            <br />
                            {t('common.purchase-price')}&nbsp;
                            <span className="float-end">{item.buy}</span>
                        </>
                    ) : (
                        <></>
                    )}
                    {item.sell != null ? (
                        <>
                            <br />
                            {t('common.sell-price')}&nbsp;
                            <span className="float-end">{item.sell}</span>
                        </>
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <>{item.name}</>
            )}
        </ReactTooltip>
    );
};

ItemTooltip.defaultProps = {
    id: undefined,
    item: undefined,
};

export default ItemTooltip;
