import styled from '@emotion/styled';
import { colors } from '../constants';

const Title = styled.h3`
    margin: 0 auto;
    font-size: 1.2rem;
    padding: 0.3rem 1.6rem 0.3rem;
    text-align: center;
    font-weight: 700;
    color: ${colors.text};
    text-shadow: 0.12em 0.12em 0 ${colors.darker};
`;

export default Title;
