import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';

const api = axios.create({
    timeout: 10000,
    headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
    },
    baseURL: process.env.REACT_APP_API,
    validateStatus: function (status: number) {
        return true;
    },
});

let que: Set<string> = new Set<string>();

export const queMethods = {
    add: (key: string): any => que.add(key),
    remove: (key: string): any => que.delete(key),
    has: (key: string): boolean => que.has(key),
};

export function useQue() {
    const [current, setQue] = useState<Set<string>>(que);

    useEffect(() => {
        queMethods.add = (key: string) => {
            que.add(key);
            // console.log('que add:', key);
            setQue(new Set(que));
        };
        queMethods.remove = (key: string) => {
            que.delete(key);
            // console.log('que rem:', key);
            setQue(new Set(que));
        };
    });

    return current;
}

export const get = async (url: any, config: any, logout: any, key: string) => {
    if (queMethods.has(key)) {
        console.warn(`request ${key} already pending`);
        return { error: '' };
    }

    try {
        queMethods.add(key);
        const response = await api.get(url, config);
        queMethods.remove(key);

        if (response.data.ok) {
            return response.data;
        } else if (response.data.logout) {
            toast.warn(response.data.msg);
            localStorage.clear();
            logout();
            return { data: { error: true } };
        } else if (response.data.info) {
            toast.info(response.data.msg);
            return { data: { error: true } };
        } else if (response.data.warn) {
            toast.warn(response.data.msg);
            return { data: { error: true } };
        } else if (response.data.error) {
            toast.error(response.data.msg);
            return { data: { error: true } };
        } else {
            toast.error(response.data);
            return { data: { error: true } };
        }
    } catch (e) {
        queMethods.remove(key);
        console.error(e);
        throw e;
    }
};

export const post = async (url: any, payload: any, config: any, logout: Function, key: string) => {
    if (queMethods.has(key)) {
        console.warn(`request ${key} already pending`);
        return { error: '' };
    }

    try {
        queMethods.add(key);
        const response = await api.post(url, payload, config);
        queMethods.remove(key);

        if (response.data.ok) {
            return response.data;
        } else if (response.data.logout) {
            localStorage.clear();
            toast.warn(response.data.msg);
            logout();
            return { data: { error: true } };
        } else if (response.data.info) {
            toast.info(response.data.msg);
            return { data: { error: true } };
        } else if (response.data.warn) {
            toast.warn(response.data.msg);
            return { data: { error: true } };
        } else if (response.data.error) {
            toast.error(response.data.msg);
            return { data: { error: true } };
        } else {
            toast.error(response.data);
            return { data: { error: true } };
        }
    } catch (e) {
        queMethods.remove(key);
        console.error(e);
        throw e;
    }
};

export const put = async (url: any, payload: any, config: any, logout: any, key: string) => {
    if (queMethods.has(key)) {
        console.warn(`request ${key} already pending`);
        return { error: '' };
    }
    try {
        queMethods.add(key);
        const response = await api.put(url, payload, config);
        queMethods.remove(key);

        if (response.data.ok) {
            return response.data;
        } else if (response.data.logout) {
            toast.warn(response.data.msg);
            localStorage.clear();
            logout();
            return { data: { error: true } };
        } else if (response.data.info) {
            toast.info(response.data.msg);
            return { data: { error: true } };
        } else if (response.data.warn) {
            toast.warn(response.data.msg);
            return { data: { error: true } };
        } else if (response.data.error) {
            toast.error(response.data.msg);
            return { data: { error: true } };
        } else {
            toast.error(response.data);
            return { data: { error: true } };
        }
    } catch (e) {
        queMethods.remove(key);
        console.error(e);
        throw e;
    }
};

export default api;
