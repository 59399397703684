import axios from 'axios';
import { get, post, put } from './api';

const googleApi = axios.create({
    timeout: 10000,
    headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
    },
    baseURL: 'https://www.googleapis.com',
    validateStatus: function (status: number) {
        return true;
    },
});

const timeoutResponse = {
    error: true,
    msg: 'BACKEND_TOO_BUSY',
};

const getConfig = (token: string) => {
    return { headers: { Authorization: 'Bearer ' + token } };
};

const apiProvider = {
    userProfileGetUser: async (userToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/user-profile`, getConfig(userToken), logout, 'userProfileGetUser');
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    userProfileUpdateUser: async (userToken: string, firstName: string, lastName: string, dateOfBirth: string, logout: Function) => {
        try {
            const response = await post(
                `/v3/update-profile`,
                {
                    first_name: firstName,
                    last_name: lastName,
                    date_of_birth: dateOfBirth,
                },
                getConfig(userToken),
                logout,
                'userProfileUpdateUser'
            );
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    userProfileDeleteUser: async (userToken: string, logout: Function) => {
        try {
            const response = await post(`/v3/delete-profile`, {}, getConfig(userToken), logout, 'userProfileDeleteUser');
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    arenaSearchForTrouble: async (charToken: string, logout: Function) => {
        try {
            const response = await post(`/v3/search-for-trouble`, {}, getConfig(charToken), logout, 'arenaSearchForTrouble');
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    arenaCancelSearchForTrouble: async (arenaToken: string, logout: Function) => {
        try {
            const response = await post(`/v3/cancel-search-for-trouble`, {}, getConfig(arenaToken), logout, 'arenaCancelSearchForTrouble');
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    arenaGetListOfAvailableTroubles: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/trouble`, getConfig(charToken), logout, 'arenaGetListOfAvailableTroubles');
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    backpackDownloadBackpack: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/backpack`, getConfig(charToken), logout, 'backpackDownloadBackpack');
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    backpackDownloadEquipment: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/equipment`, getConfig(charToken), logout, 'backpackDownloadEquipment');
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    backpackGetAmountOfGlassMarbles: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/glass-marbles`, getConfig(charToken), logout, 'backpackGetAmountOfGlassMarbles');
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    backpackDropItem: async (charToken: string, itemId: number, logout: Function) => {
        try {
            const response = await post(
                `/v3/drop-item`,
                {
                    item_id: itemId,
                },
                getConfig(charToken),
                logout,
                'backpackDropItem'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    equipmentDequip: async (charToken: string, itemId: number, logout: Function) => {
        try {
            const response = await post(
                `/v3/dequip`,
                {
                    item_id: itemId,
                },
                getConfig(charToken),
                logout,
                'equipmentDequip'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    equipmentEquip: async (charToken: string, itemId: number, logout: Function) => {
        try {
            const response = await post(
                `/v3/equip`,
                {
                    item_id: itemId,
                },
                getConfig(charToken),
                logout,
                'equipmentEquip'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    equipmentSwitchEquipment: async (charToken: string, currentItemId: number, replacementItemId: number, logout: Function) => {
        try {
            const response = await post(
                `/v3/switch-equipment`,
                {
                    current_item: currentItemId,
                    replacement_item: replacementItemId,
                },
                getConfig(charToken),
                logout,
                'equipmentSwitchEquipment'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    potionHolderDownloadPotionHolder: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/potion-holder`, getConfig(charToken), logout, 'potionHolderDownloadPotionHolder');
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    potionHolderDropItem: async (charToken: string, potionId: number, logout: Function) => {
        try {
            const response = await post(
                `/v3/drop-potion`,
                {
                    potion_id: potionId,
                },
                getConfig(charToken),
                logout,
                'potionHolderDropItem'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    potionHolderConsumePotion: async (charToken: string, potionId: number, logout: Function) => {
        try {
            const response = await post(
                `/v3/consume-potion`,
                {
                    potion_id: potionId,
                },
                getConfig(charToken),
                logout,
                'potionHolderConsumePotion'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    armorsmithDownloadArmorsmithMerchandise: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/armorsmith-merchandise`, getConfig(charToken), logout, 'armorsmithDownloadArmorsmithMerchandise');
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    weaponsmithDownloadWeaponsmithMerchandise: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/weaponsmith-merchandise`, getConfig(charToken), logout, 'weaponsmithDownloadWeaponsmithMerchandise');
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    pharmacistDownloadPharmacistMerchandise: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/pharmacist-merchandise`, getConfig(charToken), logout, 'pharmacistDownloadPharmacistMerchandise');
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    pharmacistBuyPotion: async (charToken: string, potionId: number, logout: Function) => {
        try {
            const response = await post(
                `/v3/purchase-potion`,
                {
                    potion_id: potionId,
                },
                getConfig(charToken),
                logout,
                'pharmacistBuyPotion'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    pharmacistSellPotion: async (charToken: string, potionId: number, logout: Function) => {
        try {
            const response = await post(
                `/v3/sell-potion`,
                {
                    potion_id: potionId,
                },
                getConfig(charToken),
                logout,
                'pharmacistSellPotion'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    merchantDownloadMerchantMerchandise: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/merchant-merchandise`, getConfig(charToken), logout, 'merchantDownloadMerchantMerchandise');
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    userFacebookLogin: async (facebookUserId: string, email: string, accessToken: string, expiresAt: number, name: string, url: string, logout: Function) => {
        try {
            const response = await post(
                `/v3/facebook-login`,
                {
                    facebookUserId,
                    email,
                    access_token: accessToken,
                    expires_at: expiresAt,
                    name,
                    url,
                },
                {},
                logout,
                'userFacebookLogin'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    userGoogleLogin: async (googleUserId: string, email: string, accessToken: string, expiresAt: number, firstName: string, lastName: string, url: string, logout: Function) => {
        try {
            const response = await post(
                `/v3/google-login`,
                {
                    googleUserId,
                    email,
                    access_token: accessToken,
                    expires_at: expiresAt,
                    first_name: firstName,
                    last_name: lastName,
                    url,
                },
                {},
                logout,
                'userGoogleLogin'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    userLogin: async (email: string, password: string, logout: Function) => {
        try {
            const response = await post(
                `/v3/login`,
                {
                    email,
                    password,
                },
                {},
                logout,
                'userLogin'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    userRequestPasswordReset: async (email: string, logout: Function) => {
        try {
            const response = await post(
                `/v3/request-password-reset`,
                {
                    email,
                },
                {},
                logout,
                'userRequestPasswordReset'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    userPasswordReset: async (token: string, password: string, logout: Function) => {
        try {
            const response = await post(
                `/v3/reset-password`,
                {
                    token,
                    password,
                },
                {},
                logout,
                'userPasswordReset'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    homeGetLiveCharacters: async (userToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/alive-characters`, getConfig(userToken), logout, 'homeGetLiveCharacters');
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    homeGetDeadCharacters: async (userToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/dead-characters`, getConfig(userToken), logout, 'homeGetDeadCharacters');
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    homePlay: async (userToken: string, charId: number, logout: Function) => {
        try {
            const response = await post(
                `/v3/play`,
                {
                    karacter_id: charId,
                },
                getConfig(userToken),
                logout,
                'homePlay'
            );
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    // TODO
    homeGetGameData: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/save-data`, getConfig(charToken), logout, 'homeGetGameData');

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    characterCreationGetRaces: async (userToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/race`, getConfig(userToken), logout, 'characterCreationGetRaces');

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    characterCreationGetClasses: async (userToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/class`, getConfig(userToken), logout, 'characterCreationGetClasses');

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    characterCreationCreateNewCharacter: async (userToken: string, raceId: number, classId: number, name: string, logout: Function) => {
        try {
            const response = await post(
                `/v3/new-character`,
                {
                    race_id: raceId,
                    clazz_id: classId,
                    name,
                },
                getConfig(userToken),
                logout,
                'characterCreationCreateNewCharacter'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    gameSaveData: async (charToken: string, data: string, logout: Function) => {
        try {
            const response = await put(
                `/v3/save-data`,
                {
                    data,
                },
                getConfig(charToken),
                logout,
                'gameSaveData'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    gameSaveLocation: async (charToken: string, location: string, logout: Function) => {
        try {
            const response = await put(
                `/v3/save-location`,
                {
                    location,
                },
                getConfig(charToken),
                logout,
                'gameSaveLocation'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    gameSaveCoordinate: async (charToken: string, fieldId: number, logout: Function) => {
        try {
            const response = await put(
                `/v3/save-coordinate`,
                {
                    field_id: fieldId,
                },
                getConfig(charToken),
                logout,
                'gameSaveCoordinate'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    gameGetCharacterData: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/save-data`, getConfig(charToken), logout, 'gameGetCharacterData');
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    townIsCharacterInTown: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/is-karacter-in-town`, getConfig(charToken), logout, 'townIsCharacterInTown');

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    townGetTownDetails: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/town-details`, getConfig(charToken), logout, 'townGetTownDetails');
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    merchantPurchaseAction: async (charToken: string, itemId: number, logout: Function) => {
        try {
            const response = await post(
                `/v3/purchase-action`,
                {
                    item_id: itemId,
                },
                getConfig(charToken),
                logout,
                'merchantPurchaseAction'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    merchantSellAction: async (charToken: string, itemId: number, logout: Function) => {
        try {
            const response = await post(
                `/v3/sell-action`,
                {
                    item_id: itemId,
                },
                getConfig(charToken),
                logout,
                'merchantSellAction'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    mapDownloadMap: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/map`, getConfig(charToken), logout, 'mapDownloadMap');
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    mapDownloadActions: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/field-actions`, getConfig(charToken), logout, 'mapDownloadActions');
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    mapTravel: async (charToken: string, fieldId: number, logout: Function) => {
        try {
            const response = await post(
                `/v3/travel`,
                {
                    field_id: fieldId,
                },
                getConfig(charToken),
                logout,
                'mapTravel'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    statsGetKaracterStats: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/karacter-stats`, getConfig(charToken), logout, 'statsGetKaracterStats');
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    statsGetKaracterExperience: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/karacter-experience`, getConfig(charToken), logout, 'statsGetKaracterExperience');

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    signupRegisterAccount: async (email: string, password: string, logout: Function) => {
        try {
            const response = await post(
                `/v3/register-user`,
                {
                    email: email,
                    password: password,
                },
                {},
                logout,
                'signupRegisterAccount'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    signupVerifyAccount: async (token: string, logout: Function) => {
        try {
            const response = await get(`/v3/verify-account/${token}`, {}, logout, 'signupVerifyAccount');

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    skinnerGetUpgrades: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/skinner`, getConfig(charToken), logout, 'skinnerGetUpgrades');

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    skinnerUpgradeInventory: async (charToken: string, upgradeId: number, logout: Function) => {
        try {
            const response = await post(
                `/v3/upgrade-inventory`,
                {
                    upgrade_id: upgradeId,
                },
                getConfig(charToken),
                logout,
                'skinnerUpgradeInventory'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    backpackAmountOfSkinnerUpgradeItems: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/skinner-upgrade-items`, getConfig(charToken), logout, 'backpackAmountOfSkinnerUpgradeItems');

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    searchFieldSearchField: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/search-field`, getConfig(charToken), logout, 'searchFieldSearchField');

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },
    searchFieldIsKaracterOnField: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/is-karacter-on-field`, getConfig(charToken), logout, 'searchFieldIsKaracterOnField');

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    cutTreeIsKaracterOnCutTree: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/is-karacter-on-cut-tree`, getConfig(charToken), logout, 'cutTreeIsKaracterOnCutTree');

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    cutTreeCutTree: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/cut-tree`, getConfig(charToken), logout, 'cutTreeCutTree');

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    pickFruitPickFruit: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/pick-fruit`, getConfig(charToken), logout, 'pickFruitPickFruit');

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    pickFruitIsKaracterOnPickFruit: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/is-karacter-on-pick-fruit`, getConfig(charToken), logout, 'pickFruitIsKaracterOnPickFruit');

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    campGetCamp: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/camp`, getConfig(charToken), logout, 'campGetCamp');

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    campIsKaracterOnCamp: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/is-karacter-on-camp`, getConfig(charToken), logout, 'isKaracterOnCamp');

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    lootEnemy: async (battleToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/loot-enemy`, getConfig(battleToken), logout, 'lootEnemy');

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    searchFieldStoreItem: async (charToken: string, itemId: number, logout: Function) => {
        try {
            const response = await post(
                `/v3/store-item`,
                {
                    item_id: itemId,
                },
                getConfig(charToken),
                logout,
                'searchFieldStoreItem'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    gambleMakeBet: async (charToken: string, bet: number, logout: Function) => {
        try {
            const response = await post(
                `/v3/bet`,
                {
                    bet: bet,
                },
                getConfig(charToken),
                logout,
                'gambleMakeBet'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    gambleMakePrediction: async (charToken: string, prediction: number, logout: Function) => {
        try {
            const response = await post(
                `/v3/predict`,
                {
                    prediction: prediction,
                },
                getConfig(charToken),
                logout,
                'gambleMakePrediction'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    gambleCollectPayout: async (charToken: string, logout: Function) => {
        try {
            const response = await post(`/v3/collect-payout`, {}, getConfig(charToken), logout, 'gambleCollectPayout');
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    battlePveGetBattleId: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/pve-battle`, getConfig(charToken), logout, 'battlePveGetBattleId');

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    battlePveFirstTurn: async (battleToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/pve-first-turn`, getConfig(battleToken), logout, 'battlePveFirstTurn');
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    battlePveBattleStats: async (battleToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/battle-stats`, getConfig(battleToken), logout, 'battlePveBattleStats');

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    battlePveBattleActions: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/battle-actions`, getConfig(charToken), logout, 'battlePveBattleActions');

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    currentLocation: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/location`, getConfig(charToken), logout, 'currentLocation');
            if (response.data.location) {
                return response.data.location;
            } else {
                return 'login';
            }
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    battlePveBattleAction: async (battleToken: string, battleSideId: number, battleActionId: number, logout: Function) => {
        try {
            const response = await post(
                `/v3/battle-action`,
                {
                    battle_side_id: battleSideId,
                    battle_action_id: battleActionId,
                },
                getConfig(battleToken),
                logout,
                'battlePveBattleAction'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    newsGetLatestNews: async (userToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/news`, getConfig(userToken), logout, 'newsGetLatestNews');
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    bankGetBankDeals: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/bank-deals`, getConfig(charToken), logout, 'bankGetBankDeals');
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    bankOpenSavings: async (charToken: string, bankSavingsDeal: number, interest: number, equity: number, logout: Function) => {
        try {
            const response = await post(
                `/v3/open-savings`,
                {
                    bank_savings_deal_id: bankSavingsDeal,
                    interest: interest,
                    equity: equity,
                },
                getConfig(charToken),
                logout,
                'bankOpenSavings'
            );
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    bankCloseSavings: async (charToken: string, bankSavings: number, logout: Function) => {
        try {
            const response = await post(
                `/v3/close-savings`,
                {
                    bank_savings_id: bankSavings,
                },
                getConfig(charToken),
                logout,
                'bankCloseSavings'
            );
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    bankGetCurrentSavings: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/current-savings`, getConfig(charToken), logout, 'bankGetCurrentSavings');
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    craftingPostCraft: async (charToken: string, item1: number, item2: number, item3: number, logout: Function) => {
        try {
            const response = await post(
                `/v3/craft`,
                {
                    item1,
                    item2,
                    item3,
                },
                getConfig(charToken),
                logout,
                'craftingPostCraft'
            );
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    showcaseEquipment: async () => {
        try {
            const response = await get(`/v3/showcase/equipment`, {}, null, `showcaseEquipment`);
            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    depositBoxUpgrades: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/deposit-box-upgrades`, getConfig(charToken), logout, 'depositBoxUpgrades');

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    postDepositBoxUpgrades: async (charToken: string, upgradeId: number, logout: Function) => {
        try {
            const response = await post(
                `/v3/upgrade-deposit-box`,
                {
                    upgrade_id: upgradeId,
                },
                getConfig(charToken),
                logout,
                'postDepositBoxUpgrades'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    getDepositBox: async (charToken: string, logout: Function) => {
        try {
            const response = await get(`/v3/deposit-box`, getConfig(charToken), logout, 'getDepositBox');

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    depositBoxWithdrawItem: async (charToken: string, itemId: number, logout: Function) => {
        try {
            const response = await post(
                `/v3/withdraw-item`,
                {
                    item_id: itemId,
                },
                getConfig(charToken),
                logout,
                'depositBoxWithdrawItem'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    depositBoxDepositItem: async (charToken: string, itemId: number, logout: Function) => {
        try {
            const response = await post(
                `/v3/deposit-item`,
                {
                    item_id: itemId,
                },
                getConfig(charToken),
                logout,
                'depositBoxDepositItem'
            );

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },

    googleApiProfile: async (accessToken: string) => {
        try {
            const response = await googleApi.get('/oauth2/v3/userinfo', getConfig(accessToken));

            return response.data;
        } catch (e) {
            console.error(e);
            return timeoutResponse;
        }
    },
};

export default apiProvider;
