import React, { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import './MobileBattleTable.css';

interface IProps extends ComponentProps<any> {
    myStats: any;
    opponent: any;
}

const MobileBattleTable = (props: IProps) => {
    const { t } = useTranslation();

    const getStatsColor = (myStats: any, opponent: any) => {
        if (myStats > opponent) {
            return 'text-success';
        } else if (myStats === opponent) {
            return 'text-primary';
        } else {
            return 'text-danger';
        }
    };

    return (
        <div className="table-responsive">
            <table className="table table-sm table-borderless">
                {props.myStats && props.opponent && (
                    <tbody>
                        <tr>
                            <td className="text-end">{t('common.name')}</td>
                            <td className="text-center fw-bold">{props.myStats.karacterName}</td>
                            <td className="text-center fw-bold">{props.opponent.karacterName}</td>
                        </tr>
                        <tr>
                            <td className="text-end">{t('common.level')}</td>
                            <td className={`text-center fw-bold ${getStatsColor(props.myStats.combatLevel, props.opponent.combatLevel)}`}>{props.myStats.combatLevel}</td>
                            <td className={`text-center fw-bold ${getStatsColor(props.opponent.combatLevel, props.myStats.combatLevel)}`}>{props.opponent.combatLevel}</td>
                        </tr>
                        <tr>
                            <td className="text-end">{t('common.health')}</td>
                            <td className={`text-center fw-bold ${getStatsColor(props.myStats.health, props.opponent.health)}`}>
                                {props.myStats.currentHealth} / {props.myStats.health}
                            </td>
                            <td className={`text-center fw-bold ${getStatsColor(props.opponent.health, props.myStats.health)}`}>
                                {props.opponent.currentHealth} / {props.opponent.health}
                            </td>
                        </tr>
                        <tr>
                            <td className="text-end">{t('common.damage')}</td>
                            <td className={`text-center fw-bold ${getStatsColor(props.myStats.damage, props.opponent.damage)}`}>{props.myStats.damage}</td>
                            <td className={`text-center fw-bold ${getStatsColor(props.opponent.damage, props.myStats.damage)}`}>{props.opponent.damage}</td>
                        </tr>
                        <tr>
                            <td className="text-end">{t('common.spell')}</td>
                            <td className={`text-center fw-bold ${getStatsColor(props.myStats.spell, props.opponent.spell)}`}>{props.myStats.spell}</td>
                            <td className={`text-center fw-bold ${getStatsColor(props.opponent.spell, props.myStats.spell)}`}>{props.opponent.spell}</td>
                        </tr>
                        <tr>
                            <td className="text-end">{t('common.defense')}</td>
                            <td className={`text-center fw-bold ${getStatsColor(props.myStats.defense, props.opponent.defense)}`}>{props.myStats.defense}</td>
                            <td className={`text-center fw-bold ${getStatsColor(props.opponent.defense, props.myStats.defense)}`}>{props.opponent.defense}</td>
                        </tr>
                        <tr>
                            <td className="text-end">{t('common.agility')}</td>
                            <td className={`text-center fw-bold ${getStatsColor(props.myStats.agility, props.opponent.agility)}`}>{props.myStats.agility}</td>
                            <td className={`text-center fw-bold ${getStatsColor(props.opponent.agility, props.myStats.agility)}`}>{props.opponent.agility}</td>
                        </tr>
                    </tbody>
                )}
            </table>
        </div>
    );
};

export default MobileBattleTable;
