export const intToHex = (i: any) => {
    var hex = parseInt(i).toString(16);
    return hex.length < 2 ? '0' + hex : hex;
};

export const getColorFromScalar = (value: any) => {
    value = Math.min(Math.max(0, value), 1) * 510;

    let redValue;
    let greenValue;

    if (value < 255) {
        redValue = 255;
        greenValue = Math.sqrt(value) * 16;
        greenValue = Math.round(greenValue);
    } else {
        greenValue = 255;
        value = value - 255;
        redValue = 255 - (value * value) / 255;
        redValue = Math.round(redValue);
    }

    return '#' + intToHex(redValue) + intToHex(greenValue) + '00';
};
