import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import './BattlePve.css';

import { IEquipment } from '../../interfaces/gameInterfaces';

import BattleButton from '../../components/elements/BattleButton/BattleButton';
import CharacterTable from '../../components/elements/CharacterTable/CharacterTable';
import BattleTable from '../../components/elements/BattleTable/BattleTable';
import MobileBattleTable from '../../components/elements/MobileBattleTable/MobileBattleTable';
import ActionButton from '../../components/elements/ActionButton/ActionButton';
import ActionMenu from '../../components/elements/ActionMenu/ActionMenu';
import Card from '../../ui/Card/Card';
import Loading from '../../ui/Loading/Loading';

import apiProvider from '../../api';
import { CharContext } from '../../context/CharContext';
import { BattleContext } from '../../context/BattleContext';
import { StatsContext } from '../../context/StatsContext';
import { defaultEquipment } from '../../interfaces/defaultValues';
import { Center } from '../../ui/constants';

const enemyLevel1 = '\\resources\\img\\enemy-lvl-1.svg';
const enemyLevel3 = '\\resources\\img\\enemy-lvl-3.svg';
const enemyLevel2 = '\\resources\\img\\enemy-lvl-2.svg';
const enemyLevel4 = '\\resources\\img\\enemy-lvl-4.svg';
const enemyLevel5 = '\\resources\\img\\enemy-lvl-5.svg';
const enemyLevel6 = '\\resources\\img\\enemy-lvl-6.svg';
const enemyLevel7 = '\\resources\\img\\enemy-lvl-7.svg';
const enemyLevel8 = '\\resources\\img\\enemy-lvl-8.svg';
const enemyLevel9 = '\\resources\\img\\enemy-lvl-9.svg';
const enemyLevel10 = '\\resources\\img\\enemy-lvl-10.svg';

interface IBattleStats {
    battle_pve_id: number;
    battle_side_id: number;
    karacterName: string;
    race: string;
    clazz: string;
    combatLevel: number;
    currentHealth: number;
    health: number;
    damage: number;
    spell: number;
    defense: number;
    agility: number;
}

interface IBattleAction {
    id: number;
    description: string;
}

interface IProps {}

const BattlePve = (props: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [refreshState, setRefreshState] = React.useState<number>(1);
    const [turnSideId, setTurnSideId] = React.useState<number | undefined>(undefined);
    const [equipmentBlue, setEquipmentBlue] = React.useState<IEquipment>(defaultEquipment);
    const [statsRed, setStatsRed] = React.useState<IBattleStats | undefined>(undefined);
    const [statsBlue, setStatsBlue] = React.useState<IBattleStats | undefined>(undefined);
    const [actionsBlue, setActionsBlue] = React.useState<IBattleAction[]>([]);
    const [gameOver, setGameOver] = React.useState<boolean>(false);
    const [battleEscape, setBattleEscape] = React.useState<boolean>(false);
    const [enemyEscape, setEnemyEscape] = React.useState<boolean>(false);

    // Context
    const [char, setChar] = React.useContext(CharContext);
    const [battle, setBattle] = React.useContext(BattleContext);
    const [, setStats] = React.useContext(StatsContext);

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    // Logout function
    const logout = () => {
        setChar(null);
        setBattle(null);
        setStats(null);
        navigate('/');
    };

    useEffect(() => {
        async function fetchData() {
            setActionsBlue([]);

            const [currentLocation, battleId, responseBlueEquipment, responseBattleActions, responseStats] = await Promise.all([
                apiProvider.currentLocation(char.jwt, logout),
                apiProvider.battlePveGetBattleId(char.jwt, logout),
                apiProvider.backpackDownloadEquipment(char.jwt, logout),
                apiProvider.battlePveBattleActions(char.jwt, logout),
                apiProvider.statsGetKaracterStats(char.jwt, logout),
            ]);

            if (!currentLocation || currentLocation.error) return;
            if (!battleId || battleId.error) return;
            if (!responseBlueEquipment || responseBlueEquipment.error) return;
            if (!responseBattleActions || responseBattleActions.error) return;
            if (!responseStats || responseStats.error) return;

            setStats(responseStats);

            // If battle failed, redirect to map
            if (battleId === 'map') {
                navigate(`/map`);
            }

            if (currentLocation !== 'battlepve') {
                navigate(`/${currentLocation}`);
                return;
            }

            setBattle(battleId);

            const [responseFirstTurn, responseBattleStats, responseSave] = await Promise.all([
                apiProvider.battlePveFirstTurn(battleId.jwt, logout),
                apiProvider.battlePveBattleStats(battleId.jwt, logout),
                apiProvider.gameSaveLocation(char.jwt, 'battlepve', logout),
            ]);

            if (!responseFirstTurn || responseFirstTurn.error) return;
            if (!responseBattleStats || responseBattleStats.error) return;
            if (!responseSave || responseSave.error) return;

            setTurnSideId(responseFirstTurn);
            setBattleStats(responseBattleStats);
            setEquipmentBlue(responseBlueEquipment);
            setActionsBlue(responseBattleActions);

            if (turnSideId === 1) {
                await enemyAction();
            }
        }

        fetchData();
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshState]);

    const enemyAction = async () => {
        const responseBattleAction = await apiProvider.battlePveBattleAction(battle.jwt, 1, 0, logout);

        const responseStats = await apiProvider.statsGetKaracterStats(char.jwt, logout);
        if (!responseStats || responseStats.error) return;

        setStats(responseStats);

        if (!responseBattleAction || responseBattleAction.error) {
            return;
        } else if (responseBattleAction.battleWon) {
            navigate('/loot-enemy');
            return;
        } else if (responseBattleAction.battleEscape) {
            setEnemyEscape(true);
            return;
        } else if (responseBattleAction.gameOver) {
            setGameOver(true);
            return;
        }

        if (turnSideId === 1) {
            setTurnSideId(2);
        }

        setRefreshState(refreshState + 1);
    };

    const setBattleStats = (responseBattleAction: any) => {
        setStatsRed(responseBattleAction[0]);
        setStatsBlue(responseBattleAction[1]);
    };

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------

    const clickAction = async (action: number) => {
        setActionsBlue([]);
        const responseBattleAction = await apiProvider.battlePveBattleAction(battle.jwt, 2, action, logout);

        if (!responseBattleAction || responseBattleAction.error) {
            return;
        } else if (responseBattleAction.battleWon) {
            navigate('/loot-enemy');
            return;
        } else if (responseBattleAction.battleEscape) {
            setBattleEscape(true);
        } else if (responseBattleAction.gameOver) {
            setGameOver(true);
        } else {
            await enemyAction();
        }
    };

    const clickExit = async () => {
        await goToMap();
    };

    const clickHome = () => {
        setBattle(null);
        setChar(null);
        setStats(null);
        localStorage.removeItem('CHAR_TOKEN');
        navigate('/');
    };

    const goToMap = async () => {
        setBattle(null);

        await apiProvider.gameSaveLocation(char.jwt, 'map', logout);

        navigate('/map');
    };

    // --------------------------------------------
    // UI Render Util Dynamic Generation Methods
    // --------------------------------------------

    const listActions = (actions: IBattleAction[]) => {
        if (actions.length === 0) {
            return <ActionButton key={uuidv4()} type="button" label={t('map-action.no-actions')} color="secondary" disabled />;
        }

        return (
            actions.length > 0 &&
            actions.map(function (data: any) {
                return <BattleButton key={uuidv4()} label={data.description} action={() => clickAction(data.id)} />;
            })
        );
    };

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    const renderGameOver = () => {
        if (gameOver) {
            return (
                <>
                    <ActionMenu>
                        <Loading>
                            <ActionButton type="button" label={t('button.back')} color="danger" action={() => clickHome()} />
                        </Loading>
                    </ActionMenu>
                    <Card>
                        <h3 className="text-center">{t('battlepve.game-over')}</h3>
                    </Card>
                </>
            );
        }

        return <></>;
    };

    const renderEscape = () => {
        if (enemyEscape || battleEscape) {
            return (
                <>
                    <ActionMenu>
                        <Loading>
                            <ActionButton type="button" label={t('button.back')} color="danger" action={() => clickExit()} />
                        </Loading>
                    </ActionMenu>
                    <Card>
                        <h3 className="text-center">
                            {enemyEscape && (
                                <>
                                    {statsRed!.karacterName}
                                    {t('battlepve.enemy-escape')}
                                </>
                            )}
                            {battleEscape && (
                                <>
                                    {t('battlepve.player-escape')}
                                    {statsRed!.karacterName}
                                </>
                            )}
                        </h3>
                    </Card>
                </>
            );
        }

        return <></>;
    };

    const renderBattleScreen = () => {
        if (!enemyEscape && !battleEscape && !gameOver) {
            return (
                <>
                    <ActionMenu>{listActions(actionsBlue)}</ActionMenu>
                    <div className="d-block d-md-none">
                        <Card>
                            <Loading>
                                <MobileBattleTable myStats={statsBlue} opponent={statsRed} />
                            </Loading>
                        </Card>
                    </div>
                    <div className="d-none d-md-block">
                        {statsBlue && statsRed && (
                            <Card>
                                <div className="row mb-3">
                                    <div className="col-12 col-sm-6 text-center">
                                        <h3>
                                            <b>{statsBlue.karacterName}</b>
                                        </h3>
                                    </div>
                                    <div className="col-12 col-sm-6 text-center">
                                        <h3>
                                            <b>{statsRed.karacterName}</b>
                                        </h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <CharacterTable
                                            battleOpacity
                                            equipment={equipmentBlue}
                                            color="primary"
                                            clickHelmet={() => {}}
                                            clickMainHand={() => {}}
                                            clickArmor={() => {}}
                                            clickOffHand={() => {}}
                                            clickBelt={() => {}}
                                            clickPants={() => {}}
                                            clickFeet={() => {}}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <Loading>
                                            <BattleTable myStats={statsBlue} opponent={statsRed} />
                                        </Loading>
                                    </div>
                                    <div className="col-4">
                                        <Center>
                                            {statsRed.combatLevel === 1 && <img src={enemyLevel1} alt="Enemy" className="enemy-icon" />}
                                            {statsRed.combatLevel === 2 && <img src={enemyLevel2} alt="Enemy" className="enemy-icon" />}
                                            {statsRed.combatLevel === 3 && <img src={enemyLevel3} alt="Enemy" className="enemy-icon" />}
                                            {statsRed.combatLevel === 4 && <img src={enemyLevel4} alt="Enemy" className="enemy-icon" />}
                                            {statsRed.combatLevel === 5 && <img src={enemyLevel5} alt="Enemy" className="enemy-icon" />}
                                            {statsRed.combatLevel === 6 && <img src={enemyLevel6} alt="Enemy" className="enemy-icon" />}
                                            {statsRed.combatLevel === 7 && <img src={enemyLevel7} alt="Enemy" className="enemy-icon" />}
                                            {statsRed.combatLevel === 8 && <img src={enemyLevel8} alt="Enemy" className="enemy-icon" />}
                                            {statsRed.combatLevel === 9 && <img src={enemyLevel9} alt="Enemy" className="enemy-icon" />}
                                            {statsRed.combatLevel === 10 && <img src={enemyLevel10} alt="Enemy" className="enemy-icon" />}
                                        </Center>
                                    </div>
                                </div>
                            </Card>
                        )}
                    </div>
                </>
            );
        }

        return <></>;
    };

    return (
        <>
            {renderGameOver()}
            {renderEscape()}
            {renderBattleScreen()}
        </>
    );
};
export default BattlePve;
