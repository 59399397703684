import React, { ComponentProps, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import './VerifyAccount.css';

import apiProvider from '../../api';
import { UserContext } from '../../context/UserContext';
import { CharContext } from '../../context/CharContext';
import { ArenaContext } from '../../context/ArenaContext';
import { BattleContext } from '../../context/BattleContext';
import { StatsContext } from '../../context/StatsContext';

import Loading from '../../ui/Loading/Loading';

const logo = '\\resources\\img\\logo.svg';

interface IProps extends ComponentProps<any> {}

const Wrapper = styled.div`
    margin: 0 auto;
    display: flex;
    height: 100vh;
    flex-direction: column;
    overflow: hidden;
`;

const MainContent = styled.main`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    justify-content: center;

    position: relative;
    flex: 1;
    height: 100vh;
`;

const VerifyAccount = (props: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    // Context
    const [, setUser] = React.useContext(UserContext);
    const [, setChar] = React.useContext(CharContext);
    const [, setArena] = React.useContext(ArenaContext);
    const [, setBattle] = React.useContext(BattleContext);
    const [, setStats] = React.useContext(StatsContext);

    // Logout function
    const logout = () => {
        setUser(null);
        setChar(null);
        setArena(null);
        setBattle(null);
        setStats(null);
        navigate('/');
    };

    useEffect(() => {
        async function fetchData() {
            const query = new URLSearchParams(location.search);
            const response = await apiProvider.signupVerifyAccount(query.get('token') || '', logout);

            if (!response || response.error) {
                toast.error(response.msg);
                navigate('/login');
                return;
            }

            toast.success(response);
            navigate('/login');
        }

        fetchData();
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return (
        <Wrapper className="wrapper">
            <MainContent>
                <div style={{ minWidth: '360px', width: '100%', maxWidth: '360px' }}>
                    <div
                        style={{
                            marginBottom: '.9rem',
                            textAlign: 'center',
                        }}
                    >
                        <img src={logo} alt="CSR Logo" style={{ maxHeight: '128px' }} />
                    </div>
                    <div className="card">
                        <Loading>
                            <div className="card-body">
                                <br />
                                <br />
                                <h3 className="text-center">{t('verify-account.title')}</h3>
                                <br />
                                <br />
                            </div>
                        </Loading>
                    </div>
                </div>
            </MainContent>
        </Wrapper>
    );
};

export default VerifyAccount;
