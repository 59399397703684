import React, { ComponentProps } from 'react';
import FacebookLogin from 'react-facebook-login';
import { useTranslation } from 'react-i18next';

interface IProps extends ComponentProps<any> {
    onClick?: any;
    onFailure?: any;
}

const FacebookButton = (props: IProps) => {
    const { t } = useTranslation();

    const appId = '' + process.env.REACT_APP_FACEBOOK_APP_ID;

    return (
        <FacebookLogin
            appId={appId}
            autoLoad={false}
            disableMobileRedirect={true}
            buttonStyle={{ width: '100%' }}
            fields="name,email,picture.width(96).height(96)"
            scope="public_profile, email"
            callback={props.onClick}
            onFailure={props.onFailure}
            cssClass="btn btn-primary"
            icon="fab fa-facebook me-2"
            textButton={t('button.sign-in-facebook')}
        />
    );
};

export default FacebookButton;
