import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import './Equipment.css';

import { IBackpack, IEquipment, IEquipmentItem, IItem } from '../../interfaces/gameInterfaces';

import apiProvider from '../../api';
import { CharContext } from '../../context/CharContext';
import { StatsContext } from '../../context/StatsContext';
import { defaultEquipment } from '../../interfaces/defaultValues';

import ActionButton from '../elements/ActionButton/ActionButton';
import CharacterTable from '../elements/CharacterTable/CharacterTable';
import Card from '../../ui/Card/Card';
import Icon from '../../ui/Icon/Icon';
import Loading from '../../ui/Loading/Loading';

interface IProps {
    showModal: Function;
}

const Equipment = (props: IProps) => {
    const { t } = useTranslation();

    const [showModal, setShowModal] = React.useState<boolean>(true);
    const [refreshState, setRefreshState] = React.useState<number>(1);
    const [backpack, setBackpack] = React.useState<IBackpack>([]);
    const [equipment, setEquipment] = React.useState<IEquipment>(defaultEquipment);
    const [currentItem, setCurrentItem] = React.useState<IEquipmentItem | null>(null);
    const [replacementItem, setReplacementItem] = React.useState<IEquipmentItem | null>(null);
    const [activeFilter, setActiveFilter] = React.useState<string>('');
    const [filteredItems, setFilteredItems] = React.useState<any[]>([]);

    // Context
    const [char] = React.useContext(CharContext);
    const [, setStats] = React.useContext(StatsContext);

    // Logout function
    const logout = async () => {
        await clickClose();
    };

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    useEffect(() => {
        async function fetchData() {
            setCurrentItem(null);
            setReplacementItem(null);
            setActiveFilter('');
            setFilteredItems([]);

            const [responseBackpack, responseEquipment, responseStats] = await Promise.all([
                apiProvider.backpackDownloadBackpack(char.jwt, logout),
                apiProvider.backpackDownloadEquipment(char.jwt, logout),
                apiProvider.statsGetKaracterStats(char.jwt, logout),
            ]);

            if (!responseBackpack || responseBackpack.error) return;
            if (!responseEquipment || responseEquipment.error) return;
            if (!responseStats || responseStats.error) return;

            setBackpack(responseBackpack);
            setEquipment(responseEquipment);
            setStats(responseStats);
        }

        fetchData();
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshState]);

    useEffect(() => {
        const response = listReplacements();
        setFilteredItems(response);
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFilter]);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------

    const clickClose = async () => {
        setShowModal(false);
        props.showModal(false);
    };

    const setCurrentEquipmentWithFilter = async (equipmentType: string) => {
        setActiveFilter(equipmentType);
        setReplacementItem(null);
    };

    const clickHelmet = async () => {
        setCurrentItem(equipment.helmet);
        await setCurrentEquipmentWithFilter('helmet');
    };

    const clickMainHand = async () => {
        setCurrentItem(equipment.mainHand);
        await setCurrentEquipmentWithFilter('mainHand');
    };

    const clickArmor = async () => {
        setCurrentItem(equipment.armor);
        await setCurrentEquipmentWithFilter('armor');
    };

    const clickOffHand = async () => {
        setCurrentItem(equipment.offHand);
        await setCurrentEquipmentWithFilter('offHand');
    };

    const clickBelt = async () => {
        setCurrentItem(equipment.belt);
        await setCurrentEquipmentWithFilter('belt');
    };

    const clickPants = async () => {
        setCurrentItem(equipment.pants);
        await setCurrentEquipmentWithFilter('pants');
    };

    const clickFeet = async () => {
        setCurrentItem(equipment.feet);
        await setCurrentEquipmentWithFilter('feet');
    };

    const clickReplacement = async (data: IEquipmentItem) => {
        setReplacementItem(data);
    };

    const clickEquip = async () => {
        if (isNotEmptyItem() && replacementItem && currentItem) {
            await apiProvider.equipmentSwitchEquipment(char.jwt, currentItem.id, replacementItem.id, logout);
        } else if (!isNotEmptyItem() && currentItem && replacementItem) {
            await apiProvider.equipmentEquip(char.jwt, replacementItem.id, logout);
        }

        setRefreshState(refreshState + 1);
    };

    const clickDequip = async () => {
        if (currentItem) {
            const response = await apiProvider.equipmentDequip(char.jwt, currentItem.id, logout);

            if (!response || response.error) return;
            // todo toast
        }

        setRefreshState(refreshState + 1);
    };

    // --------------------------------------------
    // UI Render Util Dynamic Generation Methods
    // --------------------------------------------

    const isNotEmptyItem = () =>
        currentItem &&
        currentItem.id !== 9001 &&
        currentItem.id !== 9002 &&
        currentItem.id !== 9003 &&
        currentItem.id !== 9004 &&
        currentItem.id !== 9005 &&
        currentItem.id !== 9006 &&
        currentItem.id !== 9007;

    const listReplacements = () => {
        return backpack
            ? backpack
                  .filter(
                      (x) =>
                          (x as IEquipmentItem) && (x as IEquipmentItem).equipmentType !== null && (x as IEquipmentItem).equipmentType.toUpperCase() === activeFilter.toUpperCase()
                  )
                  .map(function (data: IItem | IEquipmentItem) {
                      return (
                          <div key={uuidv4()} className="col-6 col-sm-4 col-md-3 text-center">
                              <span onClick={() => clickReplacement(data as IEquipmentItem)}>
                                  <Icon name={data.image} rarity={data.itemRarity} animateRaise />
                                  <span className="d-none d-sm-block">{data.name}</span>
                              </span>
                          </div>
                      );
                  })
            : [];
    };

    const dynamicModalClass = () => (showModal ? { display: 'block' } : { display: 'none' });

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return showModal ? (
        <div id="modal fade" tabIndex={-1} role="dialog" className={`modal ${showModal ? 'show' : 'hide'}`} style={dynamicModalClass()}>
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-6">
                                <Card>
                                    <CharacterTable
                                        color="primary"
                                        equipment={equipment}
                                        clickHelmet={clickHelmet}
                                        clickArmor={clickArmor}
                                        clickMainHand={clickMainHand}
                                        clickOffHand={clickOffHand}
                                        clickBelt={clickBelt}
                                        clickPants={clickPants}
                                        clickFeet={clickFeet}
                                    />
                                </Card>
                            </div>
                            <div className="col-6">
                                {currentItem && filteredItems && filteredItems.length > 0 && (
                                    <Card>
                                        <div className="row">{filteredItems}</div>
                                    </Card>
                                )}
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-12">
                                {currentItem && replacementItem && (
                                    <Card>
                                        <div className="table-responsive">
                                            <table className="table table-sm table-borderless text-light">
                                                <thead>
                                                    <tr>
                                                        <th className="text-end">{t('common.attribute')}</th>
                                                        <th className="text-center">{t('common.current')}</th>
                                                        <th className="text-center">{t('common.new')}</th>
                                                        <th className="text-center">{t('common.difference')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="text-end">{t('common.health')}</td>
                                                        <td className="text-center">{currentItem.health}</td>
                                                        <td className="text-center">{replacementItem.health}</td>
                                                        <td className="text-center">
                                                            <span className={`fw-bold ${replacementItem.health > currentItem.health ? 'text-success' : 'text-danger'}`}>
                                                                {replacementItem.health > currentItem.health && <span>+</span>}
                                                                {replacementItem.health - currentItem.health}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-end">{t('common.damage')}</td>
                                                        <td className="text-center">{currentItem.damage}</td>
                                                        <td className="text-center">{replacementItem.damage}</td>
                                                        <td className="text-center">
                                                            <span className={`fw-bold ${replacementItem.damage > currentItem.damage ? 'text-success' : 'text-danger'}`}>
                                                                {replacementItem.damage > currentItem.damage && <span>+</span>}
                                                                {replacementItem.damage - currentItem.damage}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-end">{t('common.spell')}</td>
                                                        <td className="text-center">{currentItem.spell}</td>
                                                        <td className="text-center">{replacementItem.spell}</td>
                                                        <td className="text-center">
                                                            <span className={`fw-bold ${replacementItem.spell > currentItem.spell ? 'text-success' : 'text-danger'}`}>
                                                                {replacementItem.spell > currentItem.spell && <span>+</span>}
                                                                {replacementItem.spell - currentItem.spell}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-end">{t('common.defense')}</td>
                                                        <td className="text-center">{currentItem.defense}</td>
                                                        <td className="text-center">{replacementItem.defense}</td>
                                                        <td className="text-center">
                                                            <span className={`fw-bold ${replacementItem.defense > currentItem.defense ? 'text-success' : 'text-danger'}`}>
                                                                {replacementItem.defense > currentItem.defense && <span>+</span>}
                                                                {replacementItem.defense - currentItem.defense}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-end">{t('common.agility')}</td>
                                                        <td className="text-center">{currentItem.agility}</td>
                                                        <td className="text-center">{replacementItem.agility}</td>
                                                        <td className="text-center">
                                                            <span className={`fw-bold ${replacementItem.agility > currentItem.agility ? 'text-success' : 'text-danger'}`}>
                                                                {replacementItem.agility > currentItem.agility && <span>+</span>}
                                                                {replacementItem.agility - currentItem.agility}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Card>
                                )}
                            </div>
                        </div>
                    </div>
                    <Loading>
                        <div className="modal-footer">
                            <ActionButton type="button" label={t('button.close')} color="danger" action={clickClose} />
                            {replacementItem && <ActionButton type="button" label={t('button.equip')} color="success" action={clickEquip} />}
                            {!replacementItem && <ActionButton isEmpty={true} />}
                            {isNotEmptyItem() && <ActionButton type="button" label={t('button.dequip')} color="danger" action={clickDequip} />}
                            {!isNotEmptyItem() && <ActionButton isEmpty={true} />}
                        </div>
                    </Loading>
                </div>
            </div>
        </div>
    ) : null;
};

export default Equipment;
