import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import './Statistics.css';

import { IEquipment, IExperience } from '../../interfaces/gameInterfaces';

import apiProvider from '../../api';
import { CharContext } from '../../context/CharContext';
import { StatsContext } from '../../context/StatsContext';
import { defaultEquipment, defaultExperience } from '../../interfaces/defaultValues';

import CharacterTable from '../elements/CharacterTable/CharacterTable';
import CharacterStats from '../elements/CharacterStats/CharacterStats';
import ExperienceStats from '../elements/ExperienceStats/ExperienceStats';
import ActionButton from '../elements/ActionButton/ActionButton';
import Card from '../../ui/Card/Card';
import Loading from '../../ui/Loading/Loading';

interface IProps {
    showModal: Function;
}

const Statistics = (props: IProps) => {
    const { t } = useTranslation();

    const [showModal, setShowModal] = React.useState<boolean>(true);
    const [equipment, setEquipment] = React.useState<IEquipment>(defaultEquipment);
    const [experience, setExperience] = React.useState<IExperience[]>([defaultExperience, defaultExperience, defaultExperience, defaultExperience]);

    // Context
    const [char] = React.useContext(CharContext);
    const [stats, setStats] = React.useContext(StatsContext);

    // Logout function
    const logout = async () => {
        await clickClose();
    };

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    useEffect(() => {
        async function fetchData() {
            setExperience([defaultExperience, defaultExperience, defaultExperience, defaultExperience]);
            setEquipment(defaultEquipment);

            const [responseEquipment, responseStats, responseExperience] = await Promise.all([
                apiProvider.backpackDownloadEquipment(char.jwt, logout),
                apiProvider.statsGetKaracterStats(char.jwt, logout),
                apiProvider.statsGetKaracterExperience(char.jwt, logout),
            ]);

            if (!responseEquipment || responseEquipment.error) return;
            if (!responseStats || responseStats.error) return;
            if (!responseExperience || responseExperience.error) return;

            setEquipment(responseEquipment);
            setStats(responseStats);
            setExperience(responseExperience);
        }

        fetchData();
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------

    const clickClose = async () => {
        setShowModal(false);
        props.showModal(false);
    };

    // --------------------------------------------
    // UI Render Util Dynamic Generation Methods
    // --------------------------------------------

    const dynamicModalClass = () => (showModal ? { display: 'block' } : { display: 'none' });

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return showModal ? (
        <div id="modal fade" tabIndex={-1} role="dialog" className={`modal ${showModal ? 'show' : 'hide'}`} style={dynamicModalClass()}>
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <Card>
                                    <CharacterTable
                                        equipment={equipment}
                                        color="primary"
                                        clickHelmet={() => {}}
                                        clickMainHand={() => {}}
                                        clickArmor={() => {}}
                                        clickOffHand={() => {}}
                                        clickBelt={() => {}}
                                        clickPants={() => {}}
                                        clickFeet={() => {}}
                                    />
                                </Card>
                            </div>
                            <div className="col-12 col-sm-6">
                                <Card>
                                    <CharacterStats stats={stats} />
                                    <ExperienceStats experience={experience} />
                                </Card>
                            </div>
                        </div>
                    </div>
                    <Loading>
                        <div className="modal-footer">
                            <ActionButton type="button" label={t('button.close')} color="danger" action={clickClose} />
                            <ActionButton isEmpty={true} />
                            <ActionButton isEmpty={true} />
                        </div>
                    </Loading>
                </div>
            </div>
        </div>
    ) : null;
};

export default Statistics;
