import React from 'react';
import { ICharacter } from '../interfaces/gameInterfaces';

export const StatsContext = React.createContext<any>(null);

interface IProps {
    children: React.ReactNode;
}

const StatsContextProvider: React.FC<IProps> = (props: IProps) => {
    const [stats, setStats] = React.useState<ICharacter | null>(null);

    return <StatsContext.Provider value={[stats, setStats]}>{props.children}</StatsContext.Provider>;
};

export default StatsContextProvider;
