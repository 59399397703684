import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import './Login.css';

import apiProvider from '../../api';
import { UserContext } from '../../context/UserContext';
import { CharContext } from '../../context/CharContext';
import { ArenaContext } from '../../context/ArenaContext';
import { BattleContext } from '../../context/BattleContext';
import { StatsContext } from '../../context/StatsContext';
import { EMAIL_REGEX } from '../../utils/utils';

import Input from '../../components/elements/Input/Input';
import FacebookButton from '../../components/elements/FacebookButton/FacebookButton';
import GoogleButton from '../../components/elements/GoogleButton/GoogleButton';
import Loading from '../../ui/Loading/Loading';

const logo = '\\resources\\img\\logo.svg';

interface IProps {}

const Wrapper = styled.div`
    margin: 0 auto;
    display: flex;
    height: 100vh;
    flex-direction: column;
    overflow: hidden;
`;

const MainContent = styled.main`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    justify-content: center;

    position: relative;
    flex: 1;
    height: 100vh;
`;

const Login = (props: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [rememberMe, setRememberMe] = React.useState<number>(0);

    // Context
    const [, setUser] = React.useContext(UserContext);
    const [, setChar] = React.useContext(CharContext);
    const [, setArena] = React.useContext(ArenaContext);
    const [, setBattle] = React.useContext(BattleContext);
    const [, setStats] = React.useContext(StatsContext);

    // Logout function
    const logout = () => {
        setUser(null);
        setChar(null);
        setArena(null);
        setBattle(null);
        setStats(null);
        navigate('/');
    };

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    useEffect(() => {
        setUser(null);

        if (localStorage.getItem('USER_TOKEN') !== null) {
            const token = JSON.parse(localStorage.getItem('USER_TOKEN')!);
            const currentTime = new Date().getTime() / 1000;

            if (token.expireAt < currentTime) {
                localStorage.removeItem('USER_TOKEN');
            } else {
                setUser(token);
                navigate('/');
            }
        }

        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------

    const loginUser = (response: any) => {
        if (!response || response.error) {
            toast.error(response.msg);
            return;
        }

        setUser(response);

        if (rememberMe) {
            localStorage.setItem('USER_TOKEN', JSON.stringify(response));
        }

        navigate('/');
    };

    const onFailure = async (error: any) => {
        console.error(error);
    };

    const clickGoogleLogin = async (glgResponse: any) => {
        if (glgResponse === undefined || glgResponse === null) {
            return;
        }

        const glgProfileResponse = await apiProvider.googleApiProfile(glgResponse.access_token);

        const response = await apiProvider.userGoogleLogin(
            glgProfileResponse.sub,
            glgProfileResponse.email,
            glgResponse.access_token,
            new Date().getTime() + glgResponse.expires_in,
            glgProfileResponse.given_name,
            glgProfileResponse.family_name,
            glgProfileResponse.picture,
            () => {
                navigate('/');
            }
        );

        loginUser(response);
    };

    const clickFacebookLogin = async (fbResponse: any) => {
        if (fbResponse && fbResponse.status && fbResponse.status === 'unknown') {
            return;
        }

        const response = await apiProvider.userFacebookLogin(
            fbResponse.userID,
            fbResponse.email,
            fbResponse.accessToken,
            fbResponse.data_access_expiration_time,
            fbResponse.name,
            fbResponse.picture.data.url,
            () => {
                navigate('/');
            }
        );

        loginUser(response);
    };

    const clickLogin = async () => {
        const response = await apiProvider.userLogin(email, password, logout);

        loginUser(response);
    };

    const clickForgotPassword = async () => {
        navigate('/forgot-my-password');
    };

    const clickRegister = async () => {
        navigate('/signup');
    };

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return (
        <Wrapper className="wrapper">
            <MainContent>
                <div style={{ minWidth: '360px', width: '100%', maxWidth: '360px' }}>
                    <div
                        style={{
                            marginBottom: '.9rem',
                            textAlign: 'center',
                        }}
                    >
                        <img src={logo} alt="CSR Logo" style={{ maxHeight: '128px' }} />
                    </div>
                    <div className="card">
                        <Loading>
                            <div className="card-body">
                                <form>
                                    <p className="text-center">{t('login.title')}</p>
                                    <Input
                                        id={'email'}
                                        type={'email'}
                                        name={'email'}
                                        icon={'fas fa-envelope'}
                                        value={email}
                                        placeholder={'user@example.com'}
                                        onChange={(e: any) => setEmail(e.target.value)}
                                        isInvalid={!EMAIL_REGEX.test(email)}
                                        isInvalidHelp={t('input.valid-email')}
                                        required={true}
                                        autocomplete={'email'}
                                    />
                                    <Input
                                        id={'password'}
                                        type={'password'}
                                        name={'password'}
                                        icon={'fas fa-lock'}
                                        value={password}
                                        placeholder={'••••••••'}
                                        onChange={(e: any) => setPassword(e.target.value)}
                                        isInvalid={password.length < 6}
                                        isInvalidHelp={t('input.valid-password')}
                                        required={true}
                                        autocomplete={'current-password'}
                                    />
                                    <div className="row">
                                        <div className="col-8">
                                            <div className="form-check cursor-pointer">
                                                <input
                                                    className="form-check-input cursor-pointer"
                                                    type="checkbox"
                                                    id="remember"
                                                    value={rememberMe}
                                                    onClick={() => {
                                                        setRememberMe(rememberMe === 0 ? 1 : 0);
                                                    }}
                                                />
                                                <label className="form-check-label cursor-pointer" htmlFor="remember">
                                                    {t('login.remember-me')}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="d-grid gap-2">
                                                <button type="button" className="btn btn-primary" onClick={clickLogin} disabled={!EMAIL_REGEX.test(email) || password.length < 5}>
                                                    {t('button.sign-in-web')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="social-auth-links text-center">
                                        <p>-- {t('common.or').toUpperCase()} --</p>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col">
                                            <FacebookButton onClick={clickFacebookLogin} onFailure={onFailure} />
                                        </div>
                                        <div className="col">
                                            <GoogleButton onClick={clickGoogleLogin} onFailure={onFailure} />
                                        </div>
                                    </div>
                                    <p className="mb-1">
                                        <span className="text-primary cursor-pointer" onClick={clickForgotPassword}>
                                            {t('login.forgot-password')}
                                        </span>
                                    </p>
                                    <p className="mb-0">
                                        <span className="text-primary cursor-pointer" onClick={clickRegister}>
                                            {t('common.register')}
                                        </span>
                                    </p>
                                </form>
                            </div>
                        </Loading>
                    </div>
                </div>
            </MainContent>
        </Wrapper>
    );
};

export default Login;
