import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ActionMenu from '../../components/elements/ActionMenu/ActionMenu';
import ActionButton from '../../components/elements/ActionButton/ActionButton';
import Card from '../../ui/Card/Card';
import Loading from '../../ui/Loading/Loading';

import apiProvider from '../../api';
import { CharContext } from '../../context/CharContext';
import { StatsContext } from '../../context/StatsContext';

interface IProps {}

const Camp = (props: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [msg, setMsg] = React.useState<string>('');

    // Context
    const [char, setChar] = React.useContext(CharContext);
    const [, setStats] = React.useContext(StatsContext);

    // Logout function
    const logout = () => {
        setChar(null);
        setStats(null);
        navigate('/');
    };

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    useEffect(() => {
        async function fetchData() {
            const [responseIsOnField, responseCamp] = await Promise.all([apiProvider.campIsKaracterOnCamp(char.jwt, logout), apiProvider.campGetCamp(char.jwt, logout)]);

            if (!responseIsOnField || responseIsOnField.error) return;
            if (!responseCamp || responseCamp.error) return;

            if (responseCamp.robbed) {
                setMsg(t('search-field.trash-panda-stole') + ' ' + responseCamp.robbed + ' ' + t('common.money'));
            } else {
                setMsg(t(responseCamp.msg));
            }

            const responseStats = await apiProvider.statsGetKaracterStats(char.jwt, logout);
            if (!responseStats || responseStats.error) return;

            setStats(responseStats);
        }

        fetchData();
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------

    const clickBack = () => {
        navigate('/map');
    };

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return (
        <>
            <ActionMenu>
                <Loading>
                    <ActionButton type="button" label={t('button.back')} color="danger" action={clickBack} />
                </Loading>
            </ActionMenu>
            <Card>
                <h3 className="text-center">{msg}</h3>
            </Card>
        </>
    );
};

export default Camp;
