import React, { ComponentProps } from 'react';
import { v4 as uuidv4 } from 'uuid';

import './CraftResult.css';

import { IItem } from '../../../interfaces/gameInterfaces';

import Icon from '../../../ui/Icon/Icon';

interface IProps extends ComponentProps<any> {
    item: IItem;
    onClick: Function;
    onDoubleClick: Function;
}

const CraftResult = (props: IProps) => {
    const { item, onClick, onDoubleClick } = props;

    return (
        <div key={uuidv4()} className="col-12">
            <span onDoubleClick={() => onDoubleClick()} onClick={() => onClick()}>
                <Icon name={item.image} rarity={item.itemRarity} />
            </span>
        </div>
    );
};

export default CraftResult;
