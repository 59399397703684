import styled from '@emotion/styled';
import { colors } from '../constants';

const Bordered = styled.div`
    position: relative;
    background-color: ${colors.bg};
    color: ${colors.text};
    border-radius: 10px;

    &::after {
        position: absolute;
        content: '';
        display: block;
        left: 4px;
        right: 4px;
        top: 4px;
        bottom: 4px;
        border: 2px solid ${colors.border};
        border-radius: 8px;
        pointer-events: none;
    }
`;

export default Bordered;
