import React, { ComponentProps } from 'react';

import './NewsEntry.css';
import { INews } from '../../../interfaces/gameInterfaces';
import Icon from '../../../ui/Icon/Icon';

interface IProps extends ComponentProps<any> {
    data: INews;
}

const NewsEntry = (props: IProps) => {
    const { title, text, image, type } = props.data;
    return (
        <div className={`col-11 news-border ${type.toLowerCase()}`}>
            <div className="d-flex align-items-center">
                <span className="icon me-2 mb-2">
                    <Icon name={image} />
                </span>
                <h2>{title}</h2>
            </div>
            <div dangerouslySetInnerHTML={{ __html: text }} />
        </div>
    );
};

export default NewsEntry;
