import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import './HighLow.css';

import Input from '../../components/elements/Input/Input';
import ActionMenu from '../../components/elements/ActionMenu/ActionMenu';
import ActionButton from '../../components/elements/ActionButton/ActionButton';
import Card from '../../ui/Card/Card';
import Loading from '../../ui/Loading/Loading';

import apiProvider from '../../api';
import { CharContext } from '../../context/CharContext';
import { StatsContext } from '../../context/StatsContext';
import { defaultHighLowCard } from '../../interfaces/defaultValues';

import { IHighLowCard } from '../../interfaces/gameInterfaces';

interface IProps {}

const HighLow = (props: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [canBet, setCanBet] = React.useState<boolean>(true);
    const [canSeeCards, setCanSeeCards] = React.useState<boolean>(false);
    const [canPlay, setCanPlay] = React.useState<boolean>(false);
    const [canCollect, setCanCollect] = React.useState<boolean>(false);
    const [bet, setBet] = React.useState<number>(10);
    const [totalBonus, setTotalBonus] = React.useState<number>(0);
    const [myAmount, setMyAmount] = React.useState<number | null>(null);
    const [dealtCard, setDealtCard] = React.useState<IHighLowCard>(defaultHighLowCard);
    const [newCard, setNewCard] = React.useState<IHighLowCard>(defaultHighLowCard);

    // Context
    const [char, setChar] = React.useContext(CharContext);
    const [, setStats] = React.useContext(StatsContext);

    // Logout function
    const logout = () => {
        setChar(null);
        setStats(null);
        navigate('/');
    };

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    useEffect(() => {
        async function fetchData() {
            setCanBet(true);
            setCanSeeCards(false);
            setCanPlay(false);
            setCanCollect(false);
            setBet(10);
            setTotalBonus(0);
            setMyAmount(null);
            setDealtCard(defaultHighLowCard);

            const [responseMarbles, responseStats] = await Promise.all([
                apiProvider.backpackGetAmountOfGlassMarbles(char.jwt, logout),
                apiProvider.statsGetKaracterStats(char.jwt, logout),
            ]);

            if (!responseMarbles || responseMarbles.error) return;
            if (!responseStats || responseStats.error) return;

            setStats(responseStats);
            setMyAmount(responseMarbles.glassMarbles);
        }

        fetchData();
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------

    const clickBack = () => {
        navigate('/gamble');
    };

    const clickBet = async () => {
        const response = await apiProvider.gambleMakeBet(char.jwt, bet, logout);
        if (!response || response.error) return;

        const responseStats = await apiProvider.statsGetKaracterStats(char.jwt, logout);
        if (!responseStats || responseStats.error) return;

        setStats(responseStats);

        setCanBet(false);
        setCanSeeCards(true);
        setCanPlay(true);
        setDealtCard(response);
    };

    const clickPredict = async (prediction: number) => {
        const response = await apiProvider.gambleMakePrediction(char.jwt, prediction, logout);

        if (!response || response.error) return;

        if (response.totalBonus) {
            setDealtCard(response.newCard);
            setTotalBonus(response.totalBonus);
            setCanCollect(true);
            toast.success(t(response.msg));
        } else {
            setCanCollect(false);
            setCanPlay(false);
            setNewCard(response.newCard);
            toast.warn(t(response.msg));
        }
    };

    const clickCollect = async () => {
        const response = await apiProvider.gambleCollectPayout(char.jwt, logout);
        if (!response || response.error) return;

        const responseStats = await apiProvider.statsGetKaracterStats(char.jwt, logout);
        if (!response || response.error) return;

        setStats(responseStats);

        toast.success(t(response.win));
        navigate('/gamble');
    };

    // --------------------------------------------
    // UI Render Util Dynamic Generation Methods
    // --------------------------------------------

    const getCardBar = () => {
        switch (dealtCard.name.toString()) {
            case '2':
                return (
                    <div>
                        <p className="text-center">
                            <kbd className="lead fw-bold">2</kbd>-3-4-5-6-7-8-9-10-J-Q-K-A
                        </p>
                    </div>
                );
            case '3':
                return (
                    <div>
                        <p className="text-center">
                            2-
                            <kbd className="lead fw-bold">3</kbd>-4-5-6-7-8-9-10-J-Q-K-A
                        </p>
                    </div>
                );
            case '4':
                return (
                    <div>
                        <p className="text-center">
                            2-3-
                            <kbd className="lead fw-bold">4</kbd>-5-6-7-8-9-10-J-Q-K-A
                        </p>
                    </div>
                );
            case '5':
                return (
                    <div>
                        <p className="text-center">
                            2-3-4-
                            <kbd className="lead fw-bold">5</kbd>-6-7-8-9-10-J-Q-K-A
                        </p>
                    </div>
                );
            case '6':
                return (
                    <div>
                        <p className="text-center">
                            2-3-4-5-
                            <kbd className="lead fw-bold">6</kbd>-7-8-9-10-J-Q-K-A
                        </p>
                    </div>
                );
            case '7':
                return (
                    <div>
                        <p className="text-center">
                            2-3-4-5-6-
                            <kbd className="lead fw-bold">7</kbd>-8-9-10-J-Q-K-A
                        </p>
                    </div>
                );
            case '8':
                return (
                    <div>
                        <p className="text-center">
                            2-3-4-5-6-7-
                            <kbd className="lead fw-bold">8</kbd>-9-10-J-Q-K-A
                        </p>
                    </div>
                );
            case '9':
                return (
                    <div>
                        <p className="text-center">
                            2-3-4-5-6-7-8-
                            <kbd className="lead fw-bold">9</kbd>-10-J-Q-K-A
                        </p>
                    </div>
                );
            case '10':
                return (
                    <div>
                        <p className="text-center">
                            2-3-4-5-6-7-8-9-
                            <kbd className="lead fw-bold">10</kbd>-J-Q-K-A
                        </p>
                    </div>
                );
            case 'Jack':
                return (
                    <div>
                        <p className="text-center">
                            2-3-4-5-6-7-8-9-10-
                            <kbd className="lead fw-bold">J</kbd>-Q-K-A
                        </p>
                    </div>
                );
            case 'Queen':
                return (
                    <div>
                        <p className="text-center">
                            2-3-4-5-6-7-8-9-10-J-
                            <kbd className="lead fw-bold">Q</kbd>-K-A
                        </p>
                    </div>
                );
            case 'King':
                return (
                    <div>
                        <p className="text-center">
                            2-3-4-5-6-7-8-9-10-J-Q-
                            <kbd className="lead fw-bold">K</kbd>-A
                        </p>
                    </div>
                );
            case 'Ace':
                return (
                    <div>
                        <p className="text-center">
                            2-3-4-5-6-7-8-9-10-J-Q-K-
                            <kbd className="lead fw-bold">A</kbd>
                        </p>
                    </div>
                );
            default:
                return (
                    <div>
                        <p className="text-center">{t('common.loading')}</p>
                    </div>
                );
        }
    };

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return (
        <>
            {canPlay && (
                <ActionMenu>
                    <Loading>
                        <ActionButton type="button" label={t('button.lower')} color="danger" action={() => clickPredict(1)} />
                        {canCollect && <ActionButton type="button" label={`${t('high-low.collect')} ${totalBonus}`} color="success" action={() => clickCollect()} />}
                        {!canCollect && <ActionButton isEmpty={true} />}
                        <ActionButton type="button" label={t('button.higher')} color="primary" action={() => clickPredict(2)} />
                    </Loading>
                </ActionMenu>
            )}
            {!canPlay && !canBet && (
                <ActionMenu>
                    <Loading>
                        <ActionButton type="button" label={t('button.back')} color="danger" action={clickBack} />
                    </Loading>
                </ActionMenu>
            )}
            {myAmount && canBet && (
                <>
                    <ActionMenu>
                        <Loading>
                            <ActionButton type="button" label={t('button.back')} color="danger" action={clickBack} />
                            <ActionButton type="button" label={t('button.bet')} color="primary" action={() => clickBet()} disabled={bet < 10} />
                        </Loading>
                    </ActionMenu>
                    <Card>
                        <div className={`mb-3 row offset-sm-4`}>
                            <div className="col-sm-6">
                                <Input
                                    id={'bet'}
                                    type={'number'}
                                    name={'bet'}
                                    value={bet}
                                    placeholder={10}
                                    onChange={(e: any) => setBet(e.target.value)}
                                    isInvalid={bet < 10}
                                    isInvalidHelp={t('input.valid-bet')}
                                    required={true}
                                />
                            </div>
                        </div>
                        <p className="text-center">
                            {t('common.i-have')}&nbsp;
                            <span className="lead fw-bold">{myAmount}</span>&nbsp;{t('common.money')}.
                        </p>
                    </Card>
                    <Card>
                        <h3 className="text-center">{t('high-low.welcome')}</h3>
                        <div className="row">
                            <div className="col col-md-6 offset-md-3">
                                <p>{t('high-low.game-rules')}</p>
                                <p>{t('high-low.enter-bet')}</p>
                                <p>{t('high-low.get-card')}</p>
                                <p>{t('high-low.decide')}</p>
                                <p>4. ....?</p>
                                <p>{t('high-low.repeat')}</p>
                                <p>{t('high-low.note-1')}</p>
                                <p>{t('high-low.note-2')}</p>
                                <p>{t('high-low.note-3')}</p>
                            </div>
                        </div>
                    </Card>
                </>
            )}
            {canSeeCards && dealtCard && newCard && (
                <>
                    <div className="d-block d-md-none">
                        <Card>
                            <div className="row">
                                <div className="col text-center">{getCardBar()}</div>
                            </div>
                        </Card>
                    </div>
                    <div className="row">
                        <div className="col-6 col-md-4">
                            <Card>
                                <p className="text-center">
                                    <img
                                        src={dealtCard.image}
                                        alt=""
                                        className="img-thumbnail"
                                        style={{
                                            maxWidth: '128px',
                                            width: '100%',
                                            height: 'auto',
                                        }}
                                    />
                                </p>
                                <p className="text-center fw-bold">{dealtCard.name}</p>
                            </Card>
                        </div>
                        <div className="col-4 d-none d-md-block">
                            <Card>
                                <div className="row">
                                    <div className="col text-center">{getCardBar()}</div>
                                </div>
                            </Card>
                        </div>
                        <div className="col-6 col-md-4">
                            <Card>
                                <p className="text-center">
                                    <img
                                        src={newCard.image}
                                        alt=""
                                        className="img-thumbnail"
                                        style={{
                                            maxWidth: '128px',
                                            width: '100%',
                                            height: 'auto',
                                        }}
                                    />
                                </p>
                                <p className="text-center  fw-bold">{newCard.name}</p>
                            </Card>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default HighLow;
