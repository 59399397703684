import React, { ComponentProps } from 'react';

interface IProps extends ComponentProps<any> {
    type?: 'button' | 'submit' | 'reset';
    color?: 'primary' | 'success' | 'danger' | 'warning' | 'secondary';
    label?: string;
    action?: any;
    disabled?: boolean;
}

const Button = (props: IProps) => {
    return (
        <div className="d-grid gap-2">
            <button onClick={props.action} type={props.type} className={`fw-bold btn btn-${props.color}`} disabled={props.disabled || false}>
                {props.label}
            </button>
        </div>
    );
};

export default Button;
