import React, { ComponentProps } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { colors } from '../constants';

import Icon from '../Icon/Icon';

export interface IAliveCharacter {
    id: number;
    name: string;
    race: string;
    clazz: string;
    totalLevel: number;
    totalTurns: number;
    raceImage: string;
}

export interface IFallenCharacter extends IAliveCharacter {
    score: number;
}

interface IProps extends ComponentProps<any> {
    char: IAliveCharacter | IFallenCharacter;
    onClick?: (char: IAliveCharacter | IFallenCharacter) => any;
}

const Section = styled.button`
    background: transparent;
    border: transparent;
    padding: 0.4rem;
    padding-right: 0.8rem;
    margin: 0 0 0.6rem;
    color: inherit;
    border-radius: 14px;
    display: flex;
    transition: background 0.2s;

    &:hover,
    &:focus {
        background: #0003;
        outline: none;
    }
`;

const Stats = styled.div`
    display: flex;
    flex-wrap: wrap;

    > div {
        height: 30px;
        line-height: 30px;
        width: 50%;
        display: flex;
        padding: 0 0 0 0.8rem;
        font-size: 0.9rem;

        &:nth-of-child(1) {
            width: 100%;
        }

        > h6 {
            margin: 0;
            padding: 0;
            color: ${colors.highlight};
            font-size: inherit;
            font-weight: 400;
        }

        > span {
            font-weight: 600;
            line-height: 1.3;
        }
    }
`;

const Name = styled.h5`
    margin: 0;
    height: 40px;
    font-size: 1.2rem;
    width: 100%;
    text-align: left;
    padding: 0 0.8rem;
    font-weight: 700;
    text-shadow: 0.12em 0.12em 0 ${colors.darker};
`;

const Dots = styled.i`
    flex: 1;
    border-bottom: 1px dashed ${colors.primary};
    display: inline-block;
    transform: translateY(-54%);
    pointer-events: none;
    margin: 0 0.3rem;
    opacity: 0.4;
    line-height: 30px;
`;

export default function Character({ char, onClick, ...props }: IProps) {
    const { t } = useTranslation();

    return (
        <Section {...props} onClick={onClick ? () => onClick(char) : () => {}}>
            <Icon name={char.raceImage} home />
            <Stats>
                <Name>{char.name}</Name>
                <div>
                    <h6>{t('common.race')}</h6>
                    <Dots />
                    <span>{char.race}</span>
                </div>
                <div>
                    <h6>{t('common.class')}</h6>
                    <Dots />
                    <span>{char.clazz}</span>
                </div>
                <div>
                    <h6>{t('common.level')}</h6>
                    <Dots />
                    <span>{char.totalLevel}</span>
                </div>
                <div>
                    <h6>{t('bank.turns')}</h6>
                    <Dots />
                    <span>{char.totalTurns}</span>
                </div>
            </Stats>
        </Section>
    );
}
