import React, { ComponentProps } from 'react';
import styled from '@emotion/styled';
import { colors } from '../constants';

interface IProps extends ComponentProps<any> {
    children?: any;
}

type StyledButtonProps = {
    disabled?: boolean;
};

const StyledButton = styled.button<StyledButtonProps>`
    background-color: ${(props: StyledButtonProps) => (props.disabled ? `${colors.disabled}` : `${colors.action}`)};
    color: ${colors.black};
    font-size: 1.2rem;
    padding: 0.65rem 1.4rem 0.75rem;
    border: none;
    border-radius: 8px;
    text-align: center;
    font-weight: 600;
    letter-spacing: -0.01em;
    display: block;
`;

export default function Button({ children, ...props }: IProps) {
    return <StyledButton {...props}>{children}</StyledButton>;
}
