import React, { ComponentProps } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled from '@emotion/styled';

import { colors } from '../../../ui/constants';

interface IProps extends ComponentProps<any> {
    icon: string;
    title: string;
}

const Dropdown = styled.ul`
    display: block;
    padding: 0.5rem 1rem;
    line-height: 1.5;
    margin: 0 0 3.2px 3.2px;
    font-weight: 700;
    color: #0d6efd;
`;

const IconWithTitle = styled.span`
    color: #0d6efd;

    &:hover,
    &:focus {
        color: ${colors.primary};
    }
`;

const Icon = styled.i`
    font-size: 1.1rem;
    margin-left: 0.05rem;
    margin-right: 0.2rem;
    width: 1.6rem;
`;

const SidebarDropdown = (props: IProps) => {
    const { icon, title } = props;

    const menuId = uuidv4();

    return (
        <Dropdown className="dropdown-menu show">
            <IconWithTitle className="dropdown-toggle" role="button" id={menuId} data-bs-toggle="dropdown" aria-expanded="false">
                <Icon className={`${icon} dropdown-icon`} />
                {title}
            </IconWithTitle>

            <div className="dropdown-menu" aria-labelledby={menuId}>
                {props.children}
            </div>
        </Dropdown>
    );
};

export default SidebarDropdown;
