import React, { ComponentProps } from 'react';

import './ItemList.css';

import { IItem } from '../../../interfaces/gameInterfaces';
import Icon from '../../../ui/Icon/Icon';
import ItemTooltip from '../ItemTooltip/ItemTooltip';

interface IProps extends ComponentProps<any> {
    items: any[];
    onClick: Function;
    onDoubleClick: Function;
    color?: 'primary' | 'success' | 'danger' | 'warning' | 'secondary';
}

const ItemList = (props: IProps) => {
    const listItems = () => {
        return (
            props.items &&
            props.items.length > 0 &&
            props.items.map(function (data: IItem) {
                const randomNum = Math.random();
                return (
                    <div key={`itemId_${randomNum}`} className="col-3 p-1">
                        <span onDoubleClick={() => props.onDoubleClick(data)} onClick={() => props.onClick(data)} data-tip data-for={`itemId_${randomNum}`}>
                            <Icon name={data.image} rarity={data.itemRarity} animateRaise />
                            <ItemTooltip id={`itemId_${randomNum}`} item={data} />
                        </span>
                    </div>
                );
            })
        );
    };

    return <div className="row">{listItems()}</div>;
};

ItemList.defaultProps = {
    color: 'primary',
};

export default ItemList;
